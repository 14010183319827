import { SignupChannel } from '@app/modules/user/signup/signup.interface';
import { EntitlementEnum } from '@app/shared/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';

// Lookup the 'Users' feature state managed by NgRx
export const getUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);
export const getUserLoadingState = createSelector(getUsersState, (state: UsersState) => state.user.loadingState);

export const getUser = createSelector(getUsersState, (state: UsersState) => state.user.data);
export const hasBetaFeaturesEnabled = createSelector(getUsersState, (state: UsersState) =>
  Boolean(state.user?.data?.beta_features_enabled)
);

export const getUserTrialConnectAccountCta = createSelector(getUsersState, (state: UsersState) => {
  const user = state.user.data;

  // AirBnb is the default
  const defaultPlatform = {
    platformUi: SignupChannel.Airbnb,
    connectAccountUrl: '/accounts/new/airbnb',
  };

  if (user && user.signup) {
    if (user.signup.airbnb) {
      return defaultPlatform;
    } else if (user.signup.homeaway) {
      return {
        platformUi: SignupChannel.Vrbo,
        connectAccountUrl: '/accounts/new/vrbo',
      };
    } else if (user.signup.booking) {
      return {
        platformUi: 'Booking.com',
        connectAccountUrl: '/accounts/new/booking',
      };
    } else {
      return defaultPlatform;
    }
  } else {
    return defaultPlatform;
  }
});

export const getSignUpWithAirbnbLoadingState = createSelector(
  getUsersState,
  (state: UsersState) => state.signUpWithAirbnb.loadingState
);

export const getSignUpWithAirbnbError = createSelector(
  getUsersState,
  (state: UsersState) => state.signUpWithAirbnb.error
);

export const getEntitlementForFeature = (feature: EntitlementEnum) =>
  createSelector(getUser, (state) => state?.entitlements?.find((entitlement) => entitlement.entitlement === feature));
