import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sbnb-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent implements OnInit, OnDestroy {
  @Input() content: string;
  @Input() mini = false;
  @Input() tooltipText = 'Copy to clipboard';

  @ViewChild('tooltip') tooltip: MatTooltip;
  private copied$ = new BehaviorSubject<boolean>(false);
  private destroyed$ = new Subject<void>();

  constructor(private readonly clipboardService: ClipboardService) {}

  public ngOnInit(): void {
    this.copied$.pipe(debounceTime(3200), takeUntil(this.destroyed$)).subscribe(() => this.hideTooltip());
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * We can't use the `cbOnSuccess` callback attribute
   * because of this issue: https://github.com/maxisam/ngx-clipboard/issues/310
   * The lib is using an rxjs 7 property
   */
  public onCopied($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.clipboardService.copy(this.content);
    this.tooltip.show();
    this.tooltip.tooltipClass = 'tooltip-dark';
    this.tooltip.message = 'Copied!';
    this.tooltip.hideDelay = 3000;
    this.copied$.next(true);
  }

  private hideTooltip() {
    if (!this.tooltip) return;

    this.tooltip.tooltipClass = '';
    this.tooltip.hideDelay = 0;
    this.tooltip.hide(0);
    this.tooltip.message = this.tooltipText;
  }
}
