import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { RuleService } from '@app/shared/services/rule/rule.service';
import { TeamService } from '@app/shared/services/team/team.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  selector: 'sbnb-dialog-preview-message',
  templateUrl: './dialog-preview-message.component.html',
  styleUrls: ['./dialog-preview-message.component.scss'],
})
export class DialogPreviewMessageComponent implements OnInit {
  conversations: any[];
  loading: boolean;
  loadingPreview: boolean;
  rulesetId: any;
  previewContents = '';
  rulesetData: any;
  ruleId: string;
  teammates: any[];

  selectedConversation: string;
  selectedTeammate: string;

  requiresTeammateSelection: boolean;
  emailSubject: string;

  @ViewChild('preview') preview: ElementRef;
  @ViewChild('previewSubject') previewSubject: ElementRef;

  constructor(
    private ruleService: RuleService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastNotificationsService,
    private teamService: TeamService
  ) {}

  ngOnInit() {
    this.rulesetId = this.data.rulesetId;
    this.rulesetData = this.data.rulesetData;
    this.loading = true;
    this.ruleId = this.data.ruleId;

    this.requiresTeammateSelection = this.data.event.startsWith('team');

    this.ruleService.threadsForMessagePreview(this.rulesetId).subscribe((res) => {
      this.conversations = res.data;
      this.loading = false;
    });

    if (this.requiresTeammateSelection) {
      this.teamService.getTeamMembers(null, null, null, null, false).subscribe((res: any) => {
        this.teammates = res.data;
      });
    }
  }

  conversationChanged(event: MatSelectChange) {
    this.selectedConversation = event.value;

    this.loadPreview();
  }

  teammateChanged(event: MatSelectChange) {
    this.selectedTeammate = event.value;

    this.loadPreview();
  }

  loadPreview() {
    if (!this.selectedConversation) {
      return;
    }

    if (this.requiresTeammateSelection && !this.selectedTeammate) {
      return;
    }

    this.loadingPreview = true;
    this.preview.nativeElement.textContent = 'Loading Preview...';

    this.ruleService
      .previewMessage(this.selectedConversation, this.rulesetId, this.ruleId, this.selectedTeammate)
      .subscribe((res) => {
        if (!res.message) {
          this.preview.nativeElement.textContent = 'Unable to load conversation preview.';
          this.loadingPreview = false;
          return;
        }

        if (res.subject) {
          this.emailSubject = res.subject;

          setTimeout(() => {
            this.previewSubject.nativeElement.textContent = this.emailSubject;
          }, 100);
        }

        this.previewContents = res.message;
        this.preview.nativeElement.textContent = this.previewContents;
        this.loadingPreview = false;
      });
  }
}
