import { Component, OnInit } from '@angular/core';
import { NotificationsService, NotificationData } from '@app/core/notifications/notifications.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { config } from '@app/core/app-config';

@Component({
  selector: 'sbnb-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.scss'],
})
export class ToastContainerComponent implements OnInit {
  messages: Observable<NotificationData[]>;
  hideToasts: boolean = false;
  config = config;

  constructor(
    private notificationService: NotificationsService,
    private router: Router
  ) {}

  hideToast(notification: NotificationData) {
    this.notificationService.updateNotification({
      ...notification,
      hidden: true,
    });
  }

  ngOnInit() {
    this.initialiseUrlChecksForHiding();

    this.messages = this.notificationService.notifications.pipe(
      map((notifications) => {
        return notifications.filter(
          (notification) => notification.type === 'toast' && notification.tag === 'global' && !notification.hidden
        );
      })
    );
  }

  private initialiseUrlChecksForHiding() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;

        this.hideToasts = this.checkUrlAndHide(url);
      }
    });
  }

  private checkUrlAndHide(currentUrl: string) {
    const urlsToHide = config.HIDE_TOAST_URLS;

    if (urlsToHide.length > 0) {
      if (urlsToHide.some((url) => currentUrl.indexOf(url) > -1)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
