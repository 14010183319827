<ng-container *ngIf="items?.data.length > 0 && !hidden">
  <div class="onboarding-gutter">
    <div
      class="onboarding-banner"
      [class.nav_expanded]="navExpanded"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="12px">
      <span fxFlex *ngIf="allCompleted">Congratulations on completing our onboarding checklist! 🎉</span>
      <span fxFlex *ngIf="!allCompleted">
        You have {{ items.meta.pending_count }} next steps. Next:
        <strong>{{ nextIncompleteItem.title }}</strong>
      </span>
      <sbnb-button
        *ngIf="allCompleted"
        label="Check your achievements!"
        [routerLink]="['/onboarding']"
        size="mini"
        type="secondary"></sbnb-button>

      <sbnb-button
        *ngIf="!allCompleted"
        [label]="nextIncompleteItem.action?.label || 'Show me how!'"
        size="mini"
        type="secondary"
        (click)="
          nextIncompleteItem.action.link
            ? openActionLink(nextIncompleteItem.action.link)
            : nextIncompleteItem.action.tour_id
              ? openTour(nextIncompleteItem.action)
              : null
        "></sbnb-button>
      <sbnb-close *ngIf="isDismissable" color="#fff" (click)="dismiss()"></sbnb-close>
    </div>
  </div>
</ng-container>
