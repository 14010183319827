import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AlgoliaKeyInterceptor } from './interceptors/algolia-key.interceptor';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { DemoInterceptor } from './interceptors/demo-interceptor';
import { HttpCacheInterceptor } from './interceptors/http-cache.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, SharedModule],
  declarations: [NotFoundComponent],
  exports: [],
})
export class CoreModule {
  /* Ensures that Core Module is only ever loaded from AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        // { provide: HTTP_INTERCEPTORS, useClass: MockBackendInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DemoInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AlgoliaKeyInterceptor, multi: true },
      ],
    };
  }
}
