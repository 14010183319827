import { LoadingState, PropertyMergeCandidate, Property } from '@app/shared/interfaces';
import { PaginationMeta } from '@app/shared/models/pagination-meta';
import { createReducer, on, Action } from '@ngrx/store';
import * as PropertiesActions from './properties.actions';
import { PropertyEntity, PropertyMergeCandidateEntity } from './properties.models';

import * as UsersActions from '../users/users.actions';

export const PROPERTIES_FEATURE_KEY = 'properties';

export interface PropertiesState {
  properties: {
    loadingState: LoadingState;
    data: PropertyEntity | null;
    meta: PaginationMeta | null;
  };
  propertyMergeCandidates: {
    loadingState: LoadingState;
    data: PropertyMergeCandidateEntity | null;
  };
  bulkMuteAndUnMuteProperties: {
    loadingState: LoadingState;
  };
}

export interface PropertiesPartialState {
  readonly [PROPERTIES_FEATURE_KEY]: PropertiesState;
}

export const initialPropertiesState: PropertiesState = {
  properties: {
    loadingState: LoadingState.NotSent,
    data: null,
    meta: null,
  },
  propertyMergeCandidates: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  bulkMuteAndUnMuteProperties: {
    loadingState: LoadingState.NotSent,
  },
};

const reducer = createReducer(
  initialPropertiesState,

  // Reset the store when login actions happen
  on(UsersActions.resetStore, () => initialPropertiesState),

  on(PropertiesActions.loadProperties, (state) => ({
    ...state,
    properties: {
      ...state.properties,
      loadingState: LoadingState.Pending,
    },
  })),

  on(PropertiesActions.loadPropertiesSuccess, (state, { response }) => ({
    ...state,
    properties: ((response) => {
      return {
        loadingState: LoadingState.Success,
        data: response.data.reduce((entities: PropertyEntity, record: Property) => {
          return {
            ...entities,
            [record.id]: record,
          };
        }, {}),
        meta: response.meta,
      };
    })(response),
  })),

  on(PropertiesActions.loadPropertiesFailure, (state) => ({
    ...state,
    properties: {
      ...state.properties,
      loadingState: LoadingState.Error,
    },
  })),

  on(PropertiesActions.loadPropertiesMergeCandidates, (state) => ({
    ...state,
    propertyMergeCandidates: {
      ...state.propertyMergeCandidates,
      loadingState: LoadingState.Pending,
    },
  })),

  on(PropertiesActions.loadPropertiesMergeCandidatesSuccess, (state, { response }) => ({
    ...state,
    propertyMergeCandidates: ((response) => {
      return {
        loadingState: LoadingState.Success,
        data: response.reduce((entities: PropertyMergeCandidateEntity, record: PropertyMergeCandidate) => {
          return {
            ...entities,
            [record.propertyId]: record,
          };
        }, {}),
      };
    })(response),
  })),

  on(PropertiesActions.loadPropertiesMergeCandidatesFailure, (state) => ({
    ...state,
    propertyMergeCandidates: {
      ...state.propertyMergeCandidates,
      loadingState: LoadingState.Error,
    },
  })),

  on(PropertiesActions.bulkMuteAndUnMuteProperties, (state) => ({
    ...state,
    bulkMuteAndUnMuteProperties: {
      ...state.bulkMuteAndUnMuteProperties,
      loadingState: LoadingState.Pending,
    },
  })),

  on(PropertiesActions.bulkMuteAndUnMutePropertiesSuccess, (state) => ({
    ...state,
    bulkMuteAndUnMuteProperties: {
      ...state.bulkMuteAndUnMuteProperties,
      loadingState: LoadingState.Success,
    },
  })),

  on(PropertiesActions.bulkMuteAndUnMutePropertiesFailure, (state) => ({
    ...state,
    bulkMuteAndUnMuteProperties: {
      ...state.bulkMuteAndUnMuteProperties,
      loadingState: LoadingState.Error,
    },
  })),

  on(PropertiesActions.bulkMuteAndUnMuteProperties, (state) => ({
    ...state,
    bulkMuteAndUnMuteProperties: {
      ...state.bulkMuteAndUnMuteProperties,
      loadingState: LoadingState.Pending,
    },
  })),

  on(PropertiesActions.bulkMuteAndUnMutePropertiesSuccess, (state) => ({
    ...state,
    bulkMuteAndUnMuteProperties: {
      ...state.bulkMuteAndUnMuteProperties,
      loadingState: LoadingState.Success,
    },
  })),

  on(PropertiesActions.bulkMuteAndUnMutePropertiesFailure, (state) => ({
    ...state,
    bulkMuteAndUnMuteProperties: {
      ...state.bulkMuteAndUnMuteProperties,
      loadingState: LoadingState.Error,
    },
  }))
);

export function propertiesReducer(state: PropertiesState | undefined, action: Action) {
  return reducer(state, action);
}
