import { createMetaReducer } from 'redux-beacon';
import { ActionReducer } from '@ngrx/store';
import { UsersEvents } from './users-events';
import { Segment } from '@app/shared/analytics';

const analyticsMetaReducer = createMetaReducer(UsersEvents, Segment());

export function AnalyticsMetaReducer(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return analyticsMetaReducer(reducer);
}
