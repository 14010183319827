import { Injectable, isDevMode } from '@angular/core';
import { config } from '@app/core/app-config';
import { LocalStorageKey, PosthogFeatureFlag, User } from '@app/shared/interfaces';
import { InternalCsService } from '@app/shared/services/internal-cs/internal-cs.service';
import { ScriptLoaderService } from '@app/shared/services/script-loader/script-loader.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { Logger } from '@app/shared/utils';
import { environment } from '@env/environment';
import { BehaviorSubject, combineLatest, of, race, timer } from 'rxjs';
import { filter, first, map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  constructor(
    private scriptLoader: ScriptLoaderService,
    private segmentIoService: SegmentIoService,
    private csService: InternalCsService
  ) {}
  /**
   * Internal state to track if the posthog library has loaded
   */
  private _loaded$ = new BehaviorSubject<boolean>(false);
  private loaded$ = this._loaded$.asObservable();
  private _featureFlagsLoaded$ = new BehaviorSubject<boolean>(false);
  private featureFlagsLoaded$ = combineLatest([this.loaded$, this._featureFlagsLoaded$.asObservable()]).pipe(
    filter(([loaded]) => Boolean(loaded)),
    tap(([loaded, featureFlagsLoaded]) => {
      if (!featureFlagsLoaded) {
        this.posthogInstance.onFeatureFlags(() => {
          this._featureFlagsLoaded$.next(true);
        });
      }
    }),
    filter(([loaded, featureFlagsLoaded]) => Boolean(featureFlagsLoaded)),
    map(([, featureFlagsLoaded]) => featureFlagsLoaded)
  );

  public featureEnabled$ = (feature: PosthogFeatureFlag, timeToWait = 5000) => {
    if (this.canUseDevFlags) {
      return of(this.useDevFlags(feature));
    }
    return race(
      this.featureFlagsLoaded$.pipe(
        filter((loaded) => Boolean(loaded)),
        take(1),
        map(() => this.isFeatureEnabled(feature))
      ),
      // If the user has blocked Posthog, we might never get a loaded event
      // In that case, we should just return false
      timer(timeToWait).pipe(
        first(),
        map(() => false)
      )
    );
  };

  /**
   * =============================
   * Currently used feature flags
   * =============================
   */
  public paymentTermsEnabled$ = this.featureEnabled$(PosthogFeatureFlag.PaymentTerms);
  public upsellsEnabled$ = this.featureEnabled$(PosthogFeatureFlag.Upsells);
  public directVideoHeaderEnabled$ = this.featureEnabled$(PosthogFeatureFlag.DirectVideoHeader);
  public threadBetaEnabled$ = this.featureEnabled$(PosthogFeatureFlag.ThreadBeta);
  public accountingIntegrations$ = this.featureEnabled$(PosthogFeatureFlag.AccountingIntegrations);
  public yearInReviewCtaEnabled$ = this.featureEnabled$(PosthogFeatureFlag.YearInReviewCta);
  public rentalAgreementsAllPlatformsAvailable$ = this.featureEnabled$(
    PosthogFeatureFlag.RentalAgreementsAllPlatformsAvailable
  );

  public init() {
    this.scriptLoader
      .load({
        name: 'Posthog Replays',
        src: '/assets/js/posthog.js',
      })
      .subscribe({
        next: () => {
          const segment = window.analytics || [];

          segment.ready(() => {
            this.posthogInstance.init('phc_88E0vAwv0WZonzP1Y0fDMMmgvVODP9O8UhFR3Mx34K1', {
              api_host: 'https://ph.hospitable.com', // Using our reverse proxy to help prevent client blocking
              segment: window.analytics, // Pass window.analytics here - NOTE: `window.` is important
              capture_pageview: false, // You want this false if you are going to use segment's `analytics.page()` for pageviews
              session_recording: {
                maskAllInputs: false,
                maskInputOptions: {
                  password: true,
                },
              },

              // When the posthog library has loaded, call `analytics.page()` explicitly.
              // We delay this until feature flags are loaded as someone mentioned in a comment on the posthog docs site
              loaded: (instance) => {
                // This is called both on first load as well as
                // every time feature flags change.
                instance.onFeatureFlags((flags: string[]) => {
                  localStorage.setItem(LocalStorageKey.featureFlagsPostHog, JSON.stringify(flags));
                  this._featureFlagsLoaded$.next(true);
                });

                this._loaded$.next(true);
                this.registerSuperProperties({
                  version: environment.version,
                });
              },
            });
          });
        },
        error: (error) => {
          console.error('Error loading Posthog SDK', error);
        },
      });
  }

  /**
   * Posthog users are identified through Segment.io, using Segment's identify method.
   * However, Sement's identify method excludes CS users. To use Posthog for feature flags,
   * we want to identify CS users as the user they are impersonating so that they see the same
   * features the user does (to make troubleshooting easier)
   * @param userId string
   * @param user User object
   */
  identifyUser(userId: string, user: User) {
    this.loaded$.pipe(filter((loaded) => Boolean(loaded))).subscribe((loaded) => {
      // Called as segment.identify is not called when you are CS
      if (this.csService.isCS()) {
        this.posthogInstance.identify(userId, user);
      }
    });
  }

  reloadFeatureFlags() {
    this.loaded$.pipe(filter((loaded) => Boolean(loaded))).subscribe((loaded) => {
      this.posthogInstance.reloadFeatureFlags();
    });
  }

  /**
   * When wanting to trigger a posthog survey, posthog cannot currently use segment events to trigger the survey.
   * This method calls a capture event directly on posthog to trigger the survey.
   * In most other cases, you should be using Segment track instead.
   */
  capture(event: string, properties: unknown = {}) {
    try {
      this.posthogInstance.capture(event, properties);
    } catch (err) {
      console.error('PosthogService capture error', err);
    }
  }

  getSessionId(): string {
    try {
      // Not using the posthogInstance here because this method can legitimately
      // be called before the posthogInstance is loaded and should not wait
      return (window as any)?.posthog?.get_session_id?.() ?? '';
    } catch (e) {
      console.error('Error getting Posthog session ID', e);
      this.segmentIoService.track(SegmentEvent.PosthogSessionIdError, { error: e });
    }
  }

  reset() {
    this.loaded$.pipe(take(1)).subscribe((loaded) => {
      if (loaded) {
        this.posthogInstance.resetPersonPropertiesForFlags();
        this.posthogInstance.reset();
      }
    });
  }

  /**
   * Super Properties are properties associated with events that are set once
   * and then sent with every capture call. https://posthog.com/docs/libraries/js#super-properties
   * Our most common use case is to set the app version as soon as the app loads.
   * @param properties
   */
  private registerSuperProperties(properties: Record<string, unknown>) {
    this.posthogInstance.register(properties);
  }

  private isFeatureEnabled(featureName: PosthogFeatureFlag): boolean {
    if (this.canUseDevFlags) {
      return this.useDevFlags(featureName);
    }
    if (!this.posthogInstance) {
      return false;
    }

    return this.posthogInstance.isFeatureEnabled(featureName);
  }

  private get posthogInstance() {
    if (!(window as any).posthog) {
      console.warn('Posthog not loaded, cannot call method');
      return;
    }
    return (window as any).posthog;
  }

  private get canUseDevFlags(): boolean {
    return isDevMode() && Boolean(config.POSTHOG_FEATURE_FLAGS?.length);
  }

  private useDevFlags(featureName: PosthogFeatureFlag): boolean {
    Logger.log('Posthog is using dev mode, set flags in your environment.ts file');
    return config.POSTHOG_FEATURE_FLAGS.includes(featureName);
  }
}
