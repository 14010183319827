import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { Logger } from '@app/shared/utils';
import { environment } from '@env/environment';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from './core/authentication/authentication.service';
import { LocalStorageKey } from './shared/interfaces';

declare let Intercom: any;

@Component({
  selector: 'sbnb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  updatePending: boolean;
  isDemo: boolean;
  isMobile: boolean;

  constructor(
    private swUpdate: SwUpdate,
    private demoService: DemoService,
    private authService: AuthenticationService,
    private router: Router,
    private readonly breakpointObserver: BreakpointObserver,
    private posthog: PosthogService
  ) {}

  ngOnInit() {
    Logger.log(`Hospitable (${environment.name}) - ${environment.version}`);

    this.isDemo = this.demoService.isDemo();

    this.listenForMobile();
    this.listenForAuth();

    // Posthog
    this.posthog.init();

    // Listen for App updates via Service worker
    this.checkForUpdate();

    // Check if we have a uuid for 2fa, if not generate a new one
    this.authService.checkFor2faNonce();

    if (this.authService.isLoggedIn() && !window.location.href.includes('user/cs')) {
      this.authService.isLoggedInSubject.next(true);
      this.authService.setCookie(this.authService.cookieStorageKeys.isLoggedIn);
      this.authService.loginActions();
    }

    this.checkIfAnalyticsEnabled();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // this.isMobile ? this.handleIntercomMobileWidget() : null;
    });
  }

  checkIfAnalyticsEnabled() {
    if (environment.production && localStorage.getItem(LocalStorageKey.isCS) !== 'true') {
      localStorage.setItem(LocalStorageKey.analyticsEnabled, 'true');
    } else {
      localStorage.removeItem(LocalStorageKey.analyticsEnabled);
    }
  }

  listenForAuth() {
    this.authService.isLoggedIn$.subscribe((isLoggedIn: boolean) => {
      const bodyElm = document.getElementsByTagName('body')[0];
      const isNotLoggedInClass = 'is-not-logged-in';

      isLoggedIn ? bodyElm.classList.remove(isNotLoggedInClass) : bodyElm.classList.add(isNotLoggedInClass);
      this.isMobile = true;
      // this.handleIntercomMobileWidget();
    });
  }

  listenForMobile() {
    this.breakpointObserver.observe(['(max-width: 960px)']).subscribe((state: BreakpointState) => {
      const bodyElm = document.getElementsByTagName('body')[0];

      if (bodyElm) {
        const isMobileClass = 'is-mobile';

        if (state && state.matches) {
          bodyElm.classList.add(isMobileClass);
        } else {
          bodyElm.classList.remove(isMobileClass);
          this.isMobile = false;
        }
      }
    });
  }

  private checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(() => {
          // An update is available, apply it on the next route change
          this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
              this.activateUpdate();
            });
        });
    }
  }

  handleIntercomMobileWidget() {
    setTimeout(() => {
      const widget: any = document.querySelector('.mobile-intercom-widget');
      const sideBar: any = document.querySelector('sbnb-mobile-sidebar');
      const close: any = document.querySelector('sbnb-close');

      if (widget) {
        if (sideBar) {
          widget.classList.add('mobile-intercom-widget--right');
        } else if (close) {
          widget.classList.add('mobile-intercom-widget--bottom');
        } else {
          widget.classList.remove('mobile-intercom-widget--right');
          widget.classList.remove('mobile-intercom-widget--bottom');
        }
      }
    }, 100);
  }

  activateUpdate() {
    this.swUpdate.activateUpdate().then(() => {
      setTimeout(() => document.location.reload(), 500);
    });
  }
}
