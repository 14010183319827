import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface SmartCodesResponse {
  short: SmartCode[];
}

export interface SmartCode {
  ai: boolean;
  label: string;
  description: string;
  key: 'smart_upsell' | 'smart_signoff' | 'smart_greeting';
}

interface CustomAndShortCodesResponse {
  custom: string[];
  short: string[];
}

@Injectable({
  providedIn: 'root',
})
export class EditorApiService {
  constructor(private http: HttpClient) {}

  getCustomAndShortCodes(event = 'all') {
    return this.http.get<{ data: CustomAndShortCodesResponse }>(`${config.API_URL}/editor/codes?event=${event}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getSmartCodes(event = 'all') {
    return this.http
      .get<{ data: SmartCodesResponse }>(`${config.API_URL}/editor/codes/suggestions?event=${event}`)
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return of({ short: [] });
        })
      );
  }
}
