import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DemoService } from '@app/shared/services/demo/demo.service';
import { catchError } from 'rxjs/operators';
import { NotificationType } from '@app/shared/interfaces';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Injectable()
export class DemoInterceptor implements HttpInterceptor {
  constructor(
    private demoService: DemoService,
    private toast: ToastNotificationsService
  ) {}
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.demoService.isDemo()) {
      httpRequest = httpRequest.clone({
        url: httpRequest.url,
        setParams: { demo: 'true' },
      });
    }
    return next.handle(httpRequest).pipe(catchError(this.handleError));
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 403) {
      if (error.error.message) {
        this.toast.open(error.error.message, 'Dismiss', NotificationType.Error, 999999);
      }
    }

    return throwError(error);
  };
}
