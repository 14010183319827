import { Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ChannelListing,
  ListingReview,
  LoadingState,
  parseApiErrorResponseForMessages,
  UpdatePropertyOption,
} from '@app/shared/interfaces';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of, Observable, EMPTY, combineLatest } from 'rxjs';
import * as ListingsActions from './listings.actions';
import { ListingsService } from './listings.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ListingsResponse, UpdateListingReviewsFormAnalytics, UpdateListingReviewsFormEntity } from './listings.models';
import { PropertiesFacade } from '../properties';
import { ListingsState } from './listings.reducer';
import {
  getListingReviewsFormEntitiesByAction,
  getListingsState,
  getUpdateListingReviewsFormAnalytics,
  getUpdateListingReviewsFormEntity,
} from './listings.selectors';

@Injectable()
export class ListingsEffects {
  loadListingReviews$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ListingsActions.loadListingReviews),
        map((action) => action),
        mergeMap((action) =>
          this.listingsService.loadChannelListings(action.platform, action.channelId, action.requestOverride).pipe(
            mergeMap((response: ListingsResponse) => {
              const { data, meta } = response;

              const listingReviews: ListingReview = {
                listings: data,
                types: this.calculateListingTypes(data),
                meta: meta,
              };
              return of(
                ListingsActions.loadListingReviewsSuccess({
                  response: listingReviews,
                  shouldLoadMergeCandidates: action.shouldLoadMergeCandidates,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => of(ListingsActions.loadListingReviewsFailure({ response: error })))
          )
        )
      )
  );

  loadListingReviewsSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ListingsActions.loadListingReviewsSuccess),
        map((action) => action),
        mergeMap((action) => {
          const { shouldLoadMergeCandidates, response } = action;
          if (shouldLoadMergeCandidates) {
            const propertyIds: string[] = [];
            const listingIds: string[] = [];

            if (response.listings) {
              response.listings.map((listing) => {
                propertyIds.push(listing.property_id);
                listingIds.push(listing.listing_id);
              });
            }

            if (propertyIds.length > 0 && listingIds.length > 0) {
              this.propertiesFacade.loadPropertiesMergeCandidates(propertyIds, listingIds);
            }
          }

          return EMPTY;
        })
      )
  );

  listingReviewsPusherUpdate$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ListingsActions.listingReviewsPusherUpdate),
        concatLatestFrom(() => this.store.select(getListingsState)),
        mergeMap(([action, state]) => {
          const { shouldLoadMergeCandidates, listings } = action;

          const existingListingsData: ListingReview['listings'] = state.listingReviews.data.listings
            ? Object.keys(state.listingReviews.data.listings).map((id) => state.listingReviews.data.listings[id])
            : [];
          let existingListingReviewTypes: ListingReview['types'] = state.listingReviews.data.listings
            ? state.listingReviews.data.types
            : [];

          const newListingsData: ListingReview['listings'] = existingListingsData.concat(listings);

          const newListingReviewTypes = [...new Set(listings.map((listing) => listing.type))];

          newListingReviewTypes.forEach((type: string) => {
            if (!existingListingReviewTypes.includes(type)) {
              existingListingReviewTypes = [...existingListingReviewTypes, ...[type]];
            }
          });

          const uniqueListingReviews = [];

          newListingsData.map((x) =>
            uniqueListingReviews.filter((a) => a.listing_id == x.listing_id).length > 0
              ? null
              : uniqueListingReviews.push(x)
          );

          return of(
            ListingsActions.loadListingReviewsSuccess({
              response: {
                listings: uniqueListingReviews,
                types: existingListingReviewTypes,
                meta: {
                  total_listings: uniqueListingReviews.length,
                },
              },
              shouldLoadMergeCandidates,
            })
          );
        })
      )
  );

  bulkSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          ListingsActions.bulkCreatePropertiesSuccess,
          ListingsActions.bulkMergePropertiesSuccess,
          ListingsActions.bulkMutePropertiesSuccess,
          ListingsActions.bulkUnMutePropertiesSuccess
        ),
        mergeMap((action) => {
          const { form } = action;

          return form
            ? of(
                ListingsActions.updateListingReviewsForm({
                  form,
                })
              )
            : EMPTY;
        })
      )
  );

  listingReviewsFormCreateProperties$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ListingsActions.saveListingReviewsForm),
        concatLatestFrom(() =>
          this.store.select(getListingReviewsFormEntitiesByAction(UpdatePropertyOption.CreateNewProperty))
        ),
        mergeMap(([action, createNewProperties]) => {
          if (Object.keys(createNewProperties).length === 0) {
            return of(ListingsActions.bulkCreatePropertiesSuccess({ form: null }));
          }

          // Set Each Action with CreateNewProperty loadingStateToTrue
          const form = { ...createNewProperties, ...{} };

          Object.keys(form).map((id) => {
            form[id].loadingState = LoadingState.Success;
          });

          return of(ListingsActions.bulkCreatePropertiesSuccess({ form }));
        })
      )
  );

  listingReviewsFormMergeProperties$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(ListingsActions.saveListingReviewsForm),
        concatLatestFrom(() =>
          this.store.select(getListingReviewsFormEntitiesByAction(UpdatePropertyOption.MergeProperty))
        ),
        mergeMap(([action, mergeProperties]) => {
          if (Object.keys(mergeProperties).length === 0) {
            return of(ListingsActions.bulkMergePropertiesSuccess({ form: null }));
          }

          const requests = Object.keys(mergeProperties).map((id) => {
            const propertyId = id;
            const mergeWithPropertyId = mergeProperties[id].action.replace(UpdatePropertyOption.MergeProperty, '');

            return this.propertiesFacade.mergeProperty(propertyId, mergeWithPropertyId).pipe(
              catchError((response: ApiErrorResponse) => {
                return of({
                  ...mergeProperties[propertyId],
                  ...{
                    loadingState: LoadingState.Error,
                    error: parseApiErrorResponseForMessages(response),
                  },
                });
              })
            );
          });

          return combineLatest(requests).pipe(
            mergeMap((rawResponse: any) => {
              const form = {};
              const propertyIds = Object.keys(mergeProperties).map((id) => id);

              for (let i = 0; i < propertyIds.length; i++) {
                // Check if it has a form info ie an error was thrown
                if (rawResponse[i] && rawResponse[i].loadingState) {
                  form[propertyIds[i]] = rawResponse[i];
                } else {
                  form[propertyIds[i]] = {
                    ...mergeProperties[propertyIds[i]],
                    ...{ loadingState: LoadingState.Success },
                  };
                }
              }

              return of(ListingsActions.bulkMergePropertiesSuccess({ form }));
            })
          );
        })
      )
  );

  listingReviewsFormMuteProperties$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(ListingsActions.saveListingReviewsForm),
        concatLatestFrom(() =>
          this.store.select(getListingReviewsFormEntitiesByAction(UpdatePropertyOption.MuteProperty))
        ),
        mergeMap(([action, mutedProperties]) => {
          if (Object.keys(mutedProperties).length === 0) {
            return of(ListingsActions.bulkMutePropertiesSuccess({ form: null }));
          }

          const requests = Object.keys(mutedProperties).map((propertyId) => {
            return this.propertiesFacade.muteProperty(propertyId).pipe(
              catchError((response: ApiErrorResponse) => {
                return of({
                  ...mutedProperties[propertyId],
                  ...{
                    loadingState: LoadingState.Error,
                    error: parseApiErrorResponseForMessages(response),
                  },
                });
              })
            );
          });

          return combineLatest(requests).pipe(
            mergeMap((rawResponse: any) => {
              const form = {};
              const propertyIds = Object.keys(mutedProperties).map((id) => id);

              for (let i = 0; i < propertyIds.length; i++) {
                // Check if it has a form info ie an error was thrown
                if (rawResponse[i] && rawResponse[i].loadingState) {
                  form[propertyIds[i]] = rawResponse[i];
                } else {
                  form[propertyIds[i]] = {
                    ...mutedProperties[propertyIds[i]],
                    ...{ loadingState: LoadingState.Success },
                  };
                }
              }

              return of(ListingsActions.bulkMutePropertiesSuccess({ form }));
            })
          );
        })
      )
  );

  listingReviewsFormUnMuteProperties$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(ListingsActions.saveListingReviewsForm),
        concatLatestFrom(() =>
          this.store.select(getListingReviewsFormEntitiesByAction(UpdatePropertyOption.UnmuteProperty))
        ),
        mergeMap(([action, propertiesToUnmute]) => {
          if (Object.keys(propertiesToUnmute).length === 0) {
            return of(ListingsActions.bulkUnMutePropertiesSuccess({ form: null }));
          }

          const requests = Object.keys(propertiesToUnmute).map((propertyId) => {
            return this.propertiesFacade.unmuteProperty(propertyId).pipe(
              catchError((response: ApiErrorResponse) => {
                return of({
                  ...propertiesToUnmute[propertyId],
                  ...{
                    loadingState: LoadingState.Error,
                    error: parseApiErrorResponseForMessages(response),
                  },
                });
              })
            );
          });

          return combineLatest(requests).pipe(
            mergeMap((rawResponse: any) => {
              const form = {};
              const propertyIds = Object.keys(propertiesToUnmute).map((id) => id);

              for (let i = 0; i < propertyIds.length; i++) {
                // Check if it has a form info ie an error was thrown
                if (rawResponse[i] && rawResponse[i].loadingState) {
                  form[propertyIds[i]] = rawResponse[i];
                } else {
                  form[propertyIds[i]] = {
                    ...propertiesToUnmute[propertyIds[i]],
                    ...{ loadingState: LoadingState.Success },
                  };
                }
              }

              return of(ListingsActions.bulkUnMutePropertiesSuccess({ form }));
            })
          );
        })
      )
  );

  trackStartedMergeMatch$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(ListingsActions.trackStartedMergeMatch),
        concatLatestFrom(() => this.store.select(getUpdateListingReviewsFormEntity)),
        mergeMap(([action, updateListingReviewsFormEntity]) => {
          const formEntity: UpdateListingReviewsFormEntity = updateListingReviewsFormEntity;
          const analytics: UpdateListingReviewsFormAnalytics = {
            mergeCandidates: 0,
            meta: {},
          };

          if (formEntity) {
            Object.keys(formEntity).forEach((key) => {
              if (formEntity[key].action.includes(UpdatePropertyOption.MergeProperty)) {
                analytics.mergeCandidates++;
              }

              analytics.meta[key] = {
                recommendedAction: formEntity[key].action,
              };
            });
          }

          return of(ListingsActions.trackStartedMergeMatchSuccess({ analytics }));
        })
      )
  );

  trackMergeMatchOutcome$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(ListingsActions.saveListingReviewsForm),
        concatLatestFrom(() => [
          this.store.select(getUpdateListingReviewsFormEntity),
          this.store.select(getUpdateListingReviewsFormAnalytics),
        ]),
        mergeMap(([action, updateListingReviewsFormEntity, reviewsFormAnalytics]) => {
          const formEntity: UpdateListingReviewsFormEntity = updateListingReviewsFormEntity;

          const analytics: UpdateListingReviewsFormAnalytics = {
            mergeCandidates: reviewsFormAnalytics.mergeCandidates,
            mergeCandidatesUsed: 0,
            [UpdatePropertyOption.MergeProperty]: 0,
            [UpdatePropertyOption.CreateNewProperty]: 0,
            [UpdatePropertyOption.MuteProperty]: 0,
            [UpdatePropertyOption.UnmuteProperty]: 0,
            meta: {},
          };

          Object.keys(formEntity).forEach((key) => {
            analytics.meta[key] = {
              ...reviewsFormAnalytics['meta'][key],
              actionTaken: formEntity[key].action,
            };

            if (formEntity[key].action.includes(UpdatePropertyOption.MergeProperty)) {
              analytics[UpdatePropertyOption.MergeProperty]++;
              if (formEntity[key].action === analytics.meta[key].recommendedAction) {
                analytics.mergeCandidatesUsed++;
              }
            } else {
              const action = formEntity[key]['action'];
              analytics[action]++;
            }
          });

          return of(ListingsActions.trackMergeMatchOutcome({ analytics }));
        })
      )
  );

  private calculateListingTypes(listings: ChannelListing[]) {
    if (listings) {
      const values = [...new Set(listings.map((listing) => listing.type))];

      return values.sort(function (a, b) {
        var sort = {
          hosted: 1,
          cohosted: 2,
          archived: 3,
        };
        return sort[a] - sort[b];
      });
    }
  }

  constructor(
    private readonly actions$: Actions,
    private listingsService: ListingsService,
    private propertiesFacade: PropertiesFacade,
    private store: Store<ListingsState>
  ) {}
}
