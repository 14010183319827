import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { config } from '@app/core/app-config';

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  constructor() {}

  public isDemo(): boolean {
    const isDemoMode = localStorage.getItem('demoMode');

    return isDemoMode === 'true';
  }

  public toggleDemoMode(redirect: boolean = false): void {
    const isDemoMode = localStorage.getItem('demoMode');
    if (isDemoMode === 'true') {
      this.disableDemoMode();
    } else {
      this.enableDemoMode();
    }

    if (redirect) {
      window.location.href = config.DEFAULT_PAGE;
    }
  }

  public enableDemoMode(): void {
    localStorage.setItem('demoMode', 'true');
    localStorage.setItem('preDemoModeUser', localStorage.getItem('user'));
  }

  public disableDemoMode(): void {
    localStorage.setItem('demoMode', 'false');
    localStorage.setItem('user', localStorage.getItem('preDemoModeUser'));
    localStorage.removeItem('preDemoModeUser');
  }
}
