import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/interfaces';
import { GeneratedReply } from './AI.models';

@Injectable({
  providedIn: 'root',
})
export class AIService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {}

  generateReply(threadUuid: string, hostUid: string): Observable<ApiResponse<GeneratedReply>> {
    return this.http.get<ApiResponse<GeneratedReply>>(
      `${this.apiUrl}/threads/${threadUuid}/generate-reply?host_id=${hostUid}`
    );
  }

  generateImprovement(threadUuid: string, message: string): Observable<ApiResponse<GeneratedReply>> {
    return this.http.post<ApiResponse<GeneratedReply>>(`${this.apiUrl}/threads/${threadUuid}/generate-improvement`, {
      message,
    });
  }
}
