import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GxAiPreviewRule, GxAiPreviewThread } from '@app/shared/interfaces/lib/gx-settings.interface';
import { SettingsService } from '@app/shared/services/settings/settings.service';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, finalize, map, shareReplay, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'sbnb-gx-ai-preview',
  template: `
    <div class="flex justify-between" style="margin-bottom: 12px">
      <h1>Preview messages</h1>
      <sbnb-close [border]="true" (click)="dialogRef.close()"></sbnb-close>
    </div>

    <span>See how Guest Experience AI will impact your messages in real-life conversations</span>

    <mat-spinner *ngIf="loadingRules$ | async" diameter="24" style="margin-top: 24px"></mat-spinner>
    <section
      *ngIf="rules$ | async as rules"
      class="flex"
      [ngClass.lt-md]="{ 'flex-col': true }"
      [ngStyle.lt-md]="{ gap: '0px' }"
      style="gap: 20px; margin-top: 24px">
      <div *ngIf="!dialogData.rulesetId" class="flex-1">
        <label>Messaging rule</label>
        <mat-form-field class="fw">
          <mat-select (valueChange)="selectedRule$.next($event)">
            <mat-option *ngFor="let rule of rules" [value]="rule">{{ rule.title }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedRule$ | async as selectedRule" class="flex-1">
        <label>Conversation</label>
        <mat-form-field class="fw">
          <mat-select (valueChange)="selectedThread$.next($event)">
            <mat-option *ngFor="let thread of selectedRule.threads" [value]="thread">
              {{ thread.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </section>

    <section
      *ngIf="(selectedThread$ | async) && (selectedRule$ | async)"
      class="flex"
      [ngClass.lt-md]="{ 'flex-col': true }"
      style="margin-top: 12px; gap: 20px">
      <div class="flex-1 preview-result-box">
        <span class="strong" style="display: block; margin-bottom: 16px">Original message</span>
        <mat-spinner *ngIf="loading$ | async as loading" diameter="24"></mat-spinner>
        <ng-container *ngIf="(loading$ | async) === false">
          <div *ngIf="originalMessage$ | async as og" style="line-height: 1.6" class="pre-wrap">{{ og }}</div>
        </ng-container>
      </div>

      <div class="flex-1 preview-result-box">
        <span class="strong" style="display: block; margin-bottom: 16px">Improved message with AI</span>
        <mat-spinner *ngIf="loading$ | async as loading" diameter="24"></mat-spinner>
        <ng-container *ngIf="(loading$ | async) === false">
          <div *ngIf="improvedMessage$ | async as im" style="line-height: 1.6" class="pre-wrap">{{ im }}</div>
        </ng-container>
      </div>
    </section>
  `,
  styleUrls: ['./gx-ai-preview.component.scss'],
})
export class GxAiPreviewComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  selectedRule$ = new BehaviorSubject<GxAiPreviewRule>(null);
  selectedThread$ = new BehaviorSubject<GxAiPreviewThread>(null);

  loading$ = new BehaviorSubject<boolean>(false);
  loadingRules$ = new BehaviorSubject<boolean>(true);

  originalMessage$ = new BehaviorSubject<string>('');
  improvedMessage$ = new BehaviorSubject<string>('');

  rules$: Observable<Array<GxAiPreviewRule>> = of([]);

  constructor(
    private settingsService: SettingsService,
    public dialogRef: MatDialogRef<GxAiPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { rulesetId }
  ) {}

  ngOnInit() {
    // Populate our list of rules, if we have a ruleset ID from the dialog data, use this one explicitly
    this.rules$ = this.settingsService.getDataForGxAiPreview(this.dialogData.rulesetId || null).pipe(
      shareReplay(1),
      map((data) => data.rules),
      tap((rules) => {
        if (rules.length) {
          this.selectedRule$.next(rules[0]);
        }
        this.loadingRules$.next(false);
      }),
      catchError(() => {
        return of([]);
      }),
      finalize(() => {
        this.loadingRules$.next(false);
      })
    );

    // When we have a rule and a thread, fetch the preview data
    combineLatest([this.selectedRule$, this.selectedThread$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([rule, thread]) => rule !== null && thread !== null)
      )
      .subscribe(([rule, thread]) => {
        this.fetchPreviewData(rule.id, thread.uuid);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  fetchPreviewData(rule: number, thread: string) {
    this.loading$.next(true);
    this.settingsService.generateGxAiPreviewMessage(rule, thread).subscribe(
      (response) => {
        this.loading$.next(false);
        this.originalMessage$.next(response.original);
        this.improvedMessage$.next(response.ai_improvement);
      },
      () => {
        this.loading$.next(false);
      }
    );
  }
}
