import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { GetInvoicesApiRes, GetInvoicesServiceRes, Invoice } from '@app/modules/settings/models/invoice';
import { PlanId } from '@app/shared/+state/billing';
import { BillingDetails, SimpleApiValidationError, SubscriptionData } from '@app/shared/interfaces';
import { PaymentCard } from '@app/shared/models/payment-card';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

declare let Chargebee: any;

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private subscriptionUrl = `${config.API_URL}/settings/billing/subscription`;
  private subscriptionCancelUrl = `${config.API_URL}/settings/billing/subscription/cancel`;
  /**
   * @deprecated The UI no longer supports pausing subscriptions
   */
  private subscriptionPauseUrl = `${config.API_URL}/settings/billing/subscription/pause`;
  private subscriptionResumeUrl = `${config.API_URL}/settings/billing/subscription/resume`;
  private subscriptionReactivateUrl = `${config.API_URL}/settings/billing/subscription/reactivate`;
  private subscribeUrl = `${config.API_URL}/settings/billing/subscribe`;
  private cardsUrl = `${config.API_URL}/settings/billing/payment/methods`;
  private intentUrl = `${config.API_URL}/settings/billing/payment/intent`;
  private billingUrl = `${config.API_URL}/settings/billing/details`;
  private invoicesUrl = `${config.API_URL}/settings/billing/invoices`;
  private countriesUrl = `${config.API_URL}/countries`;

  constructor(private http: HttpClient) {}

  attemptSubscribe(
    active_listings: number,
    stashedPaymentMethod?: { type: 'card' | 'paypal'; token: string }
  ): Observable<any> {
    const payload = { active_listings };

    if (stashedPaymentMethod) {
      payload['intent_id'] = stashedPaymentMethod.token;
    }

    return this.http.post(this.subscribeUrl, payload).pipe(
      map((res) => res),
      catchError((err) => of(err))
    );
  }

  /**
   * GET subscription details
   * @param planId PlanId - Allow the backend to provide an estimate based on a desired plan
   * @returns Observable
   */
  public getSubscriptionDetails(planId?: PlanId): Observable<SubscriptionData | SimpleApiValidationError> {
    return this.http.get<SubscriptionData>(`${this.subscriptionUrl}?plan=${planId}`).pipe(
      map((res: any) => res),
      catchError((err) => err)
    );
  }

  // Resume a paused subscription
  resumeSubscription(): Observable<any> {
    return this.http.post(this.subscriptionResumeUrl, {}).pipe(
      map((res: any) => res),
      catchError((err) => err)
    );
  }

  // Reactivate a cancelled subscription
  reactivateSubscription(): Observable<any> {
    return this.http.post(this.subscriptionReactivateUrl, {}).pipe(
      map((res: any) => res),
      catchError((err) => err)
    );
  }

  /**
   * GET payment intent
   * @param type 'card' | 'paypal'
   * @param planId PlanId - Allow the backend to provide an estimate based on a desired plan
   * Helps when users have bank charge authorization turned on to see the correct amount
   * @returns Observable<any> - The raw payment intent object
   */
  getPaymentIntent(type: 'card' | 'paypal' = 'card', planId?: PlanId): Observable<any> {
    return this.http.get(`${this.intentUrl}/${type}?plan=${planId}`).pipe(
      map((res: any) => {
        return res.data.raw;
      }),
      catchError((err) => err)
    );
  }

  fetchPaymentCards(): Observable<PaymentCard[]> {
    return this.http.get<PaymentCard[]>(this.cardsUrl).pipe(map((res: any) => res.data));
  }

  addCard(intent_id: string): Observable<any> {
    return this.http.post(this.cardsUrl, { intent_id }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => err)
    );
  }

  deleteCard(id: string) {
    return this.http.request('DELETE', this.cardsUrl, { body: { payment_method_id: id } }).pipe(
      map((res) => res),
      catchError((err) => err)
    );
  }

  changePaymentMethodRole(id: string, role = 'primary'): Observable<PaymentCard> {
    return this.http.put<PaymentCard>(this.cardsUrl, { payment_method_id: id, role }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err) => err)
    );
  }

  getBillingCountries(): Observable<any> {
    return this.http.get(this.countriesUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getBilling() {
    return this.http.get(this.billingUrl).pipe(
      map((res: { data: BillingDetails }) => {
        return res.data;
      })
    );
  }

  saveBilling(payload: any): Observable<any> {
    return this.http.put(this.billingUrl, payload).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  getInvoices(offset?: string, limit?: string): Observable<GetInvoicesServiceRes> {
    const params = {};

    if (offset) {
      params['offset'] = offset;
    }
    if (limit) {
      params['limit'] = limit;
    }

    return this.http.get<GetInvoicesApiRes>(this.invoicesUrl, { params }).pipe(
      map(
        (apiRes: GetInvoicesApiRes) =>
          <GetInvoicesServiceRes>{
            meta: apiRes.meta,
            data: apiRes.data.map((invoice) => new Invoice(invoice)),
          }
      )
    );
  }

  getDownloadableFile(download_url: string): Observable<any> {
    return this.http.get(`${download_url}`).pipe(
      map((res: any) => res),
      catchError((err) => err)
    );
  }

  upgradeBasePlan() {
    return this.http.post(`${config.API_URL}/settings/billing/subscription/upgrade`, {});
  }

  private initChargebee() {
    try {
      Chargebee.getInstance();
    } catch (err) {
      Chargebee.init({
        site: config.CHARGEBEE_SITE_V2,
        publishableKey: config.CHARGEBEE_PUBLISHABLEKEY_V2,
      });
    }
  }

  public initBilling$(planId?: PlanId) {
    return zip(this.getSubscriptionDetails(planId), this.fetchPaymentCards()).pipe(tap(() => this.initChargebee()));
  }
}
