import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/core/app-config';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HostsService {
  getHostsUrl = `${config.API_URL}/hosts`;

  constructor(private http: HttpClient) {}

  getHosts(): Observable<any> {
    return this.http.get(this.getHostsUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
}
