import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { UsersFacade } from '@app/shared/+state/users';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HasEntitlementForFeatureGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly usersFacade: UsersFacade
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { feature, callbackFn: successCallbackFn, redirectPath, noAccessCallbackFn: callbackFn } = route?.data ?? {};

    if (!feature) {
      console.error('You must specify the feature that you want to check entitlement for');
      return false;
    }

    if (!redirectPath && !callbackFn) {
      console.error('You must specify either a noAccessCallbackFn function or a redirect path');
      return false;
    }

    return this.usersFacade.entitlementForFeature$(feature).pipe(
      take(1),
      map((entitlement) => {
        if (entitlement?.available) {
          successCallbackFn?.();
          return true;
        }

        if (callbackFn) {
          return callbackFn();
        }

        this.router.navigate([redirectPath]);
        return this.router.createUrlTree([redirectPath]);
      })
    );
  }
}
