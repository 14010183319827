import { ChannelListing, LoadingState, UpdatePropertyOption } from '@app/shared/interfaces';
import { createReducer, on, Action } from '@ngrx/store';

import * as ListingsActions from './listings.actions';
import {
  ListingReviewEntity,
  UpdateListingReviewsFormAnalytics,
  UpdateListingReviewsFormEntity,
} from './listings.models';

import * as UsersActions from '../users/users.actions';

export const LISTINGS_FEATURE_KEY = 'listings';

export interface ListingsState {
  listingReviews: {
    loadingState: LoadingState;
    data: ListingReviewEntity | null;
    updateListings: {
      form: UpdateListingReviewsFormEntity;
      analytics: UpdateListingReviewsFormAnalytics;
      loadingStates: {
        [UpdatePropertyOption.CreateNewProperty]: LoadingState;
        [UpdatePropertyOption.MergeProperty]: LoadingState;
        [UpdatePropertyOption.MuteProperty]: LoadingState;
        [UpdatePropertyOption.UnmuteProperty]: LoadingState;
      };
    };
  };
}

export interface ListingsPartialState {
  readonly [LISTINGS_FEATURE_KEY]: ListingsState;
}

export const initialListingsState: ListingsState = {
  listingReviews: {
    loadingState: LoadingState.NotSent,
    data: null,
    updateListings: {
      form: null,
      analytics: null,
      loadingStates: {
        [UpdatePropertyOption.CreateNewProperty]: LoadingState.NotSent,
        [UpdatePropertyOption.MergeProperty]: LoadingState.NotSent,
        [UpdatePropertyOption.MuteProperty]: LoadingState.NotSent,
        [UpdatePropertyOption.UnmuteProperty]: LoadingState.NotSent,
      },
    },
  },
};

const reducer = createReducer(
  initialListingsState,

  // Reset the store when login actions happen
  on(UsersActions.resetStore, () => initialListingsState),

  on(ListingsActions.loadListingReviews, (state) => ({
    ...state,
    listingReviews: {
      ...state.listingReviews,
      loadingState: LoadingState.Pending,
    },
  })),

  on(ListingsActions.loadListingReviewsSuccess, (state, { response }) => ({
    ...state,
    listingReviews: ((response) => {
      const entities = response.listings.reduce((entities: ListingReviewEntity['listings'], record: ChannelListing) => {
        return {
          ...entities,
          [record.listing_id]: record,
        };
      }, {});

      return {
        ...state.listingReviews,
        ...{
          loadingState: LoadingState.Success,
          data: { ...response, ...{ listings: entities } },
        },
      };
    })(response),
  })),

  on(ListingsActions.loadListingReviewsFailure, (state) => ({
    ...state,
    listingReviews: {
      ...state.listingReviews,
      loadingState: LoadingState.Error,
    },
  })),

  on(ListingsActions.updateListingReviewsForm, (state, { form }) => ({
    ...state,
    listingReviews: ((form) => {
      return {
        ...state.listingReviews,
        updateListings: {
          ...state.listingReviews.updateListings,
          form: { ...state.listingReviews.updateListings.form, ...form },
        },
      };
    })(form),
  })),

  on(ListingsActions.saveListingReviewsForm, (state) => ({
    ...state,
    listingReviews: (() => {
      return {
        ...state.listingReviews,
        updateListings: {
          ...state.listingReviews.updateListings,
          form: {
            ...state.listingReviews.updateListings.form,
          },
          loadingStates: {
            [UpdatePropertyOption.CreateNewProperty]: LoadingState.Pending,
            [UpdatePropertyOption.MergeProperty]: LoadingState.Pending,
            [UpdatePropertyOption.MuteProperty]: LoadingState.Pending,
            [UpdatePropertyOption.UnmuteProperty]: LoadingState.Pending,
          },
        },
      };
    })(),
  })),

  on(ListingsActions.bulkCreatePropertiesSuccess, (state) => ({
    ...state,
    listingReviews: (() => {
      return {
        ...state.listingReviews,
        updateListings: {
          ...state.listingReviews.updateListings,
          loadingStates: {
            ...state.listingReviews.updateListings.loadingStates,
            [UpdatePropertyOption.CreateNewProperty]: LoadingState.Success,
          },
        },
      };
    })(),
  })),
  on(ListingsActions.bulkMergePropertiesSuccess, (state) => ({
    ...state,
    listingReviews: (() => {
      return {
        ...state.listingReviews,
        updateListings: {
          ...state.listingReviews.updateListings,
          loadingStates: {
            ...state.listingReviews.updateListings.loadingStates,
            [UpdatePropertyOption.MergeProperty]: LoadingState.Success,
          },
        },
      };
    })(),
  })),
  on(ListingsActions.bulkMutePropertiesSuccess, (state) => ({
    ...state,
    listingReviews: (() => {
      return {
        ...state.listingReviews,
        updateListings: {
          ...state.listingReviews.updateListings,
          loadingStates: {
            ...state.listingReviews.updateListings.loadingStates,
            [UpdatePropertyOption.MuteProperty]: LoadingState.Success,
          },
        },
      };
    })(),
  })),
  on(ListingsActions.bulkUnMutePropertiesSuccess, (state) => ({
    ...state,
    listingReviews: (() => {
      return {
        ...state.listingReviews,
        updateListings: {
          ...state.listingReviews.updateListings,
          loadingStates: {
            ...state.listingReviews.updateListings.loadingStates,
            [UpdatePropertyOption.UnmuteProperty]: LoadingState.Success,
          },
        },
      };
    })(),
  })),
  on(ListingsActions.trackStartedMergeMatchSuccess, (state, action) => ({
    ...state,
    listingReviews: (() => {
      return {
        ...state.listingReviews,
        updateListings: {
          ...state.listingReviews.updateListings,
          analytics: {
            ...state.listingReviews.updateListings.analytics,
            ...action.analytics,
          },
        },
      };
    })(),
  }))
);

export function listingsReducer(state: ListingsState | undefined, action: Action) {
  return reducer(state, action);
}
