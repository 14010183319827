import { LoadingState } from '@app/shared/interfaces';
import { createReducer, on, Action } from '@ngrx/store';
import * as OptimizelyActions from './optimizely.actions';
import { EnabledFeatures } from './optimizely.models';

import * as UsersActions from '../users/users.actions';

export const OPTIMIZELY_FEATURE_KEY = 'optimizely';
export const OPTIMIZELY_DATA_TTL = 60000; // 1 minutes in milliseconds

export interface OptimizelyState {
  initLoadingState: LoadingState;
  enabledFeatures: {
    loadingState: LoadingState;
    data: EnabledFeatures | null;
    expiresAt: number;
  };
}

export interface OptimizelyPartialState {
  readonly [OPTIMIZELY_FEATURE_KEY]: OptimizelyState;
}

export const initialOptimizelyState: OptimizelyState = {
  initLoadingState: LoadingState.NotSent,
  enabledFeatures: {
    loadingState: LoadingState.NotSent,
    data: null,
    expiresAt: null,
  },
};

const reducer = createReducer(
  initialOptimizelyState,

  // Reset the store when login actions happen
  on(UsersActions.resetStore, () => initialOptimizelyState),

  on(OptimizelyActions.init, (state) => ({
    ...state,
    initLoadingState: LoadingState.Pending,
  })),
  on(OptimizelyActions.initSuccess, (state) => ({
    ...state,
    initLoadingState: LoadingState.Success,
  })),
  on(OptimizelyActions.initFailure, (state) => ({
    ...state,
    initLoadingState: LoadingState.Error,
  })),

  on(OptimizelyActions.loadEnabledFeatures, (state) => ({
    ...state,
    enabledFeatures: {
      ...state.enabledFeatures,
      loadingState: LoadingState.Pending,
    },
  })),

  on(OptimizelyActions.loadEnabledFeaturesSuccess, (state, { response }) => ({
    ...state,
    enabledFeatures: ((response) => {
      return {
        ...state.enabledFeatures,
        ...{
          loadingState: LoadingState.Success,
          data: response ? response : null,
        },
      };
    })(response),
  })),

  on(OptimizelyActions.loadEnabledFeaturesFailure, (state) => ({
    ...state,
    enabledFeatures: {
      ...state.enabledFeatures,
      loadingState: LoadingState.Error,
    },
  }))
);

export function optimizelyReducer(state: OptimizelyState | undefined, action: Action) {
  return reducer(state, action);
}
