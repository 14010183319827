// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNullOrUndefined(value: any): value is null | undefined {
  return value === null || value === undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNullUndefinedOrEmptyString(value: any): value is null | undefined {
  if (value) {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      (value && value.length > 0 && value.replace(/\s/g, '').length === 0)
    );
  }
  return true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isString(value: any): value is string {
  return typeof value === 'string';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isFunction(value: any): boolean {
  return typeof value === 'function';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArray(value: any): value is any[] {
  return Array.isArray(value);
}

export function IsJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
