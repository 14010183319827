import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Property } from '@app/shared/interfaces';

@Component({
  template: `
    <div class="p-12">
      <sbnb-knowledge [propertyId]="propertyId"></sbnb-knowledge>
    </div>
  `,
})
export class KnowledgeHarnessComponent {
  propertyId: Property['id'] | null = null;

  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe((params) => {
      this.propertyId = +params.get('propertyId');
    });
  }
}
