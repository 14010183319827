import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AlgoliaService } from '@app/shared/services/algolia.service';
import { config } from '../app-config';

@Injectable()
export class AlgoliaKeyInterceptor implements HttpInterceptor {
  config = config;

  constructor(private algoliaService: AlgoliaService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request URL contains the search_key query parameter
    const hasSearchKeyParam = request.params.has(config.ALGOLIA_SEARCH_KEY_NAME);

    if (!hasSearchKeyParam) {
      // If not, simply forward the request without any further action
      return next.handle(request);
    }

    const now = new Date();
    const diff = now.getTime() - this.algoliaService.keyLastRefreshedAt.getTime();

    // 5 hours
    if (diff > 18000000) {
      console.log('Algolia key expired, refreshing before making original API call...');

      return this.algoliaService.requestSecureAlgoliaKey().pipe(
        switchMap(() => {
          // Replace the search_key parameter with the updated key from Algolia
          const updatedParams = new HttpParams().set(
            config.ALGOLIA_SEARCH_KEY_NAME,
            this.algoliaService.algoliaSecureApiKey
          );

          const clonedRequest = request.clone({ params: updatedParams });

          // After key is refreshed, continue with the modified request
          return next.handle(clonedRequest);
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
