import { Injectable } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SegmentIoService } from '../segmentIo/segment-io.service';
import { OnesignalService } from '../onesignal/onesignal.service';

const APP_TITLE = environment.production ? 'Hospitable.com' : environment.name;
const SEPARATOR = ' | ';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private segmentIoService: SegmentIoService,
    private onesignalService: OnesignalService
  ) {}

  init() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap((route) => route.data),
        map((data) => {
          this.segmentIoService.page(this.router.url);

          window.uetq = window.uetq || [];
          window.uetq.push('event', 'page_view', {
            page_path: this.router.url,
          });

          if (this.router.url.indexOf('/inbox') > -1) {
            this.onesignalService.showSlideNotification();
          }

          if (data.title) {
            // If a route has a title set (e.g. data: {title: "Foo"}) then we use it
            return data.title;
          } else {
            // If not, we do a little magic on the url to create an approximation
            return this.router.url.split('/').reduce((acc, frag) => {
              if (acc && frag) {
                acc += SEPARATOR;
              }
              return this.router.url.split('/').reduce((acc, frag) => {
                if (acc && frag) {
                  acc += SEPARATOR;
                }
                return acc + TitleService.ucFirst(frag);
              });
            });
          }
        })
      )
      .subscribe((pathString) => this.titleService.setTitle(`${pathString} ${SEPARATOR} ${APP_TITLE}`));
  }

  manuallySet(newTitle: string) {
    this.titleService.setTitle(`${newTitle} ${SEPARATOR} ${APP_TITLE}`);
  }

  static ucFirst(string) {
    if (!string) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
