import { Injectable } from '@angular/core';
import { ListingReview, UpdatePropertyOption } from '@app/shared/interfaces';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ListingActions from './listings.actions';
import { UpdateListingReviewsFormEntity } from './listings.models';
import * as ListingSelectors from './listings.selectors';
import { RequestOverride } from '../shared/request-override.interface';

@Injectable()
export class ListingsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  listingReviewsLoadingState$ = this.store.pipe(select(ListingSelectors.getListingReviewsLoadingState));
  listingReviews$: Observable<ListingReview> = this.store.pipe(select(ListingSelectors.getListingReviews));
  listingReviewsNeedingPrimaryHost$: Observable<ListingReview['listings']> = this.store.pipe(
    select(ListingSelectors.getListingReviewsNeedingPrimaryHost)
  );
  listingReviewsListingEntities$ = this.store.pipe(select(ListingSelectors.getListingReviewsListingEntities));
  listingReviewsHasCohosted$ = this.store.pipe(select(ListingSelectors.listingReviewsHasCohosted));

  updateListingReviewsCombinedLoadingStates$ = this.store.pipe(
    select(ListingSelectors.getUpdateListingReviewsCombinedLoadingStates)
  );
  updateListingReviewsFormEntity$ = this.store.pipe(select(ListingSelectors.getUpdateListingReviewsFormEntity));

  listingReviewsFormByAction$ = (status: UpdatePropertyOption) =>
    this.store.select(ListingSelectors.getListingReviewsFormByAction(status));

  listingReviewsFormLoadingStateCountByAction$ = (status: UpdatePropertyOption) =>
    this.store.select(ListingSelectors.getListingReviewsFormLoadingStateCountByAction(status));

  constructor(private readonly store: Store) {}

  loadListingReviews(
    platform: string,
    channelId: string,
    shouldLoadMergeCandidates = false,
    requestOverride?: RequestOverride
  ) {
    this.store.dispatch(
      ListingActions.loadListingReviews({
        platform,
        channelId,
        shouldLoadMergeCandidates,
        requestOverride,
      })
    );
  }

  listingReviewsPusherUpdate(listings: ListingReview['listings'], shouldLoadMergeCandidates = false) {
    this.store.dispatch(
      ListingActions.listingReviewsPusherUpdate({
        listings,
        shouldLoadMergeCandidates,
      })
    );
  }

  updateListingReviewsFormEntity(form: UpdateListingReviewsFormEntity) {
    this.store.dispatch(
      ListingActions.updateListingReviewsForm({
        form,
      })
    );
  }

  saveListingReviewsForm() {
    this.store.dispatch(ListingActions.saveListingReviewsForm());
  }

  trackStartedMergeMatch() {
    this.store.dispatch(ListingActions.trackStartedMergeMatch());
  }
}
