import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sbnb-dialog-delete-generic',
  templateUrl: './dialog-delete-generic.component.html',
  styleUrls: ['./dialog-delete-generic.component.scss'],
})
export class DialogDeleteGenericComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {}

  okClicked() {
    this.dialogRef.close(true);
  }

  cancelClicked() {
    this.dialogRef.close(false);
  }
}
