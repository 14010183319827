import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

interface TurnoActivateResponse {
  redirect: string;
}

@Injectable({
  providedIn: 'root',
})
export class TurnoService {
  constructor(private http: HttpClient) {}

  activateTurnoAccount() {
    return this.http.get<TurnoActivateResponse>(`${environment.apiUrl}/partnerships/turno/generate-signup-link`);
  }
}
