<div
  *ngIf="{ isOpen: open$ | async } as context"
  class="flex flex-col"
  [class.divider]="showDivider"
  [style.gap]="spacing + 'px'">
  <div class="collapsible-card__header group" [class.disabled]="!canBeClosed" (click)="toggle()">
    <div class="w-full">
      <ng-content select="[title]"></ng-content>
    </div>
    <mat-icon
      *ngIf="canBeClosed"
      class="collapsible-card__toggle text-night-lighter"
      [ngClass]="{
        open: context.isOpen,
        'order-first': headerCaretPosition === 'before',
        'group-hover:text-grass-darker': hoverColor === 'grass-darker',
        'group-hover:text-raspberry-darker': hoverColor === 'raspberry-darker'
      }"
      [matTooltip]="context.isOpen ? 'Collapse' : 'Expand'"
      matTooltipPosition="left">
      expand_less
    </mat-icon>
  </div>
  <!-- Conditionally rendered projected content -->
  <!-- To use: <ng-template sbnbCollapsibleCardContent>Some content</ng-template> -->
  <div *ngIf="context.isOpen || !canBeClosed">
    <ng-container [ngTemplateOutlet]="content?.templateRef"></ng-container>
  </div>
</div>
