export enum SignupStatusError {
  Denied = 'access_denied',
  Unknown = 'unknown',
  ApiError = 'api_error',
  InvalidState = 'invalid_state',
}

export interface SignUpErrorResponse {
  error: {
    error: boolean;
    error_reason: string;
    error_message: string;
    detail?: string;
  };
}

export enum LoginFlow {
  Code = 'code',
  Link = 'link',
}

export interface LoginFlowApiResponse {
  flow: LoginFlow;
  device_trusted: boolean;
}

export interface LoginResult extends LoginFlowApiResponse {
  success: boolean;
}
