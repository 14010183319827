import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AI_FEATURE_KEY, AIState } from './AI.reducer';

// Lookup the 'AI' feature state managed by NgRx
export const getAIState = createFeatureSelector<AIState>(AI_FEATURE_KEY);
export const getGenerateReplyLoadingState = createSelector(
  getAIState,
  (state: AIState) => state.generateReply.loadingState
);
export const getGeneratedReply = createSelector(getAIState, (state: AIState) => state.generateReply.data);

export const getGeneratedImprovementLoadingState = createSelector(
  getAIState,
  (state: AIState) => state.generateImprovement.loadingState
);
export const getGeneratedImprovement = createSelector(getAIState, (state: AIState) => state.generateImprovement.data);
