import { Pipe, PipeTransform } from '@angular/core';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';

@Pipe({
  name: 'numNights',
})
export class NumNightsPipe implements PipeTransform {
  transform(checkin: any, checkout?: any): any {
    return differenceInCalendarDays(checkout, checkin);
  }
}
