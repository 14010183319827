import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromListingsReducer from './listings.reducer';
import { ListingsEffects } from './listings.effects';
import { ListingsService } from './listings.service';
import { ListingsFacade } from './listings.facade';
import { AnalyticsMetaReducer } from './listings-analytics-meta.reducer';

export const metaReducers: MetaReducer<unknown>[] = [AnalyticsMetaReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromListingsReducer.LISTINGS_FEATURE_KEY, fromListingsReducer.listingsReducer, {
      metaReducers,
    }),
    EffectsModule.forFeature([ListingsEffects]),
  ],
  providers: [ListingsFacade, ListingsService],
})
export class ListingsStateModule {}
