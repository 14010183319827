import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private _expanded: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public readonly expanded$: Observable<any> = this._expanded.asObservable();

  constructor() {
    this.checkExpandedFromLocalStorage();
  }

  checkExpandedFromLocalStorage() {
    const defaultVal = localStorage.getItem('nav-expanded') === '0' ? false : true;

    this.setExpanded(defaultVal);
  }

  setExpanded(expanded: boolean) {
    localStorage.setItem('nav-expanded', expanded ? '1' : '0');
    this._expanded.next(expanded);
  }
}
