<article
  class="sb-toast"
  [attr.data-toast-type]="message.level"
  fxLayout
  fxLayoutAlign="start center"
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start stretch"
  fxLayoutGap="24px">
  <div fxFlex fxLayout="column" fxLayoutGap="16px" fxFlexFill>
    <header fxLayout.lt-md>
      <h1 fxFlex [innerHTML]="message.title">{{ message.title }}</h1>
      <mat-icon fxHide.gt-sm (click)="!isDismissible ? hide() : dismiss()" aria-label="Dismiss message">close</mat-icon>
    </header>
    <section ngClass="sb-toast-message" [innerHTML]="message.description">{{ message.description }}</section>
  </div>

  <div fxLayout fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="auto" fxLayoutGap="12px">
    <button
      mat-flat-button
      *ngFor="let action of buttons"
      class="button__small button__secondary sb-toast-action"
      (click)="cta(action)">
      {{ action.text }}
    </button>

    <mat-icon *ngIf="!isDismissing" fxHide.lt-md (click)="!isDismissible ? hide() : dismiss()">close</mat-icon>
  </div>
</article>
