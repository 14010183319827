import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[sbnbCollapsibleCardContent]',
})
export class CollapsibleCardContentDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'sbnb-collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrls: ['./collapsible-card.component.scss'],
})
export class CollapsibleCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() defaultOpen = false;
  @Input() showDivider = true;
  @Input() spacing = '0';
  @Input() headerCaretPosition: 'before' | 'after' = 'after';
  @Input() canBeClosed = true;
  @Input() hoverColor: 'grass-darker' | 'raspberry-darker' = 'raspberry-darker';

  @Output() openToggled = new EventEmitter<{ open: boolean }>();

  @ContentChild(CollapsibleCardContentDirective, { static: false }) content: CollapsibleCardContentDirective;

  public open$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.open$.next(this.defaultOpen);
    this.open$.pipe(distinctUntilChanged()).subscribe((open) => {
      this.openToggled.emit({ open });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.canBeClosed && changes.canBeClosed.currentValue === false) {
      this.open$.next(true);
    }
  }

  public ngOnDestroy(): void {
    this.open$.complete();
  }

  public toggle() {
    this.open$.next(!this.open$.value);
  }
}
