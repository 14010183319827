import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'sbnb-command-k',
  templateUrl: './command-k.component.html',
  styleUrls: ['./command-k.component.scss'],
  animations: [trigger('fadeInOut', [state('void', style({ opacity: 0 })), transition('void <=> *', [animate(100)])])],
})
export class CommandKComponent {
  isVisible = false;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    // Check for Command + K on Mac or Ctrl + K on other platforms
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      this.isVisible = !this.isVisible;
    }
  }
}
