import { PriceType } from './price_type';
import { CreditNoteAPIRes, CreditNote } from './credit_note';

export enum InvoiceStatus {
  PAID = 'paid',
  PAYMENT_DUE = 'payment_due',
  NOT_PAID = 'not_paid',
  PENDING = 'pending',
  VOIDED = 'voided',
  POSTED = 'posted',
}

export interface InvoiceAPIRes {
  invoice_id: string;
  status: InvoiceStatus;
  date: string;
  amount: string;
  download: string;
  price_type: PriceType;
  credit_notes: CreditNoteAPIRes[];
}

export interface LegacyInvoice {
  text: string;
  url: string;
}

export interface GetInvoicesApiRes {
  data: InvoiceAPIRes[];
  meta: {
    offset: string;
    legacy_invoices_url: LegacyInvoice;
  };
}

export interface GetInvoicesServiceRes {
  data: Invoice[];
  meta: {
    offset: string;
    legacy_invoices_url: LegacyInvoice;
  };
}

export class Invoice {
  readonly id: string;
  readonly date: string;
  readonly amount: string;
  readonly download: string;

  public downloading?: boolean;

  private _credit_notes: CreditNote[] = [];
  public get credit_notes() {
    return this._credit_notes;
  }

  private _status: InvoiceStatus;
  public get status() {
    return this._status;
  }

  private _price_type: PriceType;
  public get price_type() {
    return this._price_type;
  }

  public constructor(newInvoice: InvoiceAPIRes) {
    this.id = newInvoice.invoice_id;
    this.setStatus(newInvoice.status);
    this.date = newInvoice.date;
    this.amount = newInvoice.amount;
    this.download = newInvoice.download;
    this.setPriceType(newInvoice.price_type);
    this.setCreditNotes(newInvoice.credit_notes);
  }

  private setCreditNotes(creditNotes: CreditNoteAPIRes[]) {
    if (creditNotes) {
      this._credit_notes = creditNotes.map((cn) => new CreditNote(cn));
    }
  }

  private setStatus(status: InvoiceStatus) {
    if (!(status in InvoiceStatus)) {
      // Generic error log here to send dodgy status to Error tracking
    }
    this._status = status;
  }

  private setPriceType(pt: PriceType) {
    if (!(pt in PriceType)) {
      // Generic error log here to send dodgy status to Error tracking
    }
    this._price_type = pt;
  }

  public isPaid() {
    return this.status === InvoiceStatus.PAID;
  }

  public isPaymentDue() {
    return this.status === InvoiceStatus.PAYMENT_DUE;
  }

  public isNotPaid() {
    return this.status === InvoiceStatus.NOT_PAID;
  }

  public isPending() {
    return this.status === InvoiceStatus.PENDING;
  }

  public isVoided() {
    return this.status === InvoiceStatus.VOIDED;
  }

  public isPosted() {
    return this.status === InvoiceStatus.POSTED;
  }
}
