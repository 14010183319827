import { LoadingState } from '@app/shared/interfaces';
import { createReducer, on, Action } from '@ngrx/store';

import * as BillingActions from './billing.actions';
import { DeviceAddonResponse, DowngradeChecks, Plan, PlanId, Subscription } from './billing.models';

import * as UsersActions from '../users/users.actions';

export const BILLING_FEATURE_KEY = 'billing';

export interface BillingState {
  subscription: {
    loadingState: LoadingState;
    data: Subscription | null;
  };
  plans: {
    loadingState: LoadingState;
    data: Plan[] | null;
  };
  pendingSubscriptionChange: {
    inProgress: boolean;
    planId: PlanId | null;
    isDowngrade: boolean;
    submitting: LoadingState;
    downgradeChecks: DowngradeChecks | null;
    loadingState: LoadingState;
  };
  smartDevices: {
    loadingState: LoadingState;
    data: DeviceAddonResponse;
    submitting: LoadingState;
  };
  cancelSubscription: {
    submitting: LoadingState;
  };
}

export interface BillingPartialState {
  readonly [BILLING_FEATURE_KEY]: BillingState;
}

export const initialBillingState: BillingState = {
  subscription: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  plans: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  pendingSubscriptionChange: {
    inProgress: false,
    planId: null,
    isDowngrade: false,
    submitting: LoadingState.NotSent,
    downgradeChecks: null,
    loadingState: LoadingState.NotSent,
  },
  smartDevices: {
    loadingState: LoadingState.NotSent,
    data: null,
    submitting: LoadingState.NotSent,
  },
  cancelSubscription: {
    submitting: LoadingState.NotSent,
  },
};

const reducer = createReducer(
  initialBillingState,

  // Reset the store when login actions happen
  on(UsersActions.resetStore, () => initialBillingState),

  /** Subscription fetching */
  on(BillingActions.loadSubscription, (state) => ({
    ...state,
    subscription: {
      ...state.subscription,
      loadingState: LoadingState.Pending,
    },
  })),

  on(BillingActions.loadSubscriptionSuccess, (state, { response }) => ({
    ...state,
    subscription: {
      ...state.subscription,
      loadingState: LoadingState.Success,
      data: response,
    },
  })),

  on(BillingActions.loadSubscriptionFailure, (state, { response }) => ({
    ...state,
    subscription: {
      ...state.subscription,
      loadingState: LoadingState.Error,
    },
  })),

  /** Plan fetching */
  on(BillingActions.loadPlans, (state) => ({
    ...state,
    plans: {
      ...state.plans,
      loadingState: LoadingState.Pending,
    },
  })),
  on(BillingActions.loadPlansSuccess, (state, { response }) => ({
    ...state,
    plans: {
      ...state.plans,
      loadingState: LoadingState.Success,
      data: response,
    },
  })),
  on(BillingActions.loadPlansFailure, (state) => ({
    ...state,
    plans: {
      ...state.plans,
      loadingState: LoadingState.Error,
    },
  })),

  /** Launch mangage-subscription wizard */
  on(BillingActions.initiateSubscriptionChange, (state, { planId, downgrade }) => ({
    ...state,
    pendingSubscriptionChange: {
      inProgress: true,
      planId,
      submitting: LoadingState.NotSent,
      isDowngrade: downgrade,
      downgradeChecks: null,
      loadingState: LoadingState.NotSent,
    },
  })),
  on(BillingActions.abandonSubscriptionChange, (state) => ({
    ...state,
    pendingSubscriptionChange: {
      inProgress: false,
      planId: null,
      isDowngrade: null,
      submitting: LoadingState.NotSent,
      downgradeChecks: null,
      loadingState: LoadingState.NotSent,
    },
  })),

  /** Downgrade checks fetching */
  on(BillingActions.loadDowngradeIssues, (state) => ({
    ...state,
    pendingSubscriptionChange: {
      ...state.pendingSubscriptionChange,
      loadingState: LoadingState.Pending,
    },
  })),
  on(BillingActions.loadDowngradeIssuesSuccess, (state, { response }) => ({
    ...state,
    pendingSubscriptionChange: {
      ...state.pendingSubscriptionChange,
      loadingState: LoadingState.Success,
      downgradeChecks: response,
    },
  })),
  on(BillingActions.loadDowngradeIssuesFailure, (state) => ({
    ...state,
    pendingSubscriptionChange: {
      ...state.pendingSubscriptionChange,
      loadingState: LoadingState.Error,
    },
  })),

  /** Change a current subscription to a new plan */
  on(BillingActions.changeSubscription, (state) => ({
    ...state,
    pendingSubscriptionChange: {
      ...state.pendingSubscriptionChange,
      submitting: LoadingState.Pending,
    },
  })),
  on(BillingActions.changeSubscriptionSuccess, (state) => ({
    ...state,
    pendingSubscriptionChange: {
      ...state.pendingSubscriptionChange,
      submitting: LoadingState.Success,
    },
  })),
  on(BillingActions.changeSubscriptionFailure, (state) => ({
    ...state,
    pendingSubscriptionChange: {
      ...state.pendingSubscriptionChange,
      submitting: LoadingState.Error,
    },
  })),

  /** Device addon */
  on(BillingActions.loadDeviceAddon, (state) => ({
    ...state,
    smartDevices: {
      ...state.smartDevices,
      loadingState: LoadingState.Pending,
    },
  })),

  on(BillingActions.loadDeviceAddonSuccess, (state, { response }) => ({
    ...state,
    smartDevices: {
      ...state.smartDevices,
      loadingState: LoadingState.Success,
      data: response,
    },
  })),

  on(BillingActions.loadDeviceAddonFailure, (state) => ({
    ...state,
    smartDevices: {
      ...state.smartDevices,
      loadingState: LoadingState.Error,
    },
  })),

  on(BillingActions.confirmDeviceAddon, (state) => ({
    ...state,
    smartDevices: {
      ...state.smartDevices,
      submitting: LoadingState.Pending,
    },
  })),

  on(BillingActions.confirmDeviceAddonSuccess, (state) => ({
    ...state,
    smartDevices: {
      ...state.smartDevices,
      submitting: LoadingState.Success,
    },
  })),

  on(BillingActions.confirmDeviceAddonFailure, (state) => ({
    ...state,
    smartDevices: {
      ...state.smartDevices,
      submitting: LoadingState.Error,
    },
  })),

  /** Cancel subscription */
  on(BillingActions.cancelSubscription, (state) => ({
    ...state,
    cancelSubscription: {
      submitting: LoadingState.Pending,
    },
  })),
  on(BillingActions.cancelSubscriptionSuccess, (state) => ({
    ...state,
    cancelSubscription: {
      submitting: LoadingState.Success,
    },
  })),
  on(BillingActions.cancelSubscriptionFailure, (state) => ({
    ...state,
    cancelSubscription: {
      submitting: LoadingState.Error,
    },
  }))
);

export function billingReducer(state: BillingState | undefined, action: Action) {
  return reducer(state, action);
}
