import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse, SignUpWithAirbnbCallback, SignUpWithAirbnbUrl, User } from '@app/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {}

  loadUser(): Observable<ApiResponse<User>> {
    return this.http.get<ApiResponse<User>>(`${this.apiUrl}/user`);
  }

  signUpWithAirbnb(): Observable<ApiResponse<SignUpWithAirbnbUrl>> {
    return this.http.get<ApiResponse<SignUpWithAirbnbUrl>>(`${this.apiUrl}/signup/airbnb/authorization/url`);
  }

  signUpWithAirbnbCallback(code: string, state: string): Observable<ApiResponse<SignUpWithAirbnbCallback>> {
    return this.http.post<ApiResponse<SignUpWithAirbnbCallback>>(
      `${this.apiUrl}/signup/airbnb/authorization/callback`,
      {
        code,
        state,
      }
    );
  }
}
