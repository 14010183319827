import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {
  NotificationData,
  NotificationAction,
  NotificationActionType,
  NotificationsService,
} from '../../../core/notifications/notifications.service';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { NotificationType } from '@app/shared/interfaces';

@Component({
  selector: 'sbnb-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnChanges {
  @Input() message: NotificationData;
  @Output() onHide = new EventEmitter();

  buttons: NotificationAction[];
  dismissible: NotificationAction;
  isDismissible: boolean;
  isDismissing: boolean;

  constructor(
    private notificationService: NotificationsService,
    private toast: ToastNotificationsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message.currentValue.actions) {
      this.setButtons();
      this.setDismissible();
    }
  }

  setButtons() {
    this.buttons = this.message.actions.filter((action) => action.type === NotificationActionType.BUTTON);
  }

  setDismissible() {
    this.dismissible = this.message.actions.find((action) => action.type === NotificationActionType.DISMISSIBLE);
    this.isDismissible = !!this.dismissible;
  }

  hide() {
    this.onHide.emit(this.message);
  }

  cta(action: NotificationAction) {
    if (action.url) {
      (window as any).location = action.url;
    }
  }

  dismiss() {
    if (this.isDismissible) {
      this.isDismissing = true;
      this.notificationService.dismissNotification(this.dismissible.url, this.message).subscribe((res) => {
        this.isDismissing = false;
        if (!res) {
          this.toast.open(`Error dismissing the notification`, 'Dismiss', NotificationType.Error);
        }
      });
    }
  }
}
