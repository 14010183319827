import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@app/core/app-config';
import { interval, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export interface MonolithSecureApiKeyResponse {
  key: string;
  valid_until: {
    date: string; // weird format: 2022-10-24 16:44:48.941629
    timezone: string; // UTC
    timezone_type: number; // ??? 3
  };
}

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  config = config;
  algoliaSecureApiKey: string | null = null;

  refreshSubscription?: Subscription;
  keyLastRefreshedAt: Date;

  constructor(private http: HttpClient) {}

  public requestSecureAlgoliaKey(): Observable<string> {
    return this.http.post(`${config.API_URL}/users/current/search-key`, {}).pipe(
      tap((res: MonolithSecureApiKeyResponse) => {
        if (res.key) {
          this.algoliaSecureApiKey = res.key;
          this.keyLastRefreshedAt = new Date();
        }
      }),
      map((res: MonolithSecureApiKeyResponse) => res.key)
    );
  }

  private setAlgoliaHeaders(apiKey: string = this.algoliaSecureApiKey) {
    return {
      method: 'POST',
      headers: new Headers({
        'X-Algolia-Application-Id': config.ALGOLIA_APPLICATION_ID,
        'X-Algolia-API-Key': apiKey,
      }),
    };
  }
}
