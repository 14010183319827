<div
  *ngIf="size === NoItemsSize.Normal"
  class="noitems__container"
  fxLayout="column"
  fxLayoutGap="18px"
  fxLayoutAlign="center center">
  <sbnb-icon *ngIf="icon" [icon]="icon" [size]="50" class="text-smoke-darker"></sbnb-icon>
  <h4 *ngIf="text" class="text-center pre-wrap">{{ text }}</h4>
  <span *ngIf="subtext" class="text-center pre-wrap">{{ subtext }}</span>
  <div *ngIf="buttons && buttons.length > 0">
    <button
      *ngFor="let button of buttons"
      mat-flat-button
      [class.button__small]="!button.size || button.size !== 'normal'"
      (click)="buttonClicked(button.id)">
      {{ button.label }}
    </button>
  </div>
</div>

<div
  *ngIf="size === NoItemsSize.Mini"
  class="noitems__container noitems__container-mini"
  fxLayout="row"
  fxLayoutGap="18px"
  fxLayoutAlign="space-between center"
  fxLayout.lt-md="column">
  <sbnb-icon *ngIf="icon" [icon]="icon" [size]="50"></sbnb-icon>

  <div fxFlex>
    <h4 *ngIf="text">{{ text }}</h4>
    <span *ngIf="subtext">{{ subtext }}</span>
  </div>

  <div *ngIf="buttons && buttons.length > 0">
    <sbnb-button
      *ngFor="let button of buttons"
      [size]="button.size"
      [type]="button.type ? button.type : 'primary'"
      (clicked)="buttonClicked(button.id)">
      {{ button.label }}
    </sbnb-button>
  </div>
</div>
