import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, zip } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay } from 'rxjs/operators';
import { config } from '../app-config';
import { Features, UserPermissions } from './user-permissions.model';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  private permissionCheckUrl = `${config.API_URL}/user/permissions`;

  private readonly _loaded: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public loaded = this._loaded.asObservable();

  private _permissions: BehaviorSubject<any> = new BehaviorSubject({});
  public readonly permissions: Observable<UserPermissions> = from(this._permissions);
  public hasOwnerStatements$ = this.hasAccessTo(Features.PERM_OPERATIONS_OWNER_STATEMENTS);

  constructor(private readonly http: HttpClient) {}

  public getPermissionsFromApi(): Observable<boolean> {
    return this.http.get(this.permissionCheckUrl).pipe(
      map((res: any) => {
        if (res.data) {
          localStorage.setItem('user_permissions', JSON.stringify(res.data));
          this._permissions.next(res.data);
          this._loaded.next(true);
          return true;
        } else {
          return false;
        }
      }),
      catchError((err) => {
        this._loaded.next(false);
        return of(false);
      })
    );
  }

  public hasAccessTo(feature: Features): Observable<boolean> {
    return this.permissions.pipe(
      map((perms: UserPermissions) => {
        return perms.all_features || perms.is_admin || perms.features?.includes(feature);
      })
    );
  }

  public isAdmin(): boolean {
    const permissions = this._permissions.getValue();

    return permissions.is_admin || permissions.all_features || permissions.is_team_owner;
  }

  public isPrimaryUser(): Observable<boolean> {
    return this.permissions.pipe(
      map((perms: UserPermissions) => {
        return perms.is_team_owner;
      })
    );
  }

  public calendarPermissions$ = this.getPermissionsFromApi().pipe(
    filter((loaded) => Boolean(loaded)),
    mergeMap(() => this.calendarPermissionChecks$),
    map(([hasReservationsManagementAccess, hasCalendarAccess, userHasAccessToDisplayPricing, hasTasksAccess]) => ({
      hasReservationsManagementAccess,
      hasCalendarAccess,
      userHasAccessToDisplayPricing,
      hasTasksAccess,
    })),
    shareReplay(1)
  );

  private calendarPermissionChecks$ = zip(
    this.hasAccessTo(Features.PERM_MANAGE_RESERVATIONS),
    this.hasAccessTo(Features.PERM_CALENDAR),
    this.hasAccessTo(Features.PERM_CALENDAR_DISPLAY_PRICING),
    this.hasAccessTo(Features.PERM_TASKS)
  );
}
