import { createAction, props } from '@ngrx/store';
import { EnabledFeatures } from './optimizely.models';

export const init = createAction('[Optimizely] Init', props<{ forceReload?: boolean }>());
export const initSuccess = createAction('[Optimizely] Init Success', props<{ forceReload?: boolean }>());
export const initFailure = createAction('[Optimizely] Init Failure');
export const loadEnabledFeatures = createAction(
  '[Optimizely] Load Enabled Features',
  props<{ forceReload?: boolean }>()
);
export const loadEnabledFeaturesSuccess = createAction(
  '[Optimizely] Load Enabled Features Success',
  props<{
    response: EnabledFeatures;
    expiresAt?: number;
  }>()
);
export const loadEnabledFeaturesFailure = createAction('[Optimizely] Load Enabled Features Failure');
