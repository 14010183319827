import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { config } from '@app/core/app-config';
import { map, catchError } from 'rxjs/operators';
import { Filter } from '@app/shared/models/filter';
import { FilterService } from '../filter/filter.service';
import { of } from 'rxjs';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private getTeamUrl = `${config.API_URL}/operations/teammates?`;
  private getMemberUrl = `${config.API_URL}/operations/teammates/`;
  private getRolesUrl = `${config.API_URL}/team/services`;
  private createMemberUrl = `${config.API_URL}/operations/teammates/create`;
  private icalInstructionsurl = `${config.API_URL}/operations/teammates`;

  constructor(
    private http: HttpClient,
    private filterService: FilterService,
    private errorService: ErrorService
  ) {}

  sendIcalInstructions(uuid: string) {
    const url = `${this.icalInstructionsurl}/${uuid}/send-instructions`;

    return this.http.post(url, {}).pipe(map((res) => res));
  }

  getTeamMembers(filterCriteria?: Filter[], offset?: number, search?: string, limit?: number, paginate = true) {
    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    let url;

    url = `${this.getTeamUrl}pagination=${paginate}`;

    if (limit) {
      url = `${url}&limit=${limit}`;
    }

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }
    return this.http.post(url, { filters: apiFilters }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPossibleRoles() {
    return this.http.get(this.getRolesUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getTeamMember(uuid: string) {
    const url = `${this.getMemberUrl}${uuid}`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  createTeamMember(payload: any) {
    return this.http.post(this.createMemberUrl, payload).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => of(err))
    );
  }

  deleteTeamMember(uuid: string) {
    const url = `${this.getMemberUrl}${uuid}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  resetCalendarAccess(uuid: string) {
    const url = `${this.getMemberUrl}${uuid}/calendar`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res.data.ical_suffix;
      })
    );
  }

  updateTeamMember(uuid: string, payload: any) {
    const url = `${this.getMemberUrl}${uuid}`;

    // For the `availability key, we omit any which are available:false - per the API requirements
    // So lets filter those out
    const pay = Object.assign({}, payload);

    pay.availability = pay.availability.filter((av) => av.available);

    pay.availability.forEach((av, index, arr) => {
      if (!av.hour_start) {
        arr[index].hour_start = 0;
      }
      if (!av.hour_end) {
        arr[index].hour_end = 0;
      }
      if (!av.minute_start) {
        arr[index].minute_start = 0;
      }
      if (!av.minute_end) {
        arr[index].minute_end = 0;
      }
    });

    return this.http.put(url, pay).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
}
