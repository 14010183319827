<div fxLayout>
  <sbnb-nav></sbnb-nav>

  <main fxFlex class="subnav__exists" [ngClass.lt-md]="{ mobile: true, subnav__exists: false }">
    <div class="flex" class="webhooks__header">
      <h2 class="flex items-center gap-3">
        <sbnb-icon class="clickable" [routerLink]="['../']" icon="arrow_left_rose"></sbnb-icon>
        <span class="flex-1">Connected Apps</span>
      </h2>
    </div>
    <p class="text-gray-600 mb-4">Manage the apps that are connected to your Hospitable account.</p>

    <sbnb-loading-spinner *ngIf="loading"></sbnb-loading-spinner>

    <ng-container *ngIf="!loading">
      <sbnb-no-items
        *ngIf="(!loading && !apps) || apps.length === 0"
        icon="app"
        text="No connected apps found."
        subtext="Visit the apps page to see available integrations."></sbnb-no-items>

      <div *ngIf="apps && apps.length > 0" class="rules__container">
        <div
          *ngFor="let app of apps"
          class="flex flex-col border-smoke border rounded p-4 mb-4 h-full"
          data-testid="connected-app">
          <div class="flex justify-between items-center">
            <div>
              <h3 class="text-lg font-semibold mb-1">{{ app.name }}</h3>
              <span *ngIf="app.added_at_label" class="text-sm mb-2">Added: {{ app.added_at_label }}</span>
            </div>
            <img [src]="app.logo" alt="{{ app.name }} logo" class="h-16 w-16" />
          </div>
          <div class="flex items-center mt-2 flex-grow">
            <div *ngIf="app.scopes?.length">
              <span class="font-semibold">Has permission to:</span>
              <ul class="list-disc pl-5">
                <li class="mb-2" *ngFor="let scope of app.scopes">{{ scope }}</li>
              </ul>
            </div>
          </div>
          <div class="mt-4 flex justify-between">
            <button
              *ngIf="hasDetailsPage(app.code) | async"
              mat-flat-button
              class="button__secondary button__small"
              (click)="navigateToApp('/apps/' + app.code)">
              View app
            </button>
            <button
              *ngIf="canDisconnect$"
              mat-flat-button
              class="button__primary button__small"
              (click)="deleteAppPrompt(app)">
              Remove access
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </main>
</div>
