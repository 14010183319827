import { ActionReducer, UPDATE } from '@ngrx/store';
import { LoadingState, LocalStorageKey } from '@app/shared/interfaces';
import { getDataWithExpiry } from '@app/shared/utils';
import { OptimizelyState, OPTIMIZELY_FEATURE_KEY } from './optimizely.reducer';
import { EnabledFeatures } from './optimizely.models';

export function optimizelyCacheMetaReducer(reducer: any) {
  return (state: unknown, action: any) => {
    let optimizelyState: OptimizelyState = reducer(state, action);

    if (action.type === UPDATE && action.features && action.features.includes(OPTIMIZELY_FEATURE_KEY)) {
      const response = getDataWithExpiry<EnabledFeatures>(LocalStorageKey.optimizely);

      if (response) {
        optimizelyState = {
          ...optimizelyState,
          enabledFeatures: {
            data: response.data,
            loadingState: LoadingState.Success,
            expiresAt: response.expiresAt,
          },
        };
      }
    }

    return optimizelyState;
  };
}

export function OptimizelyCacheMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return optimizelyCacheMetaReducer(reducer);
}
