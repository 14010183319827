import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { NotificationType } from '@app/shared/interfaces';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';

@Component({
  selector: 'sbnb-custom-code-conditions',
  templateUrl: './custom-code-conditions.component.html',
  styleUrls: ['./custom-code-conditions.component.scss'],
})
export class CustomCodeConditionsComponent implements OnInit {
  @Input('meta') meta: any;
  @Input('customCode') customCodeOrig: any;
  customCode: any;
  searchCriteria: string;
  selectedIntValue: any;

  activeMetaCode: any;

  @ViewChild('intRadioGroup', { static: true }) intRadioGroup: MatRadioGroup;
  @ViewChild('searchInput', { static: false }) searchInput: any;

  @Output() triggerSave: EventEmitter<any> = new EventEmitter();

  constructor(private toast: ToastNotificationsService) {}

  ngOnInit() {
    // local object copy clone
    this.customCode = Object.assign({}, this.customCodeOrig);

    if (!this.customCode.condition) {
      this.customCode.all_conditions = true;
      this.customCode.condition = {
        key: '',
        operator: '',
        value: '',
      };
    }

    // Set the currently activated meta condition from the saved data
    this.activeMetaCode = this.meta.conditions.find((cond) => cond.key === this.customCode.condition.key);

    if (this.customCode.condition.operator) {
      this.setOperatorLabel(this.customCode.condition.operator);
    }

    if (typeof this.customCode.condition.value === 'boolean') {
      this.setBooleanLabel(this.customCode.condition.value);
    }
  }

  addCondition() {
    this.customCode.all_conditions = false;
  }

  setFocusToSearch() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 100);
  }

  setActiveCondition(newCondition) {
    this.customCode.all_conditions = false;
    this.activeMetaCode = newCondition;
    this.customCode.condition.key = newCondition.key;
    this.customCode.condition.operator = null;

    if (this.activeMetaCode.type === 'boolean' && !this.activeMetaCode.bool_true) {
      this.customCode.condition.value = true;
      this.customCode.condition.operator = '=';
      this.save();
    } else {
      this.customCode.condition.value = null;
    }
  }

  removeCondition() {
    this.selectedIntValue = null;
    this.customCode.all_conditions = true;
    this.customCode.condition = {
      key: null,
      operator: null,
      value: null,
    };
    this.save();
  }

  setBoolConditionValue(value: boolean, label: string) {
    this.customCode.condition.operator = '=';
    this.customCode.condition.value = value;
    this.customCode.condition.value_label = label;
    this.save();
  }

  setIntOperator(event: MatRadioChange) {
    if (event.value) {
      this.customCode.condition.operator = event.value;

      if (!this.customCode.condition.value) {
        this.customCode.condition.value = 1;
      }

      this.setOperatorLabel(event.value);
      this.save();
    }
  }

  setIntValue(val) {
    const validation = this.activeMetaCode.validation;
    const newValue = val.target.value;

    if (!validation) {
      this.customCode.condition.value = newValue;
      this.save();
    } else {
      let validationPassed = true;
      const max = validation.find((val) => val.type === 'max');
      const min = validation.find((val) => val.type === 'min');

      if (max && newValue > max.value) {
        validationPassed = false;

        this.toast.open(`Please enter a number less than or equal to ${max.value}`, 'Dismiss', NotificationType.Error);
      }

      if (min && newValue < min.value) {
        validationPassed = false;

        this.toast.open(
          `Please enter a number greater than or equal to ${min.value}`,
          'Dismiss',
          NotificationType.Error
        );
      }

      if (validationPassed) {
        this.customCode.condition.value = newValue;
        this.save();
      }
    }
  }

  setBooleanLabel(currentVal) {
    if (currentVal) {
      this.customCode.condition.value_label = this.activeMetaCode.bool_true;
    } else {
      this.customCode.condition.value_label = this.activeMetaCode.bool_false;
    }
  }

  setOperatorLabel(operator) {
    let result = '';
    switch (operator) {
      case '>':
        result = 'more than';
        break;

      case '<':
        result = 'less than';
        break;

      case '=':
        result = 'exactly';
        break;

      default:
        break;
    }

    this.customCode.condition.operator_label = result;
  }

  save() {
    let savePayload: any = {
      all_conditions: this.customCode.all_conditions,
    };

    if (this.customCode.condition.key !== null) {
      savePayload.condition = {
        key: this.customCode.condition.key,
        operator: this.customCode.condition.operator,
        value: this.customCode.condition.value,
      };
    }

    this.triggerSave.emit(savePayload);
  }
}
