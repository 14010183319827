<h2 class="nomargin">Preview message</h2>
<span class="subheading">See how this message will look in a real-life conversation</span>

<mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>

<ng-container *ngIf="!loading && conversations">
  <mat-form-field class="fw">
    <mat-select placeholder="Select a conversation" (selectionChange)="conversationChanged($event)">
      <mat-option *ngFor="let conv of conversations" [value]="conv.uuid">
        <div fxLayoutAlign="start center" fxLayoutGap="8px">
          <img class="checkin__icon" [src]="'/assets/iconography/' + conv.guest.platform + '.svg'" />
          <span>Conversation with {{ conv.guest.name }} ({{ conv.booking.status.description }})</span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="requiresTeammateSelection && teammates" class="fw">
    <mat-select placeholder="Select a teammate" (selectionChange)="teammateChanged($event)">
      <mat-option *ngFor="let tm of teammates" [value]="tm.uuid">
        {{ tm.name }} {{ tm.company_name ? '(' + tm.company_name + ')' : '' }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <span *ngIf="emailSubject" style="display: block; margin-bottom: 12px">Subject:</span>
  <div *ngIf="emailSubject" class="preview previewSubject" #previewSubject></div>

  <div *ngIf="rulesetData?.team?.notify_via === 'sms' && previewContents.length === 306" class="warning-sms-length">
    <strong>SMS messages are limited to 306 characters.</strong>
    Based on this conversation preview, your message would be truncated to what you see below. Consider shortening your
    template if possible, or switching to email.
  </div>

  <div class="preview pre-wrap" #preview>
    Select a conversation
    {{ requiresTeammateSelection ? 'and teammate' : '' }} above to preview...
  </div>
</ng-container>
