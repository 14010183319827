import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListingsResponse } from './listings.models';
import { RequestOverride } from '../shared/request-override.interface';

@Injectable({
  providedIn: 'root',
})
export class ListingsService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {}

  loadChannelListings(
    platform: string,
    channelId: string,
    requestOverride: RequestOverride
  ): Observable<ListingsResponse> {
    const url =
      requestOverride && requestOverride.url
        ? requestOverride.url
        : `${this.apiUrl}/settings/channels/${platform}/${channelId}/listings`;

    const headers = requestOverride && requestOverride.headers ? requestOverride.headers : {};
    return this.http.get<ListingsResponse>(url, headers);
  }
}
