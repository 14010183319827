<div *ngIf="isVisible" [@fadeInOut] class="cmd-k" fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign="space-between center">
    <h2 class="nomargin">We've retired ⌘ + K, for now...</h2>
    <sbnb-close (click)="isVisible = false"></sbnb-close>
  </div>
  <span>
    Thank you for trying out our ⌘ + K feature. We've been monitoring its usage and decided that it wasn't bringing our
    hosts enough time savings to justify the costs. Because of that, we're retiring ⌘ + K.
  </span>

  <span>
    We're keeping the improved search experience in the Inbox and hope to bring ⌘ + K back in another format in the
    future.
  </span>
</div>
