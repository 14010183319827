<div fxLayout>
  <sbnb-nav *ngIf="isLoggedIn"></sbnb-nav>

  <main
    fxFlex
    fxLayout
    fxLayoutAlign="center center"
    fxLayoutAlign.lt-md="center start"
    [ngClass.lt-md]="{ mobile: true, subnav__exists: false }">
    <div class="fof__container">
      <h1>Oops!</h1>
      <p>
        We can't seem to find the content you're looking for. Please check the link on your address bar or return to the
        dashboard.
      </p>
      <button style="margin-top: 12px" mat-flat-button class="button__primary" [routerLink]="['/dashboard']">
        Return to {{ isLoggedIn ? 'dashboard' : 'login' }}
      </button>
    </div>
  </main>
</div>
