import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { SmartCode } from '@app/shared/services/editor/editor-api.service';
import { EditorService } from '@app/shared/services/editor/editor.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sbnb-editor-smart-codes',
  templateUrl: './editor-smart-codes.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSmartCodesComponent implements OnInit {
  @Output() smartCodeSelected = new EventEmitter<SmartCode['key']>();
  @Input() multipleRules = false;

  public smartCodes$ = this.editorStateService.availableSmartCodes$;
  public canSeeSmartCodes$ = this.editorStateService.canSeeSmartCodes$;
  public smartCodesInUse$ = this.editorStateService.inUse$;
  public upsellWarning$ = this.editorStateService.upsellWarning$;
  public hasInsertedCode$ = new BehaviorSubject(false);

  constructor(private readonly editorStateService: EditorService) {}

  ngOnInit(): void {}

  public codeInserted(codeKey) {
    this.smartCodeSelected.emit(codeKey);
    this.hasInsertedCode$.next(true);
  }
}
