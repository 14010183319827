import { createAction, props } from '@ngrx/store';
import {
  ApiErrorResponse,
  ApiResponse,
  SignUpWithAirbnbCallback,
  SignUpWithAirbnbUrl,
  User,
} from '@app/shared/interfaces';
import { SignUpErrorResponse } from './users.models';

export const loadUser = createAction('[Users] Load User');
export const loadUserSuccess = createAction('[Users] Load UserSuccess', props<{ response: ApiResponse<User> }>());
export const loadUserFailure = createAction('[Users] Load User Failure', props<{ response: ApiErrorResponse }>());

export const signUpWithAirbnb = createAction('[Users] Sign Up With Airbnb');
export const signUpWithAirbnbRedirectToUrl = createAction(
  '[Users] Sign Up With Airbnb Redirect To Url',
  props<{ response: ApiResponse<SignUpWithAirbnbUrl> }>()
);
export const signUpWithAirbnbUrlFailure = createAction(
  '[Users] Sign Up With Airbnb Failure',
  props<{ response: ApiErrorResponse }>()
);

export const signUpWithAirbnbCallback = createAction(
  '[Users] Sign Up With Airbnb Callback',
  props<{ code: string; state: string }>()
);
export const signUpWithAirbnbCallbackSuccess = createAction(
  '[Shared] Sign Up With Airbnb Callback Success',
  props<{ response: ApiResponse<SignUpWithAirbnbCallback> }>()
);
export const signUpWithAirbnbCallbackFailure = createAction(
  '[Shared] Sign Up With Airbnb Callback Failure',
  props<{ response: SignUpErrorResponse }>()
);

export const resetStore = createAction('[Users] ResetStore');
