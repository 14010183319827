import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAIReducer from './AI.reducer';
import { AIEffects } from './AI.effects';
import { AIService } from './AI.service';
import { AIFacade } from './AI.facade';
import { AnalyticsMetaReducer } from './AI-analytics-meta.reducer';

export const metaReducers: MetaReducer<unknown>[] = [AnalyticsMetaReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAIReducer.AI_FEATURE_KEY, fromAIReducer.AIReducer, { metaReducers }),
    EffectsModule.forFeature([AIEffects]),
  ],
  providers: [AIFacade, AIService],
})
export class AIStateModule {}
