import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/core/app-config';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class SidebarCountsService {
  private countsUrl = `${config.API_URL}/counts`;

  constructor(private http: HttpClient) {}

  public getCountsFromApi(): Observable<any> {
    return this.http.get(this.countsUrl).pipe(
      map((res: { data: any }) => {
        if (res.data) {
          return res.data;
        }
      })
    );
  }
}
