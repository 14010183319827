import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

interface CacheEntry {
  response: HttpResponse<any>;
  expiration: number;
}

@Injectable({
  providedIn: 'root',
})
export class ApiCacheService {
  cache = new Map<string, CacheEntry>();

  get(key: string): HttpResponse<any> | undefined {
    const entry = this.cache.get(key);

    if (entry && entry.expiration > Date.now()) {
      return entry.response;
    }

    // Remove expired entry from cache, if we have one
    // Delete on a map attempts and returns a boolean whether it exists or not, so this is safe
    this.cache.delete(key);
    return undefined;
  }

  put(key: string, response: HttpResponse<any>, ttl: number): void {
    const entry: CacheEntry = {
      response,
      expiration: Date.now() + ttl,
    };

    this.cache.set(key, entry);
  }

  delete(url: string): boolean {
    console.log(`[API Cache] Deleting cache entry for ${url}`);
    return this.cache.delete(url);
  }

  clear(): void {
    console.log(`[API Cache] Clearing API cache`);
    this.cache.clear();
  }
}
