import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sbnb-resizer, [sbnb-resizer]',
  template: `
    <div
      class="resizer"
      [class.expanded]="isExpanded"
      [class.light]="color === 'light'"
      [ngClass]="{ right: direction === 'right', left: direction === 'left' }"
      (click)="onToggle.emit()"></div>
  `,
  styleUrls: ['./resizer.component.scss'],
})
export class ResizerComponent {
  @Input() public isExpanded: boolean = false;
  @Input() public color: 'light' | 'dark' = 'light';
  @Input() public direction: 'right' | 'left' = 'right';
  @Output() public onToggle = new EventEmitter<null>();
}
