import { Pipe, PipeTransform } from '@angular/core';
import { PropertyPhotoTag } from '@app/modules/direct/services/direct.service';

@Pipe({
  name: 'doesPropertyImageHaveTags',
})
export class DoesPropertyImageHaveTagsPipe implements PipeTransform {
  transform(imageId: number, tags: PropertyPhotoTag[]): boolean {
    return tags.some((tag) => tag.photo?.id === imageId);
  }
}
