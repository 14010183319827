import { Pipe, PipeTransform } from '@angular/core';
import { PropertyPhotoTag } from '@app/modules/direct/services/direct.service';

@Pipe({
  name: 'sortPropertyImageTags',
})
export class SortPropertyImageTagsPipe implements PipeTransform {
  transform(tags: PropertyPhotoTag[], imageId: number): PropertyPhotoTag[] {
    return tags.slice().sort((a, b) => {
      const aSet = a.photo?.id === imageId;
      const bSet = b.photo?.id === imageId;

      if (aSet && !bSet) return -1;
      if (!aSet && bSet) return 1;

      return a.label.localeCompare(b.label);
    });
  }
}
