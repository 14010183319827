import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

/**
 * Alert card for showing on page information with context
 *
 * Note: This card's host psuedo element is displaying as flex. Its
 * this opinionated since we use this card in just a few specific ways and
 * it makes it easier to add tailwind classes directly to the selector element.
 *
 * Example:
 * ```html
 * <sbnb-alert-card context="positive" class="flex-col gap-2">
 *  <img src="assets/icons/checkmark.svg" alt="checkmark" />
 *  <span>Success! Your changes have been saved.</span>
 * </sbnb-alert-card>
 * ```
 */
@Component({
  selector: 'sbnb-alert-card',
  template: `
    <ng-content></ng-content>
  `,
  styles: [
    `
      @import 'variables';

      :host {
        display: flex;
        border-radius: 4px;
        font-size: 14px;
        padding: 11px 16px; // minus a pixel to account for border

        img {
          width: 14px;
          height: 14px;
        }

        &.negative {
          background: $rose-lighter;
          border: 1px solid $rose;
          color: $rose-darker;
        }

        &.warning {
          background: $lemon-lighter;
          border: 1px solid $lemon-darker;
          color: $night;
        }

        &.neutral {
          background: $smoke-lighter;
          border: 1px solid $smoke-darker;
          color: $night;
        }

        &.info {
          background: $grape-lighter;
          border: 1px solid lighten($grape, 20%);
          color: $night;
        }

        &.positive {
          background: $grass-lighter;
          border: 1px solid $grass-darker;
          color: darken($grass-darker, 10%);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertCardComponent implements OnInit {
  @Input() public context: 'negative' | 'warning' | 'neutral' | 'info' | 'positive' = 'neutral';

  @HostBinding('class') get classes(): string {
    return this.context;
  }
  constructor() {}

  ngOnInit(): void {}
}
