import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromUsersReducer from './users.reducer';
import { UsersEffects } from './users.effects';
import { UsersService } from './users.service';
import { UsersFacade } from './users.facade';
import { AnalyticsMetaReducer } from './users-analytics-meta.reducer';

export const metaReducers: MetaReducer<unknown>[] = [AnalyticsMetaReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUsersReducer.USERS_FEATURE_KEY, fromUsersReducer.usersReducer, { metaReducers }),
    EffectsModule.forFeature([UsersEffects]),
  ],
  providers: [UsersFacade, UsersService],
})
export class UsersStateModule {}
