export enum EventType {
  PageView = 'Page Viewed',
  Action = 'Action',
}

export enum SegmentEvent {
  /*******  Acquisition — “Where Are Our Users / Customers Coming From?”    *******/
  SignUpWithAirbnbShown = 'fe_signup_with_airbnb_shown',
  StartedSignUpWithAirbnb = 'fe_started_signup_with_airbnb',
  SignUpWithAirbnbError = 'fe_signup_with_airbnb_error',
  SignUpWithAirbnbSuccess = 'fe_signup_with_airbnb_success',

  /*******  Activation — “How good is the user’s / customer’s first experience?”    *******/
  ClickedTrialBannerPrimaryCta = 'fe_clicked_trial_banner_primary_cta',
  ClickedTrialBannerSecondaryCta = 'fe_clicked_trial_banner_secondary_cta',
  ClickedTrialBannerVideo = 'fe_clicked_trial_banner_video',

  /*******  Retention — “How many of your customers are you retaining and why are you losing the others?”    *******/
  StartedMergeMatch = 'fe_merge_match_started',
  MergeMatchError = 'fe_merge_match_error',
  MergeMatchOutcome = 'fe_merge_match_outcome',

  ClickedNovaContentLink = 'fe_clicked_nova_content_link',
  ClickedNovaSidebarLink = 'fe_clicked_nova_sidebar_link',
  ClickedNovaIntroLink = 'fe_clicked_nova_intro_link',
  ClickedNovaDefaultLink = 'fe_clicked_nova_default_link',

  SignUpClickedEnableAllRuleSets = 'fe_signup_clicked_enable_all_rulesets',
  SignUpNoSavedRuleSets = 'fe_signup_no_saved_rulesets',
  SignUpSavedRuleSets = 'fe_signup_saved_rulesets',
  SignUpSavedRuleSetsError = 'fe_signup_saved_rulesets_error',
  SignUpScheduledSalesCall = 'fe_signup_scheduled_sales_call',
  AccountCreated = 'fe_account_created',
  SignupUserApiFailedToLoad = 'fe_signup_user_api_failed_to_load',
  SignupOnlyUnsupportedChannelsSelected = 'fe_signup_only_unsupported_channels_selected',
  SignupUnsupportedChannelsSelected = 'fe_signup_unsupported_channels_selected',
  AccountSubscribed = 'fe_account_subscribed',

  UpdateSupportPreference = 'fe_updated_support_preference',

  StartedCalendarBulkEdit = 'fe_started_calendar_bulk_edit',
  ClickedCalendarBulkEditDay = 'fe_clicked_calendar_bulk_edit_day',

  VisitedThread = 'Visited thread',
  MessageSentFromTheInbox = 'Message sent from the inbox',
  PreapprovedInquiry = 'Preapproved inquiry',
  DeniedInquiry = 'Denied inquiry',

  /*******  Referral — “How can you turn your customers into your advocates?”    *******/

  /*******  Other    *******/
  OptimizelyError = 'fe_optimizely_error',
  DemoMode412 = 'fe_demo_mode_412',
  CalendarConflictingEventsDialogOpened = 'fe_calendar_conflicting_events_dialog_opened',

  // AI Usage
  AIDraftMessageGenerated = 'Draft message generated',
  AIPrewrittenDraftMessageViewed = 'Prewritten draft message viewed',
  AIDraftMessageSentFromTheInbox = 'Draft message sent from the inbox',
  AIDraftMessageImproved = 'Draft message improved',
  AIUndoImprovement = 'Undo draft message',
  AIReviewReplySent = 'Review reply sent',
  AIReviewEdited = 'AI review edited',
  AIReviewEditActioned = 'Review Edit Actioned',
  AIEngaged = 'Engaged with AI',
  AISuggestionLiked = 'AI Suggestion Liked',
  AISuggestionDisliked = 'AI Suggestion Disliked',
  AISuggestionSurveyTriggered = 'AI Suggestion Survey Triggered',
  AISuggestionCleared = 'AI Suggestion Cleared',

  // Knowledge Hub
  KnowledgeHubImportStarted = 'Knowledge Hub Import Started',
  KnowledgeHubImported = 'Knowledge Hub Imported',
  KnowledgeHubImportSurveyTriggered = 'Knowledge Hub Import Survey Triggered',
  KnowledgeHubCustomQuestionAsked = 'Knowledge Hub Custom Question Asked',

  // Direct
  CreateDirectQuote = 'Create direct quote',
  CreateDirectQuoteError = 'Create direct quote error',
  SetAmountOnDirectQuote = 'Set amount on direct quote',
  SetGuestDetailsOnDirectQuote = 'Set guest details on direct quote',
  SetExpirationOnDirectQuote = 'Set expiration on direct quote',
  CreateDirectCustomQuote = 'Create direct custom quote',
  CreateDirectCustomQuoteError = 'Create direct custom quote error',

  // Rental agreements
  CreateRentalAgreementTemplate = 'Created rental agreement template',
  CreateRentalAgreementTemplateError = 'Created rental agreement template error',
  UpdateRentalAgreementTemplate = 'Updated rental agreement template',
  UpdateRentalAgreementTemplateError = 'Updated rental agreement template error',
  DeleteRentalAgreementTemplate = 'Deleted rental agreement template',
  DeleteRentalAgreementTemplateError = 'Deleted rental agreement template error',

  // Owner Statements
  OwnerStatementShareFeedbackDashboard = 'fe_owner_statements_share_feedback_dashboard',
  OwnerStatementsPropertyLimitReached = 'owner_statements_property_limit_reached',

  // Billing
  DirectPaymentCardsAuthorize3dsStarted = 'fe_direct_payment_cards_authorize_3ds_started',
  DirectPaymentCardsAuthorize3dsReturned = 'fe_direct_payment_cards_authorize_3ds_returned',
  DirectPaymentCardsAuthorize3dsFailed = 'fe_direct_payment_cards_authorize_3ds_failed',

  // Thread actions
  TurnOnThreadTranslations = 'fe_turn_on_thread_translations',
  TurnOffThreadTranslations = 'fe_turn_off_thread_translations',
  UnstarredThread = 'fe_unstarred_thread',
  StarredThread = 'fe_starred_thread',
  UnresolvedThread = 'fe_unresolved_thread',
  ResolvedThread = 'fe_resolved_thread',

  // Tax
  DirectTaxPropertyRemoved = 'fe_direct_tax_property_removed',
  DirectTaxPropertyAdded = 'fe_direct_tax_property_added',
  DirectTaxDeleted = 'fe_direct_tax_deleted',
  DirectTaxUpdated = 'fe_direct_tax_updated',
  DirectTaxCreated = 'fe_direct_tax_created',

  // Settings
  ChannelConnected = 'fe_channel_connected',

  // Property muting
  PropertyMutingError = 'fe_property_muting_error',
  PropertyUnmuted = 'fe_property_unmuted',
  PropertyMuted = 'fe_property_muted',
  PropertyMutingDialogOpened = 'fe_property_muting_dialog_opened',
  PropertiesUpdatedFeesDiscounts = 'properties_updated_fees_discounts',

  // Calendar Platform colors
  CalendarPlatformColorsDismissed = 'calendar_platform_colors_dismissed',
  CalendarPlatformColorsDisabled = 'calendar_platform_colors_disabled',
  CalendarPlatformColorsEnabled = 'calendar_platform_colors_enabled',

  // Smartlocks
  ClickAddSmartLockFromReservation = 'Click - Add a smart lock from Reservation',

  // Onboarding banner
  OnboardingBannerDismissed = 'fe_onboarding_banner_dismissed',
  OnboardingBannerInteraction = 'fe_onboarding_banner_interaction',

  // Guest Vetting
  GuestVerificationAdHocAvailable = 'fe_guest_verification_ad_hoc_available',
  GuestVerificationFlowStarted = 'fe_guest_verification_flow_started',
  GuestVerificationRequestConfirmAndSend = 'fe_guest_verification_request_confirm_and_send',
  GuestVerificationRequestContinue = 'fe_guest_verification_request_continue',
  GuestVerificationDetailsOpened = 'fe_guest_verification_details_opened',
  GuestVerificationWarningIgnored = 'fe_guest_verification_warning_ignored',
  GuestVerificationAllowCheckIn = 'fe_guest_verification_allow_check_in',
  GuestVerificationCheckInRestricted = 'fe_guest_verification_check_in_restricted',

  // Guest banner
  RenamedGuest = 'fe_renamed_guest',

  PosthogSessionIdError = 'fe_posthog_session_id_error',
  ManualBookingDialogOpened = 'fe_manual_booking_dialog_opened',
  DirectQuoteDialogOpened = 'fe_direct_quote_dialog_opened',
  DelightedNPSResponded = 'fe_delighed_nps_responded',
  SEGMENT_ERROR_KEY = 'signup_email_error',
  BroadcastDialogOpened = 'broadcast_dialog_opened',
  ChatGPTDebugError = 'chatgpt_debug_error',
  UsedCannedResponse = 'Used a Canned response',
  ParentChildConfigStageSuccessComplete = 'Parent-child Config Stage success Complete',
  ParentChildConfigStageSelectParentComplete = 'Parent-child Config Stage selectParent Complete',
  ParentChildConfigStageSelectInteractionComplete = 'Parent-child Config Stage selectInteraction Complete',
  ParentChildConfigStageReviewConfirmationComplete = 'Parent-child Config Stage reviewConfirmation Complete',
  ParentChildConfigCompleted = 'Parent-child Config Completed',
  ParentChildRemovalStarted = 'Parent-child Removal Started',
  ParentChildRemovalCompleted = 'Parent-child Removal Completed',
  PropertiesAppliedFilter = 'fe_properties_applied_filter',
  ParentChildConfigStarted = 'Parent-child Config Started',
  ParentChildConfigValidationFailed = 'Parent-child Config Validation Failed',
  LoginError = 'fe_login_error',
  FalsePositiveLogout = 'fe_false_positive_logout',
  SmartLockNotMatchedSkipped = 'Smart lock not matched - skipped',
  CalendarDateError = 'fe_calendar_date_error',
  ChannelSuccessfullyConnected = 'fe_channel_successfully_connected',
  BookingComPropertyIdAdded = 'fe_booking_com_property_id_added',
  DirectPayoutStripeOauthFailedToConnect = 'direct_payout_stripe_oauth_failed_to_connect',
  CalendarCICOSetBothError = 'fe_calendar_cico_set_both_error',
  CalendarCICOAvailability = 'fe_calendar_cico_availability',
  AgodaPropertyIdAdded = 'fe_agoda_property_id_added',
}

export interface AnalyticsEvent {
  type: EventType;
  name: SegmentEvent | string;
  parameters?: Record<string, unknown>;
  options?: unknown;
  meta?: unknown;
}
