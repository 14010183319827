import { Injectable, isDevMode } from '@angular/core';
import { OptimizelyFeatureFlag } from '@app/shared/interfaces';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import * as OptimizelyActions from './optimizely.actions';
import * as OptimizelySelectors from './optimizely.selectors';
import { config } from '@app/core/app-config';
import { of } from 'rxjs';
import { Logger } from '@app/shared/utils';

@Injectable()
export class OptimizelyFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  initLoadingState$ = this.store.pipe(select(OptimizelySelectors.getInitLoadingState));
  enabledFeaturesLoadingState$ = this.store.pipe(select(OptimizelySelectors.getEnabledFeaturesLoadingState));
  enabledFeatures$ = this.store.pipe(select(OptimizelySelectors.getEnabledFeatures));

  feature$ = (key: OptimizelyFeatureFlag) => this.store.select(OptimizelySelectors.getFeature(key));

  feature = <R>(key: OptimizelyFeatureFlag) => {
    let feature;
    this.store
      .select(OptimizelySelectors.getFeature(key))
      .pipe(take(1))
      .subscribe((f) => (feature = f));

    return feature as R;
  };

  isFeatureEnabled$ = (key: OptimizelyFeatureFlag) => {
    if (isDevMode() && config.OPTIMIZELY_FEATURE_FLAGS?.length) {
      Logger.log('Optimizely is using dev mode, set flags in your environment.ts file');
      return of(config.OPTIMIZELY_FEATURE_FLAGS.includes(key));
    }
    return this.store.select(OptimizelySelectors.isFeatureEnabled(key));
  };

  isFeatureEnabled = (key: OptimizelyFeatureFlag) => {
    let isEnabled;
    this.store
      .select(OptimizelySelectors.isFeatureEnabled(key))
      .pipe(take(1))
      .subscribe((enabled) => (isEnabled = enabled));

    return isEnabled;
  };

  constructor(private readonly store: Store) {}

  init(forceReload = false) {
    this.store.dispatch(OptimizelyActions.init({ forceReload }));
  }
}
