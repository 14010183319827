import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import { ConnectedApp } from '@app/shared/interfaces/lib/connected-app.interface';
import { IntercomService } from '@app/shared/services/intercom.service';
import { environment } from '@env/environment';
import * as isBefore from 'date-fns/is_before';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum AppPermission {
  calendarRead = 'View your calendar (pricing, availability and minimum night stay).',
  calendarPartialWrite = 'Update your calendar (pricing and minimum night stay).',
  calendarWrite = 'View and update your calendar (pricing, availability and minimum night stay).',
  propertyRead = 'View property details, including location, description and details such as capacity and amenities.',
  propertyListingRead = 'View property and listings details, including location, description and details such as capacity and amenities.',
  reservationRead = 'View and receive notification of reservation details, including guest information.',
  financialsRead = 'View reservation financials, including detailed price breakdown for the guest and host.',
  enrichmentRead = 'View reservation details, including guest information.',
  enrichmentSmartLockCode = 'Populates the %smartlock_code% short code for use in guest messages.',
  upsellShortcode = 'Populates the %upsell% short code for use in guest messages sent after reservations are confirmed.',
  enrichmentGuidebookCode = 'Populates the %guidebook% short code for use in guest messages sent after reservations are confirmed.',
  importGuidebook = 'Imports your existing guidebook content into the Knowledge Hub.',
}

export interface App {
  vendorCode?: string; // If we have this, we check the Access token status for this integration and show the appropriate UI
  connected?: boolean; // Tells us if this vendorCode is connected for this user
  title: string;
  description: string;
  link?: string;
  external_link?: string;
  image_url: string;
  enabled: boolean;
  badge_text?: string;
  categories?: string[];
  builtBy?: 'hospitable' | 'partner';
  actionFunction?: () => void;
}

export interface IntegrationVoteData {
  id: number;
  name: string;
  has_voted: boolean;
  votes: number;
  description: string;
  categories: string[];
  location_alert?: string;
  image?: string;
}

export enum LegacyApiKeyScope {
  ReadCalendar = 'read:calendar',
  WriteCalendar = 'write:calendar',
}

export interface LegacyApiKey {
  client_id: string;
  client_secret: string;
  name: string;
  scope: LegacyApiKeyScope[];
  just_created?: boolean;
  recently_copied?: boolean;
}

export interface ApiAccessToken {
  id: string;
  name: string;
  scopes: ApiAccessTokenScope[];
  secret?: string;
  expires_at: string;
  created_at: string;
  updated_at: string;
  last_used_at: null | string;
  recently_copied?: boolean;
}
export enum ApiAccessTokenScope {
  Read = 'read',
  Write = 'write',
}

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private mailchimpListsUrl = `${config.API_URL}/mailchimp/lists`;
  private mailchimpVerifyUrl = `${config.API_URL}/mailchimp`;
  private mailchimpListUrl = `${config.API_URL}/mailchimp/lists/selected`;
  private webhooksUrl = `${config.API_URL}/webhooks`;
  private emailRoutesUrl = `${config.API_URL}/mail-routes`;
  private integrationsUrl = `${config.API_URL}/integrations`;
  private autohostUrl = `${config.API_URL}/apps/autohost`;
  private apiKeysUrl = `${config.API_URL}/api-keys`;

  remotelockIntegration: App = {
    title: 'RemoteLock',
    description: 'Connect your August, Yale or RemoteLock smart locks to generate and send PIN codes to guests.',
    link: 'remotelock',
    image_url: 'integrations/remote-lock.svg',
    enabled: true,
    categories: ['Home automation'],
    builtBy: 'hospitable',
    vendorCode: 'remotelock',
  };

  private integrations = new BehaviorSubject<App[]>([
    {
      title: 'Mailchimp',
      description: 'Send guest details to a MailChimp list.',
      link: 'mailchimp',
      image_url: 'mailchimp.png',
      enabled: true,
      categories: ['Marketing'],
      builtBy: 'hospitable',
      vendorCode: 'mailchimp',
    },
    {
      title: 'PriceLabs',
      description: `PriceLabs is a robust revenue management tool that streamlines pricing for hosts and property managers. By analyzing data from platforms like Airbnb, VRBO, and Booking.com, it delivers precise price recommendations to optimize revenue and save time.`,
      image_url: 'pricelabs.svg',
      enabled: true,
      categories: ['Dynamic pricing'],
      builtBy: 'partner',
      vendorCode: 'pricelabs',
      link: 'pricelabs',
    },
    {
      title: 'Beyond',
      description: 'Connect your Beyond account to automate dynamic pricing.',
      image_url: 'beyond-badge.png',
      enabled: true,
      categories: ['Dynamic pricing'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(6929219);
      },
    },
    {
      title: 'Charge Automation',
      description: `Send booking details to Charge Automation's guest experience platform.`,
      image_url: 'charge-automation.png',
      enabled: true,
      categories: ['Guest portal'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8018518);
      },
    },

    {
      title: 'Wheelhouse',
      description: 'Connect your Wheelhouse account to automate dynamic pricing.',
      image_url: 'wheelhouse.svg',
      enabled: true,
      categories: ['Dynamic pricing'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(7892772);
      },
    },
    {
      title: 'Rategenie',
      description: 'Connect your Rategenie account to automate dynamic pricing.',
      image_url: 'rategenie.png',
      enabled: true,
      categories: ['Dynamic pricing'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8017299);
      },
    },
    {
      title: 'Autohost',
      description: `Connect your Autohost account to enable guest screening and background checks.`,
      link: 'autohost',
      image_url: 'integrations/autohost.png',
      enabled: true,
      categories: ['Guest verification'],
      builtBy: 'hospitable',
      vendorCode: 'autohost',
    },
    {
      title: 'Turno',
      description:
        'Automate cleaning operations - get Turno for free! Find local cleaners on Turno’s Marketplace of 25K+ pros worldwide.',
      link: '/turno',
      image_url: 'turno.jpeg',
      enabled: true,
      categories: ['Operations'],
      builtBy: 'partner',
    },
    {
      title: 'Cleanster',
      description: 'Send reservations to Cleanster.com to automatically schedule cleanings.',
      image_url: 'cleanster.png',
      enabled: true,
      categories: ['Operations'],
      builtBy: 'partner',
      vendorCode: 'cleanster',
      link: 'cleanster',
    },
    {
      title: 'The Host Co.',
      description:
        'Instantly offer hotel-level amenities in your rentals with a digital store from The Host Co - and make up to 30% more revenue per listing. Create stores from your Hospitable listings in just a few clicks.',
      image_url: 'hostco.png',
      enabled: true,
      categories: ['Amenities'],
      builtBy: 'partner',
      vendorCode: 'thehostco',
      link: 'thehostco',
    },
    {
      title: 'TechTape',
      description:
        "TechTape provides revenue management services to drive up your revenue performance! TechTape's team implements revenue strategies so you can focus on operations and growth!",
      image_url: 'techtape.png',
      enabled: true,
      categories: ['Professional services'],
      builtBy: 'partner',
      vendorCode: 'techtape',
      link: 'techtape',
    },
    {
      title: 'Hudson Creative Studio',
      description:
        'Hudson Creative Studio builds custom WordPress direct booking sites. Brand-driven design, AI-powered search, automated data sync, custom property collections, and more. Get a unique site that drives direct bookings and revenue for your business.',
      image_url: 'hudson-creative.png',
      enabled: true,
      categories: ['Direct bookings'],
      builtBy: 'partner',
      vendorCode: 'hudson-creative',
      link: 'hudson-creative',
    },
    {
      title: 'CraftedStays',
      description:
        'CraftedStays is a direct booking platform made to simplify direct bookings. Build a beautiful integrated site in 15-minutes, and choose from a library of short-term-rental optimized templates. Start winning in direct bookings today!',
      image_url: 'craftedstays.png',
      enabled: true,
      categories: ['Direct bookings'],
      builtBy: 'partner',
      vendorCode: 'craftedstays',
      link: 'craftedstays',
    },
    {
      title: 'Hostkit',
      description:
        'Automate all your legal and fiscal obligations in Portugal. Certified invoicing, immigration bulletins (SEF), city tax, INE and more.',
      image_url: 'hostkit.png',
      enabled: true,
      categories: ['Accounting'],
      builtBy: 'partner',
      vendorCode: 'hostkit',
      link: 'hostkit',
    },
    {
      title: 'Jervis Systems',
      description:
        'Jervis Systems is a guest access and smart home device automation platform that allows property owners and managers to automate their short-term rentals from anywhere in the world.',
      image_url: 'jervis.png',
      enabled: true,
      categories: ['Home automation'],
      builtBy: 'partner',
      vendorCode: 'jervis',
      link: 'jervis',
      badge_text: 'Beta',
    },
    {
      title: 'Ownercenter',
      description:
        'Simplify Owner Management - create owner invoices, statements, booking reports and collect payments.',
      image_url: 'ownercenter.png',
      enabled: true,
      categories: ['Owner tools'],
      builtBy: 'partner',
      vendorCode: 'ownercenter',
      link: 'ownercenter',
    },
    {
      title: 'Minut',
      description:
        'Integrate Minut with Hospitable to sync booking information to Minut and automatically notify guests when noise levels are high.',
      image_url: 'minut.png',
      enabled: true,
      categories: ['Monitoring'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(7206433);
      },
    },

    {
      title: 'Clearing',
      description: 'Send reservations to Clearing to automate your bookkeeping and simplify trust accounting.',
      image_url: 'clearing.svg',
      enabled: true,
      categories: ['Accounting'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8149696);
      },
    },

    {
      title: 'Rankbreeze',
      description: `Rankbreeze provides listing optimization, dynamic pricing & market insights. This integration pushes prices from Rankbreeze's Dynamic Pricing Engine to Hospitable.`,
      image_url: 'rankbreeze.jpg',
      enabled: true,
      categories: ['Dynamic pricing'],
      builtBy: 'partner',
      link: 'rankbreeze',
    },
    {
      title: 'DACK',
      description: `DACK is an all-in-one, mobile-first, guest experience platform.`,
      image_url: 'DACK.svg',
      enabled: true,
      categories: ['Guest portal'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8219211);
      },
    },

    {
      title: 'Superhog',
      description: `Integrate Know Your Guest by SUPERHOG to automatically send guest verification requests to guests who book your properties.`,
      image_url: 'superhog.svg',
      enabled: true,
      badge_text: 'Beta',
      categories: ['Guest verification'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8307907);
      },
    },
    {
      title: 'DPGO',
      description: `DPGO is an AI-driven dynamic pricing tool for hosts, vacation rental owners, and short-term rental managers.`,
      image_url: 'DPGO.png',
      enabled: true,
      badge_text: 'Beta',
      categories: ['Dynamic pricing'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8308461);
      },
    },
    {
      title: 'Alertify',
      description: `Integrate Alertify with Hospitable to monitor when noise, smoke and occupancy exceed thresholds.`,
      image_url: 'alertify.svg',
      enabled: true,
      badge_text: 'Beta',
      categories: ['Monitoring'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8336757);
      },
    },

    {
      title: 'StayFi',
      description: `Brand WiFi, collect guest data, and increase direct bookings - all while providing a better WiFi experience for vacation rental guests.`,
      image_url: 'stayfi.png',
      enabled: true,
      categories: ['Marketing'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8571696);
      },
    },

    {
      title: 'VRPlatform',
      description: `Connecting your Hospitable account to VRPlatform syncs property and reservation data to automate your bookkeeping and accounting.`,
      image_url: 'vrplatform.svg',
      enabled: true,
      badge_text: 'Beta',
      categories: ['Accounting'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8198900);
      },
    },
    {
      title: 'Buoy',
      description: `Integrate Hospitable with Buoy to automate your pricing strategy.`,
      image_url: 'buoy.png',
      enabled: true,
      badge_text: 'Beta',
      categories: ['Dynamic pricing'],
      builtBy: 'partner',
      actionFunction: () => {
        this.openIntercomArticle(8583827);
      },
    },
    {
      title: 'ResortCleaning',
      description: `Connect Hospitable to ResortCleaning to automatically schedule departure cleans based on your reservations.`,
      image_url: 'resortcleaning.png',
      enabled: true,
      badge_text: 'Beta',
      categories: ['Operations'],
      builtBy: 'partner',
      link: 'resortcleaning',
      vendorCode: 'resortcleaning',
    },
    {
      title: 'Breezeway',
      description:
        "Breezeway's property care and operations platform helps coordinate, communicate, and verify detailed work, and deliver the best service experience to clients.",
      image_url: 'breezeway-logo.png',
      enabled: true,
      categories: ['Operations'],
      builtBy: 'partner',
      vendorCode: 'breezeway',
      link: 'breezeway',
    },
    {
      title: 'SuiteOp',
      description:
        'SuiteOp streamlines short-term rental, vacation rental, and hotel operations with interactive guidebooks, branded guest portals, guest verification, task management, and smart device control—all in one platform.',
      image_url: 'suiteop-logo.png',
      enabled: true,
      categories: ['Operations', 'Guest portal', 'Guest verification'],
      builtBy: 'partner',
      vendorCode: 'suiteop',
      link: 'suiteop',
    },
    {
      title: 'Touch Stay',
      description:
        'Touch Stay makes it fast and easy to share essential info like check-in details, WiFi passwords, house rules, and local recommendations. No more last-minute scrambling – everything guests need is just a click away.',
      image_url: 'touchstay-logo.png',
      enabled: true,
      categories: ['Guidebook'],
      builtBy: 'partner',
      vendorCode: 'touchstay',
      link: 'touchstay',
    },
    {
      title: 'Building an integration?',
      description: 'Review our Public API, and apply to be an approved vendor to have your App on this page!',
      image_url: 'missing.svg',
      enabled: true,
      external_link: 'https://developer.hospitable.com/reference/getting-started-with-your-api',
    },
    {
      title: 'Missing an integration?',
      description: 'View the list of planned integrations and register your interest to receive updates.',
      image_url: 'missing.svg',
      enabled: true,
      external_link: 'https://feedback.hospitable.com/?b=655e2114c2ec19e8f0500151',
    },
  ]);
  integrations$ = this.integrations.asObservable();

  integrationsCategories$ = this.integrations$.pipe(
    map((integrations) => {
      const categories = new Set<string>();

      integrations.forEach((integration) => {
        if (integration.categories) {
          integration.categories.forEach((category) => {
            categories.add(category);
          });
        }
      });

      return Array.from(categories).sort();
    })
  );

  private tools = new BehaviorSubject<App[]>([
    {
      title: 'Connected apps',
      description: `View and manage your connected applications.`,
      link: 'connected-apps',
      image_url: 'connected-apps.png',
      enabled: true,
    },
    {
      title: 'API access',
      description: `Generate and manage API keys.`,
      link: 'api-access',
      image_url: 'API.png',
      enabled: true,
    },
    {
      title: 'Email routing',
      description: `Create email redirections to one or several email addresses.`,
      link: 'email-routing',
      image_url: 'email-routing.png',
      enabled: true,
    },
    {
      title: 'Webhooks',
      description: `Send reservation details from Hospitable.com to your server.`,
      link: 'webhooks',
      image_url: 'webhooks.png',
      enabled: true,
    },
  ]);
  public tools$: Observable<App[]>;

  constructor(
    private http: HttpClient,
    private permissionsService: UserPermissionsService,
    private authService: AuthenticationService,
    private intercomService: IntercomService
  ) {
    this.toolPermissionsCheck();

    this.isRemotelockEnabled();
  }

  isRemotelockEnabled() {
    // Remotelock is only advertised for users who signed up before 26th March 2024
    if (isBefore(this.authService.getUserDetails().created_at, '2024-03-26')) {
      this.integrations.next([...this.integrations.value, this.remotelockIntegration]);
    }
  }

  private toolPermissionsCheck() {
    this.tools$ = combineLatest([this.permissionsService.isPrimaryUser(), this.tools]).pipe(
      map(([isPrimaryUser, tools]) => {
        if (isPrimaryUser) {
          return tools;
        } else {
          return tools.filter((tool) => tool.link !== 'api-access');
        }
      })
    );
  }

  getAutohostEnabled(uid: number): Observable<any> {
    return this.http.get(this.autohostUrl + '?uid=' + uid).pipe(
      map((res) => {
        return res;
      })
    );
  }

  setAutohostEnabled(uid: number, enabled: boolean) {
    return this.http.post(this.autohostUrl, { uid: uid, status: enabled }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  removeAutohostApiKey(uid: number) {
    return this.http.delete(this.autohostUrl + '?uid=' + uid).pipe(
      map((res) => {
        return res;
      })
    );
  }

  setAutohostApiKey(uid: number, apiKey: string) {
    return this.http
      .post(this.autohostUrl, {
        uid: uid,
        api_key: apiKey,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getMailchimpLists() {
    return this.http.get(this.mailchimpListsUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        return of({
          connected: false,
        });
      })
    );
  }

  createMailchimpIntegration(confirmationCode: string) {
    return this.http.post(this.mailchimpVerifyUrl, { code: confirmationCode }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        return of({
          error: true,
        });
      })
    );
  }

  disconnectMailchimpIntegration() {
    return this.http.delete(this.mailchimpVerifyUrl).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateMailchimpSelectedList(listId: string) {
    return this.http.post(this.mailchimpListUrl, { list_id: listId }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAPIkeys(): Observable<LegacyApiKey[]> {
    return this.http.get(this.apiKeysUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  generateAPIkeys(): Observable<LegacyApiKey | { error: boolean; message: string }> {
    return this.http
      .post(this.apiKeysUrl, {
        name: 'new-api-key',
        scope: ['read:calendar', 'write:calendar'],
      })
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          return of({
            error: true,
            message: error.error.message,
          });
        })
      );
  }

  updateAPIkey(id: string, name: string, scope: any[]) {
    return this.http.put(`${this.apiKeysUrl}/${id}`, { name, scope }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteAPIkey(identifier: string) {
    return this.http.delete(`${this.apiKeysUrl}/${identifier}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAPIAccessTokens(): Observable<ApiAccessToken[]> {
    return this.http.get(`${environment.apiUrl}/api-access/pat`).pipe(
      map((res: { data: ApiAccessToken[] }) => {
        return res.data.map((token) => {
          return this.transformApiAccessTokenScopes(token);
        });
      })
    );
  }

  getAPIAccessToken(id: string, password): Observable<ApiAccessToken> {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');

    return this.http.get(`${environment.apiUrl}/api-access/pat/${id}?password=${password}`, { headers }).pipe(
      map((res: { data: ApiAccessToken }) => {
        return this.transformApiAccessTokenScopes(res.data);
      }),
      catchError((error) => {
        if (error.status === 401) {
          return of(null);
        } else {
          return throwError(error);
        }
      })
    );
  }

  createAPIAccessToken(payload: Pick<ApiAccessToken, 'name' | 'scopes'>): Observable<ApiAccessToken> {
    return this.http.post(`${environment.apiUrl}/api-access/pat`, payload).pipe(
      map((res: { data: ApiAccessToken }) => {
        return this.transformApiAccessTokenScopes(res.data);
      })
    );
  }

  updateAPIAccessToken(id: string, token: ApiAccessToken): Observable<ApiAccessToken> {
    return this.http
      .put<ApiAccessToken>(`${environment.apiUrl}/api-access/pat/${id}`, {
        name: token.name,
        scopes: token.scopes,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  deleteAPIAccessToken(id: string, password: string): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/api-access/pat/${id}?password=${password}`).pipe(
      map((res: any) => {
        return true;
      })
    );
  }

  transformApiAccessTokenScopes(token: ApiAccessToken): ApiAccessToken {
    const uniqueScopes = new Set<ApiAccessTokenScope>();

    token.scopes.forEach((scope) => {
      if (scope.includes(':read')) {
        uniqueScopes.add(ApiAccessTokenScope.Read);
      } else if (scope.includes(':write')) {
        uniqueScopes.add(ApiAccessTokenScope.Write);
      } else {
        uniqueScopes.add(scope);
      }
    });

    return { ...token, scopes: Array.from(uniqueScopes) };
  }

  getEmailRoutes() {
    return this.http.get(this.emailRoutesUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateEmailRoute(id: string, payload: any) {
    return this.http.put(`${this.emailRoutesUrl}/${id}`, payload).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  createEmailRoute(payload: any) {
    return this.http.post(`${this.emailRoutesUrl}`, payload).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  deleteEmailRoute(identifier: string) {
    return this.http.delete(`${this.emailRoutesUrl}/${identifier}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getWebhooks() {
    return this.http.get(this.webhooksUrl).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  addWebhook(webhookUrl: string, webhookName: null | string = null) {
    return this.http.post(this.webhooksUrl, { url: webhookUrl, name: webhookName }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateWebhookUrl(webhookId: string, webhookUrl: string) {
    return this.http.put(`${this.webhooksUrl}/${webhookId}`, { url: webhookUrl }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateWebhookName(webhookId: string, webhookName: string) {
    return this.http.put(`${this.webhooksUrl}/${webhookId}`, { name: webhookName }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  deleteWebhook(webhookId: string) {
    return this.http.delete(`${this.webhooksUrl}/${webhookId}`).pipe(
      map((res: any) => {
        return res.success;
      })
    );
  }

  testWebhook(webhookId: string) {
    return this.http.put(`${this.webhooksUrl}/${webhookId}/test`, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  sendAllWebhooks() {
    return this.http.put(`${this.webhooksUrl}/send-previous`, {}).pipe(
      map((res: any) => {
        return res.success;
      })
    );
  }

  sendWebhooks(webhookId: string) {
    return this.http.put(`${this.webhooksUrl}/send-previous/${webhookId}`, {}).pipe(
      map((res: any) => {
        return res.success;
      })
    );
  }

  getIntegrations() {
    return this.http.get(this.integrationsUrl).pipe(
      map((res: any) => ({
        integrations: res.data as IntegrationVoteData[],
        meta: res.meta as any,
      }))
    );
  }

  upvoteIntegration(id: number, comment?: string) {
    return this.http
      .post(this.integrationsUrl + '/' + id + '/vote', {
        feedback: comment,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError)
      );
  }

  getIntegrationConnectionStatus(vendorCode: string): Observable<null | ConnectedApp> {
    return this.http.get<{ connected: boolean }>(`${this.integrationsUrl}/${vendorCode}`).pipe(
      map((res: any) => res.data),
      catchError(() => of(null))
    );
  }

  generateIntegrationConnectionUrl(vendorCode: string): Observable<string> {
    return this.http.post<{ redirect_uri: string }>(`${this.integrationsUrl}/${vendorCode}`, {}).pipe(
      map((res) => {
        return res.redirect_uri;
      })
    );
  }

  private openIntercomArticle(articleId: number) {
    this.intercomService.openHelpArticle(articleId);
  }

  private handleError(err) {
    return of({
      error: true,
      message: err.error.message,
      errors: err.error.errors,
    });
  }

  getConnectedApps(): Observable<ConnectedApp[]> {
    return this.http.get<ConnectedApp[]>(this.integrationsUrl).pipe(map((res: any) => res.data));
  }

  deleteIntegration(app: ConnectedApp): Observable<boolean> {
    return this.http.delete<void>(`${this.integrationsUrl}/${app.code}`).pipe(
      map((res: any) => {
        return res.success;
      })
    );
  }
}
