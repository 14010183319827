import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPropertiesReducer from './properties.reducer';
import { PropertiesEffects } from './properties.effects';
import { PropertiesService } from './properties.service';
import { PropertiesFacade } from './properties.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPropertiesReducer.PROPERTIES_FEATURE_KEY, fromPropertiesReducer.propertiesReducer),
    EffectsModule.forFeature([PropertiesEffects]),
  ],
  providers: [PropertiesFacade, PropertiesService],
})
export class PropertiesStateModule {}
