import { combineLoadingStates, ListingReview, LoadingState, UpdatePropertyOption } from '@app/shared/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UpdateListingReviewForm, UpdateListingReviewsFormEntity } from './listings.models';
import { LISTINGS_FEATURE_KEY, ListingsState } from './listings.reducer';

// Lookup the 'Listings' feature state managed by NgRx
export const getListingsState = createFeatureSelector<ListingsState>(LISTINGS_FEATURE_KEY);
export const getListingReviewsLoadingState = createSelector(
  getListingsState,
  (state: ListingsState) => state.listingReviews.loadingState
);

export const getListingReviewsListingEntities = createSelector(getListingsState, (state: ListingsState) =>
  state.listingReviews.data && state.listingReviews.data.listings ? state.listingReviews.data.listings : null
);

export const getListingReviews = createSelector(getListingsState, (state: ListingsState) => {
  const listingsData = state.listingReviews.data;

  const listings =
    listingsData && listingsData.listings
      ? Object.keys(listingsData.listings).map((id) => listingsData.listings[id])
      : [];

  if (listings && listings.length > 0) {
    return {
      listings,
      meta: listingsData.meta,
      types: listingsData.types,
    };
  } else {
    return null;
  }
});

export const getListingReviewsNeedingPrimaryHost = createSelector(
  getListingReviews,
  (listingsReviews: ListingReview) => {
    const { listings } = listingsReviews;
    if (listings && listings.length > 0) {
      return listings.filter((x) => x.needs_primary_host_connection);
    }

    return [];
  }
);

export const getUpdateListingReviewsFormEntity = createSelector(
  getListingsState,
  (state: ListingsState) => state.listingReviews.updateListings.form
);

export const getUpdateListingReviewsFormAnalytics = createSelector(
  getListingsState,
  (state: ListingsState) => state.listingReviews.updateListings.analytics
);

export const getListingReviewsFormEntitiesByAction = (action: UpdatePropertyOption) =>
  createSelector(getListingsState, (state: ListingsState) => {
    const listingsWithAction: UpdateListingReviewsFormEntity = {} as UpdateListingReviewsFormEntity;
    const form = state.listingReviews.updateListings.form;

    Object.keys(form).map((id) => {
      if (
        form[id]['action'] &&
        form[id]['action'].includes(UpdatePropertyOption.MergeProperty) &&
        action === UpdatePropertyOption.MergeProperty
      ) {
        listingsWithAction[id] = {
          propertyId: id,
          listingId: form[id].listingId,
          action: form[id].action,
          loadingState: form[id].loadingState,
        };
      } else {
        form[id]['action'] === action
          ? (listingsWithAction[id] = {
              propertyId: id,
              listingId: form[id].listingId,
              action: form[id].action,
              loadingState: form[id].loadingState,
            })
          : null;
      }
    });
    return listingsWithAction;
  });

export const getListingReviewsFormByAction = (action: UpdatePropertyOption) =>
  createSelector(getListingsState, (state: ListingsState) => {
    const listingsWithAction: UpdateListingReviewForm[] = [];
    const form = state.listingReviews.updateListings.form;

    Object.keys(form).map((id) => {
      if (
        form[id]['action'] &&
        form[id]['action'].includes(UpdatePropertyOption.MergeProperty) &&
        action === UpdatePropertyOption.MergeProperty
      ) {
        listingsWithAction.push({
          propertyId: id,
          listingId: form[id].listingId,
          action: form[id].action,
          loadingState: form[id].loadingState,
        });
      } else {
        form[id]['action'] === action
          ? listingsWithAction.push({
              propertyId: id,
              listingId: form[id].listingId,
              action: form[id].action,
              loadingState: form[id].loadingState,
            })
          : null;
      }

      return id;
    });
    return listingsWithAction;
  });

export const getListingReviewsFormLoadingStateCountByAction = (action: UpdatePropertyOption): any =>
  createSelector(getListingsState, (state: ListingsState) => {
    const form = state.listingReviews.updateListings.form;

    if (form) {
      const actionLoadingStateCount = {
        [LoadingState.Success]: 0,
        [LoadingState.Error]: 0,
        [LoadingState.NotSent]: 0,
        [LoadingState.Pending]: 0,
      };

      Object.keys(form).map((id) => {
        if (
          form[id]['action'] &&
          form[id]['action'].includes(UpdatePropertyOption.MergeProperty) &&
          action === UpdatePropertyOption.MergeProperty
        ) {
          actionLoadingStateCount[form[id].loadingState]++;
        } else if (form[id]['action'] === action) {
          actionLoadingStateCount[form[id].loadingState]++;
        }
      });
      return actionLoadingStateCount;
    } else {
      return null;
    }
  });

export const getUpdateListingReviewsCombinedLoadingStates = createSelector(getListingsState, (state: ListingsState) => {
  const loadingState = combineLoadingStates(Object.values(state.listingReviews.updateListings.loadingStates));
  return loadingState;
});

export const listingReviewsHasCohosted = createSelector(getListingReviews, (listingsReviews: ListingReview) => {
  const { listings } = listingsReviews;
  if (listings && listings.length > 0 && listings.filter((x) => x.needs_primary_host_connection).length > 0) {
    return true;
  }

  return false;
});
