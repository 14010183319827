import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sbnb-badge',
  template: `
    <span
      class="badge  capitalize !ml-0 "
      [ngClass]="{
        'badge-grass': context === 'positive' && volume === 'high',
        'badge-lemon': context === 'warning' && volume === 'high',
        'badge-danger': context === 'negative' && volume === 'high',
        'badge-grape': context === 'neutral' && volume === 'high',
        'badge-grass-clear': context === 'positive' && volume === 'low',
        'badge-lemon-clear': context === 'warning' && volume === 'low'
      }">
      <ng-content></ng-content>
    </span>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnInit {
  @Input() context: 'exuberant' | 'positive' | 'neutral' | 'negative' | 'warning' = 'exuberant';
  @Input() volume: 'low' | 'high' = 'high';

  @HostBinding('class.contents') displayContents = true;

  constructor() {}

  ngOnInit(): void {}
}
