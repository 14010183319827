import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyMergeCandidate } from '@app/shared/interfaces';
import { FilterService } from '@app/shared/services/filter/filter.service';
import { Observable, of } from 'rxjs';
import { RequestOverride } from '../shared/request-override.interface';
import { PropertiesResponse } from './properties.models';

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  private resultsPerPage = 20;

  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient,
    private filterService: FilterService,
    private router: Router
  ) {}

  loadProperties(
    paginate = true,
    filterCriteria?: any[],
    offset?: number,
    search?: string,
    limit?: number,
    transformer?: string, // simple
    idList?: any[]
  ): Observable<PropertiesResponse> {
    const propertiesUrl = `${this.apiUrl}/properties`;
    let url;

    if (paginate) {
      url = `${propertiesUrl}?limit=${limit ? limit : this.resultsPerPage}`;
    } else {
      url = `${propertiesUrl}?pagination=false`;
    }

    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    if (offset) {
      url = `${url}&offset=${offset}`;
    }

    if (search) {
      url = `${url}&query=${search}`;
    }

    if (transformer) {
      url = `${url}&transformer=${transformer}`;
    }

    if (idList) {
      url = `${url}&ids=${idList.join()}`;
    }

    return this.http.get<PropertiesResponse>(url);
  }

  getPropertyMergeCandidate(propertyId: string): Observable<PropertyMergeCandidate> {
    // I really hate this, but trying to pass them all the way through ngrx felt like a labyrinth
    // const urlSegments = this.router.url.split('/');
    // const platform = urlSegments[urlSegments.length - 2] || null;
    // const platform_id = urlSegments[urlSegments.length - 1] || null;

    // return this.http.get<PropertyMergeCandidate>(
    //   `${this.apiUrl}/properties/${propertyId}/merge/match?platform=${platform}&platform_id=${platform_id}`
    // );

    return of({ merge_candidate: null });
  }

  mergeProperty(propertyId: string, mergeWithPropertyId: string): Observable<unknown> {
    return this.http.post<PropertyMergeCandidate>(`${this.apiUrl}/properties/${propertyId}/merge/validate`, {
      property_id: mergeWithPropertyId,
    });
  }

  muteProperty(propertyId: string): Observable<unknown> {
    return this.http.put<PropertyMergeCandidate>(`${this.apiUrl}/properties/${propertyId}/mute`, {});
  }

  unmuteProperty(propertyId: string): Observable<unknown> {
    return this.http.put<PropertyMergeCandidate>(`${this.apiUrl}/properties/${propertyId}/unmute`, {});
  }

  // TODO - Update bulk URL when used outside of signup process
  bulkMuteAndUnMuteProperties(mute: string[], unmute: string[], requestOverride: RequestOverride): Observable<unknown> {
    const url = requestOverride && requestOverride.url ? requestOverride.url : `${this.apiUrl}/properties/mute/bulk`;
    const headers = requestOverride && requestOverride.headers ? requestOverride.headers : {};

    return this.http.put(
      url,
      {
        mute,
        unmute,
      },
      headers
    );
  }
}
