import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { NotificationType, NotificationVerticalPosition } from '@app/shared/interfaces';
import { countWords } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationsService {
  constructor(private snackbar: MatSnackBar) {}

  open(
    message: string,
    action = 'Dismiss',
    type: NotificationType,
    durationOverride?: number
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar.open(message, action, {
      duration: durationOverride ? durationOverride : this.getToastDuration(message),
      verticalPosition: NotificationVerticalPosition.Top,
      panelClass: [type],
    });
  }

  // Gets toast duration based on the amount of words in the message
  getToastDuration(message: string) {
    const minDuration = 8000;
    const msPerWord = 750;
    const wordCount = countWords(message);
    const duration = wordCount ? wordCount * msPerWord : minDuration;
    return duration > minDuration ? duration : minDuration;
  }
}
