<!-- FEATURE FLAGGED: Smart codes -->
<ng-container *ngIf="canSeeSmartCodes$ | async">
  <ng-container *ngIf="upsellWarning$ | async as warning">
    <sbnb-alert-card context="warning" *ngIf="warning.length" class="mb-5 mt-5">
      <div class="flex flex-col gap-3">
        <strong>
          Use the Upsell smart code to offer guests early check-in or late check-out whenever relevant using AI.
        </strong>
        <div>These custom codes might be in conflict with the Upsell smart code:</div>
        <div class="ml-4">
          <ul class="list !gap-3">
            <li *ngFor="let code of warning">
              <span class="customcode">%%{{ code.key }}%%</span>
              is populated when
              {{
                code.condition.key === 'night_after_check_out_available'
                  ? 'the night after check-out'
                  : 'the night before check-out'
              }}
              is
              {{ code.condition.value === true ? 'available' : 'not available' }}
            </li>
          </ul>
        </div>
      </div>
    </sbnb-alert-card>
  </ng-container>
  <ng-container *ngIf="smartCodes$ | async as smartCodes">
    <div class="flex flex-col gap-3" *ngIf="smartCodes.length">
      <mat-divider></mat-divider>
      <div class="flex gap-3 items-center leading-8">
        <strong>Add smart codes</strong>
        <button
          *ngFor="let code of smartCodes"
          [matTooltip]="code.description"
          (click)="smartCodeSelected.emit(code.key)"
          class="button__smartcode flex gap-2 items-center">
          <span>
            {{ code.label }}
          </span>
          <img src="/assets/iconography/magic.svg" onload="SVGInject(this)" alt="Use smart code" />
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
