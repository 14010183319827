import { ApiErrorResponse, ApiResponse } from '@app/shared/interfaces';
import { createAction, props } from '@ngrx/store';
import { GeneratedReply } from './AI.models';

export const generateReply = createAction(
  '[AI] Generate Reply',
  props<{ threadUuid: string; hostUid: string | null }>()
);
export const generateReplySuccess = createAction(
  '[AI] Generate Reply Success',
  props<{ response: ApiResponse<GeneratedReply> }>()
);
export const generateReplyFailure = createAction(
  '[AI] Generate Reply Failure',
  props<{ response: ApiErrorResponse }>()
);
export const resetGeneratedReply = createAction('[AI] Reset Generated Reply');

export const generateImprovement = createAction(
  '[AI] Generate Improvement',
  props<{ threadUuid: string; existingMessage: string | null }>()
);
export const generateImprovementSuccess = createAction(
  '[AI] Generate Improvement Success',
  props<{ response: ApiResponse<GeneratedReply> }>()
);
export const generateImprovementFailure = createAction(
  '[AI] Generate Improvement Failure',
  props<{ response: ApiErrorResponse }>()
);

export const resetGeneratedImprovement = createAction('[AI] Reset Generated Improvement');
