export function ConvertEnumToArray<E>(enumObject: any): E {
  const array = [];

  for (const [propertyKey, propertyValue] of Object.entries(enumObject)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }

    if (propertyValue === '') {
      continue;
    }
    array.push(propertyValue);
  }

  return array as unknown as E;
}
