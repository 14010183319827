import { OptimizelyFeatureFlag } from '@app/shared/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OPTIMIZELY_FEATURE_KEY, OptimizelyState } from './optimizely.reducer';

// Lookup the 'Optimizely' feature state managed by NgRx
export const getOptimizelyState = createFeatureSelector<OptimizelyState>(OPTIMIZELY_FEATURE_KEY);
export const getInitLoadingState = createSelector(
  getOptimizelyState,
  (state: OptimizelyState) => state.initLoadingState
);

export const getEnabledFeaturesState = createSelector(
  getOptimizelyState,
  (state: OptimizelyState) => state.enabledFeatures
);

export const getEnabledFeaturesLoadingState = createSelector(
  getOptimizelyState,
  (state: OptimizelyState) => state.enabledFeatures.loadingState
);

export const getEnabledFeatures = createSelector(
  getOptimizelyState,
  (state: OptimizelyState) => state.enabledFeatures.data
);

export const getFeature = (key: OptimizelyFeatureFlag) =>
  createSelector(getOptimizelyState, (state: OptimizelyState) => {
    return state &&
      state.enabledFeatures &&
      state.enabledFeatures.data &&
      state.enabledFeatures.data.features &&
      state.enabledFeatures.data.features[key]
      ? state.enabledFeatures.data.features[key]
      : null;
  });

export const isFeatureEnabled = (key: OptimizelyFeatureFlag) =>
  createSelector(getOptimizelyState, (state: OptimizelyState) => {
    return state &&
      state.enabledFeatures &&
      state.enabledFeatures.data &&
      state.enabledFeatures.data.features &&
      state.enabledFeatures.data.features[key]
      ? true
      : false;
  });
