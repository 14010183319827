import { Pipe, PipeTransform } from '@angular/core';
import { OnboardingItem } from '@app/modules/onboarding/onboarding.service';

@Pipe({
  name: 'incompleteParentCount',
  pure: false,
})
export class IncompleteParentCountPipe implements PipeTransform {
  transform(onboardingItems: OnboardingItem[]): number {
    const total = onboardingItems.length;
    const incomplete = onboardingItems.filter((x) => x.completed).length;

    return total - incomplete;
  }
}
