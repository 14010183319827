export interface DirectSubscription {
  onboarded: boolean;
  trial: boolean | null;
  plan: DirectPlan | null;
  pending_cancellation_date: string | null;
  next_charge_date: string; // 2023-03-30T14:48:50+00:00
  active_direct_properties: number;
  charge_amount: string; // $0.00
}

export enum DirectPlan {
  Premium = 'direct-premium',
  Basic = 'direct-basic',
  Lite = 'direct-lite',
}

export interface DirectUiPlan {
  key: DirectPlan;
  name: string;
  subtitle: string | null;
  perPropertyPrice: string; // String localised to the users currency, e.g. '$599' or '£499'
  guestServiceFee: string;
  headline: string;
  description: string;
  recommended: boolean;
  features: string[];
}
