<div class="close clickable flex items-center justify-center" [class.close-btn-border]="border">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" class="x-button" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.75 16.25L16.25 3.75"
      [attr.stroke]="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M3.75 3.75L16.25 16.25"
      [attr.stroke]="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</div>
