import { ApiErrorResponse, ApiResponse, ApiValidationError, SimpleApiValidationError } from '@app/shared/interfaces';
import { createAction, props } from '@ngrx/store';
import { PlanId, StashedPaymentMethod, Subscription, SubscriptionFeature } from './billing.models';

export const loadSubscription = createAction('[Billing] Load Subscription');
export const loadSubscriptionSuccess = createAction(
  '[Billing] Load Subscription Success',
  props<{ response: Subscription }>()
);
export const loadSubscriptionFailure = createAction(
  '[Billing] Load Subscription Failure',
  props<{ response: ApiErrorResponse | ApiValidationError }>()
);

// Load Plans from API
export const loadPlans = createAction('[Billing] Load Plans');
export const loadPlansSuccess = createAction('[Billing] Load Plans Success', props<{ response: any }>());
export const loadPlansFailure = createAction('[Billing] Load Plans Failure', props<{ response: ApiErrorResponse }>());

// Manage subscription state
export const initiateSubscriptionChange = createAction(
  '[Billing] Initiate Subscription Change',
  props<{ planId: PlanId; downgrade: boolean; redirectUrl: string }>()
);
export const abandonSubscriptionChange = createAction('[Billing] Abandon Subscription Change');
export const loadDowngradeIssues = createAction('[Billing] Load Downgrade Issues');
export const loadDowngradeIssuesSuccess = createAction(
  '[Billing] Load Downgrade Issues Success',
  props<{ response: any }>()
);
export const loadDowngradeIssuesFailure = createAction(
  '[Billing] Load Downgrade Issues Failure',
  props<{ response: ApiErrorResponse }>()
);

// Change subscription
export const changeSubscription = createAction(
  '[Billing] Change Subscription',
  props<{
    planId: PlanId;
    activeListings?: number;
    stashedPaymentMethod?: StashedPaymentMethod;
    isSubscribing?: boolean;
  }>()
);
export const changeSubscriptionSuccess = createAction(
  '[Billing] Change Subscription Success',
  props<{ response: Record<string, unknown> }>()
);
export const changeSubscriptionFailure = createAction(
  '[Billing] Change Subscription Failure',
  props<{ response: SimpleApiValidationError }>()
);

// Device addon state
export const loadDeviceAddon = createAction('[Billing] Load Device Addon');
export const loadDeviceAddonSuccess = createAction('[Billing] Load Device Addon Success', props<{ response: any }>());
export const loadDeviceAddonFailure = createAction(
  '[Billing] Load Device Addon Failure',
  props<{ response: ApiErrorResponse }>()
);
export const confirmDeviceAddon = createAction('[Billing] Confirm Device Addon');
export const confirmDeviceAddonSuccess = createAction(
  '[Billing] Confirm Device Addon Success',
  props<{ response: any }>()
);
export const confirmDeviceAddonFailure = createAction(
  '[Billing] Confirm Device Addon Failure',
  props<{ response: ApiErrorResponse }>()
);

// Cancel subscription
export const cancelSubscription = createAction(
  '[Billing] Cancel Subscription',
  props<{ reason: string; textReason: string }>()
);
export const cancelSubscriptionSuccess = createAction(
  '[Billing] Cancel Subscription Success',
  props<{ response: any }>()
);
export const cancelSubscriptionFailure = createAction(
  '[Billing] Cancel Subscription Failure',
  props<{ response: ApiErrorResponse }>()
);
