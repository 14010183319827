import { LocalStorageKey } from '@app/shared/interfaces';
import { getBoolean, isArray } from '@app/shared/utils';
import { Target } from 'redux-beacon';

export const Segment = (): Target => (events) => {
  if (getBoolean(localStorage.getItem(LocalStorageKey.analyticsEnabled))) {
    if (events && isArray(events)) {
      if ((<any>window).analytics) {
        events.forEach((event) => {
          (<any>window).analytics.track(event.name, event.parameters);
        });
      } else {
        console.warn('Segment analytics not on window object');
      }
    }
  } else {
    return;
  }
};
