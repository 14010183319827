import { Reservation } from '@app/shared/interfaces';

type CalendarReservation = Reservation & { isParentChildReservation?: boolean };

export const mapConflictingEvents = (reservations: CalendarReservation[]) => {
  const conflictingEventsDateMap: { [key: string]: any[] } = {};

  // This could be susceptible to ordering issues, is it worth it to cut down the
  // size of the subsequent loops we have to do?
  const conflictingEvents = reservations.filter((event, i, arr) => {
    const nextEvent = arr[i + 1];
    const prevEvent = arr[i - 1];

    // filter out duplicates by the reservation code
    if (event.code === prevEvent?.code || event.code === nextEvent?.code) return false;

    // return true if it conflicts with the previous or next event
    // filtering out sibling parent-child reservations
    return (
      (!isSiblingProperty(event, prevEvent) && hasConflict(event, prevEvent)) ||
      (!isSiblingProperty(event, nextEvent) && hasConflict(event, nextEvent))
    );
  });

  // For each event, find all the reservations that overlap
  conflictingEvents.forEach((event) => {
    const uuid = event.uuid;
    const currentEvent = reservations.find((event) => event.uuid === uuid);
    const conflicts = conflictingEvents.filter((event) => {
      return hasConflict(currentEvent, event);
    });

    conflictingEventsDateMap[uuid] = conflicts;
  });

  return conflictingEventsDateMap;
};

export const hasConflict = (event: CalendarReservation, otherEvent: CalendarReservation) => {
  if (!otherEvent) return false;
  return (
    new Date(event.checkin) < new Date(otherEvent.checkout) && new Date(event.checkout) > new Date(otherEvent.checkin)
  );
};

export const isFullyOverlapping = (event: CalendarReservation, otherEvent: CalendarReservation) => {
  return (
    new Date(event.checkin) >= new Date(otherEvent.checkin) && new Date(event.checkout) <= new Date(otherEvent.checkout)
  );
};

const isSiblingProperty = (event: CalendarReservation, otherEvent: CalendarReservation): boolean => {
  return event?.listing?.property_id !== otherEvent?.listing?.property_id && isChild(event) && isChild(otherEvent);
};

const isChild = (event: CalendarReservation): boolean => {
  return event?.parent_child_properties?.some(
    (property) => property.property_id === event?.listing?.property_id && property.type === 'child'
  );
};
