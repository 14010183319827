import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { sbnbIcon } from '@app/ui/icons/sbnb-icons';

export enum NoItemsSize {
  Normal = 'normal',
  Mini = 'mini',
}

@Component({
  selector: 'sbnb-no-items',
  templateUrl: './no-items.component.html',
  styleUrls: ['./no-items.component.scss'],
})
export class NoItemsComponent implements OnInit {
  NoItemsSize = NoItemsSize;

  @Input() size: NoItemsSize = NoItemsSize.Normal; // The size of the component
  @Input() icon: sbnbIcon; // The icon to display above the text, if any
  @Input() text: string;
  @Input() subtext: string;
  @Input() buttons: {
    id: string;
    label: string;
    size?: 'normal' | 'mini';
    type?: 'primary' | 'primary-teal' | 'secondary' | 'text' | 'text-secondary';
  }[];

  @Output() button: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  buttonClicked(buttonId: string) {
    this.button.emit(buttonId);
  }
}
