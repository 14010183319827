<mat-spinner diameter="16" *ngIf="!rules && !simpleMode"></mat-spinner>

<div id="rule__editor" *ngIf="rules || simpleMode">
  <mat-tab-group *ngIf="rules" animationDuration="0ms" #tabGroup (selectedTabChange)="changeActiveTab($event)">
    <mat-tab *ngFor="let rule of rules">
      <div
        *ngIf="emailSubject && options"
        #subjectEditor
        class="email-subject__input strong"
        placeholder="Email subject..."
        [ngxTribute]="options"
        contenteditable="true"
        [innerHTML]="rule.metadata?.subject ? (rule.metadata.subject | safe: 'html') : ''"
        (keyup)="updateEmailSubject(rule, $event.target.textContent)"
        (onMentioned)="updateEmailSubject(rule, subjectEditor.innerText)"></div>

      <ng-template mat-tab-label>
        <span fxLayoutGap="8px" fxLayoutAlign="start center">
          <img
            *ngIf="rule.warnings && rule.warnings.length > 0"
            class="warning-icon"
            src="assets/iconography/warning.svg"
            alt="Warning" />
          <span class="cera-heading" fxFlex>{{ rule.language.label }}</span>
          <img
            *ngIf="rule.language.key !== 'en'"
            src="/assets/iconography/cancel-smoke.svg"
            (click)="$event.stopPropagation(); deleteRulePrompt(rule)" />
        </span>
      </ng-template>

      <div
        *ngIf="options"
        #messageEditor
        contenteditable="true"
        class="rule__textarea-editor"
        [ngxTribute]="options"
        [attr.placeholder]="rule.translating ? 'Translating...' : editorPlaceholder"
        (keyup)="onKeyUp(rule, messageEditor.innerText)"
        (paste)="stripStyles($event, rule); onKeyUp(rule, messageEditor.innerText)"
        (onMentioned)="onKeyUp(rule, messageEditor.innerText)"
        [innerHTML]="rule.template | safe: 'html'"></div>

      <sbnb-editor-smart-codes (smartCodeSelected)="insertSmartCode($event)"></sbnb-editor-smart-codes>
      <sbnb-cs-debug-item label="rule_id" [value]="rule.id" [borderTop]="true"></sbnb-cs-debug-item>

      <div *ngIf="rule.warnings?.length > 0" class="rule-warnings-container">
        <div
          *ngFor="let warning of rule.warnings"
          class="rule-warning"
          fxLayoutAlign="space-between center"
          fxLayoutGap="6px">
          <div fxLayoutAlign="start start" fxLayoutGap="6px">
            <img src="assets/iconography/warning.svg" alt="Warning icon" />
            <div fxLayout="column" fxLayoutGap="4px">
              <span>{{ warning.message }}</span>
              <ul *ngIf="warning.details?.length > 0" class="warning-list">
                <li *ngFor="let detail of warning.details" class="text__small">
                  {{ detail }}
                </li>
              </ul>
            </div>
          </div>

          <a *ngIf="warning.more_info_url" [href]="warning.more_info_url" target="_blank" class="lemon-darker strong">
            Learn more
          </a>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="rules?.length === 0" class="rule__textarea-editor-standalone night-lighter">
    Well this is embarassing - we're having trouble loading your template 🤷‍
  </div>

  <ng-container *ngIf="{ value: simpleInputHtml$ | async } as simpleInputHtml">
    <div
      *ngIf="simpleMode && options"
      #simpleEditor
      contenteditable="true"
      class="rule__textarea-editor rule__textarea-editor-standalone rule__textarea-editor-fixed"
      [ngxTribute]="options"
      [style.height.px]="fixedHeight ? fixedHeight : null"
      [attr.placeholder]="editorPlaceholder"
      (keyup)="onKeyUp('', simpleEditor.innerText)"
      (paste)="stripStyles($event, null); onKeyUp('', simpleEditor.innerText)"
      (onMentioned)="onKeyUp('', simpleEditor.innerText)"
      [innerHTML]="simpleInputHtml.value | safe: 'html'"></div>
  </ng-container>
</div>

<div
  class="rule__buttons"
  *ngIf="(rules && !simpleMode) || (simpleMode && importEnabled)"
  fxLayout
  fxLayoutAlign="start center">
  <div fxFlex>
    <button
      *ngIf="importEnabled"
      class="button__text ocean button__small"
      mat-flat-button
      [matMenuTriggerFor]="importMenu"
      (menuOpened)="focusImportSearch()">
      Import
    </button>

    <button
      *ngIf="languages?.length > 0"
      class="button__text ocean button__small"
      mat-flat-button
      [matMenuTriggerFor]="languageMenu"
      (menuOpened)="focusLanguageSearch()">
      Add another language
    </button>
  </div>

  <ng-container *ngIf="previewEnabled">
    <div class="flex items-center" style="gap: 12px">
      <div *ngIf="isAiImproveEnabled" class="flex" style="gap: 6px">
        <span class="grass-darker">Message set to improve with AI</span>
        <img width="20" src="/assets/iconography/magic.svg" />
      </div>
      <button class="button__secondary button__small" mat-flat-button (click)="openMessagePreviewDialog()">
        {{ (smartCodesInUse$ | async) ? 'Preview message with AI' : 'Preview message' }}
      </button>
    </div>
  </ng-container>

  <mat-menu #languageMenu="matMenu" class="filters__cdk-menu">
    <input
      #langSearchInput
      class="filters__input-list-search"
      [(ngModel)]="languageSearchCriteria"
      type="text"
      placeholder="Search..."
      (click)="$event.stopPropagation()"
      (blur)="langSearchInput.focus()" />
    <div class="filter__list-fixed-height">
      <ng-container *ngFor="let lang of languages">
        <button
          *ngIf="
            languagesWeAlreadyHave.indexOf(lang.code) === -1 && filtersMatchSearchCriteria(lang, languageSearchCriteria)
          "
          mat-menu-item
          (click)="createNewRule(ruleSetId, lang.code)">
          {{ lang.native }} ({{ lang.english }})
        </button>
      </ng-container>
    </div>
  </mat-menu>

  <mat-menu #importMenu="matMenu" class="filters__cdk-menu">
    <input
      *ngIf="
        importableCannedResponses && (importableCustomCodes$ | async)?.length + importableCannedResponses.length >= 10
      "
      #importSearchInput
      class="filters__input-list-search"
      [(ngModel)]="importSearchCriteria"
      type="text"
      placeholder="Search..."
      (click)="$event.stopPropagation()"
      (blur)="importSearchInput.focus()" />
    <div class="filter__list-fixed-height">
      <ng-container *ngIf="importableCannedResponses?.length === 0 && (importableCustomCodes$ | async)?.length === 0">
        <span class="filter__list-no-data text__small">
          You don’t have any Canned Responses or Custom Codes to import.
        </span>
      </ng-container>

      <ng-container *ngFor="let cc of importableCustomCodes$ | async">
        <button
          *ngIf="importMatchSearchCriteria(cc.key, importSearchCriteria)"
          mat-menu-item
          (click)="insertIntoEditor(cc.text)">
          %%{{ cc.key }}%%
        </button>
      </ng-container>

      <ng-container *ngFor="let cr of importableCannedResponses">
        <button
          *ngIf="importMatchSearchCriteria(cr.name, importSearchCriteria)"
          mat-menu-item
          (click)="insertIntoEditor(cr.rule_preview)">
          {{ cr.name }}
        </button>
      </ng-container>
    </div>
  </mat-menu>
</div>
