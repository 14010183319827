import { PriceType } from './price_type';

export enum CreditNoteStatus {
  ADJUSTED = 'adjusted',
  REFUNDED = 'refunded',
  REFUND_DUE = 'credited',
  VOIDED = 'voided',
}

export interface CreditNoteAPIRes {
  credit_note_id: string;
  status: CreditNoteStatus;
  amount: string;
  date: string;
  download: string;
  price_type: PriceType;
}

export class CreditNote {
  public id: string;
  private _status: CreditNoteStatus;
  public amount: string;
  public date: string;
  public download: string;
  public downloading: boolean;

  get status() {
    return this._status;
  }

  private _price_type: PriceType;
  get price_type(): PriceType {
    return this._price_type;
  }

  constructor(newCN: CreditNoteAPIRes) {
    this.id = newCN.credit_note_id;
    this.setStatus(newCN.status);
    this.amount = newCN.amount;
    this.date = newCN.date;
    this.download = newCN.download;
    this.setPriceType(newCN.price_type);
  }

  private setStatus(status: CreditNoteStatus) {
    if (!(status in CreditNoteStatus)) {
      // Generic error log here to send dodgy status to Error tracking
    }
    this._status = status;
  }

  private setPriceType(pt: PriceType) {
    if (!(pt in PriceType)) {
      // Generic error log here to send dodgy status to Error tracking
    }
    this._price_type = pt;
  }

  public isStatusAdjusted() {
    return this.status === CreditNoteStatus.ADJUSTED;
  }

  public isStatusRefunded() {
    return this.status === CreditNoteStatus.REFUNDED;
  }

  public isStatusRefundDue() {
    return this.status === CreditNoteStatus.REFUND_DUE;
  }

  public isStatusVoided() {
    return this.status === CreditNoteStatus.VOIDED;
  }
}
