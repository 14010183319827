import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of, Observable, EMPTY } from 'rxjs';
import * as OptimizelyActions from './optimizely.actions';
import { OptimizelyService } from './optimizely.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InitResponse, EnabledFeatures } from './optimizely.models';
import { LocalStorageKey, LocalStoragePayload } from '@app/shared/interfaces';
import { OptimizelyState, OPTIMIZELY_DATA_TTL } from './optimizely.reducer';
import { getEnabledFeaturesState } from './optimizely.selectors';

@Injectable()
export class OptimizelyEffects {
  init$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(OptimizelyActions.init),
        map((action) => action),
        mergeMap((action) =>
          this.optimizelyService.init().pipe(
            mergeMap((response: InitResponse) => {
              return response && response.success
                ? of(OptimizelyActions.initSuccess({ forceReload: action.forceReload }))
                : of(OptimizelyActions.initFailure());
            }),
            catchError(() => of(OptimizelyActions.initFailure()))
          )
        )
      )
  );

  initFailure$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(OptimizelyActions.initFailure),
        map((action) => action),
        mergeMap(() => of(OptimizelyActions.loadEnabledFeaturesFailure()))
      )
  );

  loadEnabledFeatures$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(OptimizelyActions.loadEnabledFeatures, OptimizelyActions.initSuccess),
        concatLatestFrom(() => this.store.select(getEnabledFeaturesState)),
        mergeMap(([action, enabledFeaturesState]) => {
          if (
            enabledFeaturesState.data &&
            enabledFeaturesState.expiresAt &&
            new Date().getTime() < enabledFeaturesState.expiresAt
          ) {
            return of(
              OptimizelyActions.loadEnabledFeaturesSuccess({
                response: enabledFeaturesState.data,
                expiresAt: enabledFeaturesState.expiresAt,
              })
            );
          }

          const expiresAt = new Date().getTime() + OPTIMIZELY_DATA_TTL;

          return this.optimizelyService.loadEnabledFeatures().pipe(
            mergeMap((response: EnabledFeatures) => {
              return of(
                OptimizelyActions.loadEnabledFeaturesSuccess({
                  response,
                  expiresAt,
                })
              );
            }),
            catchError(() => of(OptimizelyActions.loadEnabledFeaturesFailure()))
          );
        })
      )
  );

  loadEnabledFeaturesSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(OptimizelyActions.loadEnabledFeaturesSuccess),
        map((action) => action),
        mergeMap((action) => {
          const payload: LocalStoragePayload<EnabledFeatures> = {
            data: action.response,
            expiresAt: action.expiresAt,
          };

          localStorage.setItem(LocalStorageKey.optimizely, JSON.stringify(payload));

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private optimizelyService: OptimizelyService,
    private store: Store<OptimizelyState>
  ) {}
}
