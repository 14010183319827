import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { Channel } from '@app/shared/models/channel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type ChannelValues = `${Channel}`;

@Injectable({
  providedIn: 'root',
})
export class PlatformsService {
  getPlatformsUrl = `${config.API_URL}/platforms`;

  constructor(private http: HttpClient) {}

  getPlatforms(): Observable<Array<{ key: ChannelValues; value: string }>> {
    return this.http.get(this.getPlatformsUrl).pipe(
      map((res: { data: Array<{ key: ChannelValues; value: string }> }) => res.data)
    );
  }
}
