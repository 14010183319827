export enum LoadingState {
  NotSent = 'NotSent',
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
}

// Util to combine loading state across various features that require info for a given page or component
export const combineLoadingStates = (required: LoadingState[], optional: LoadingState[] = []): LoadingState => {
  if (required.some((state) => state === LoadingState.Error)) {
    return LoadingState.Error;
  }
  if (required.some((state) => state === LoadingState.Pending)) {
    return LoadingState.Pending;
  }
  if (required.every((state) => state === LoadingState.Success)) {
    return LoadingState.Success;
  }
  return LoadingState.NotSent;
};
