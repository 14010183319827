import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressAutocompleteComponent } from '@app/ui/address-autocomplete/address-autocomplete.component';
import { AddressAutocompleteComponentModule } from '@app/ui/address-autocomplete/address-autocomplete.component.module';
import { FullscreenUploadDropzoneComponent } from '@app/ui/fullscreen-upload-dropzone/fullscreen-upload-dropzone.component';
import { SvgCacheService } from '@app/ui/icons/icon/svg-cache.service';
import { MaterialModule } from '@app/ui/material.module';
import { ProfileImgPlaceholderComponent } from '@app/ui/profile-img/profile-img-placeholder.component';
import { ScopingIconsComponent } from '@app/ui/scoping-icons/scoping-icons.component';
import { AlertCardComponent } from './alert-card/alert-card.component';
import { BadgeComponent } from './badge/badge.component';
import { BookmarkComponent } from './bookmark/bookmark.component';
import { CloseComponent } from './close/close.component';
import {
  CollapsibleCardComponent,
  CollapsibleCardContentDirective,
} from './collapsible-card/collapsible-card.component';
import { ConfettiComponent } from './confetti/confetti.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { DayToggleGroupComponent } from './day-toggle-group/day-toggle-group.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { FullSizeDialogComponent } from './full-size-dialog/full-size-dialog.component';
import { HeroCardComponent } from './hero-card/hero-card.component';
import { IconComponent } from './icons/icon/icon.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MoreMenuTriggerComponent } from './more-menu-trigger/more-menu-trigger.component';
import { ProfileImgComponent } from './profile-img/profile-img.component';
import { RadioCardComponent } from './radio-card/radio-card.component';
import { ShowMoreComponent } from './show-more/show-more.component';
import { SplitCardComponent } from './split-card/split-card.component';
import { StarsComponent } from './stars/stars.component';
import { TagsComponent } from './tags/tags.component';
import { ToggleButtonsComponent } from './toggle-buttons/toggle-buttons.component';
import { TogglePillsComponent } from './toggle-pills/toggle-pills.component';
import { ThrottleTooltipDirective } from './throttle-tooltip.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { ProgressStepperComponent } from './progress-stepper/progress-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';

/**
 * - This module should not contain any business logic or import any
 * other modules besides Angular Material/3rd party UI.
 * - Does NOT use angular flex layout.
 * - Enforces strict templates.
 **/
@NgModule({
  declarations: [
    AlertCardComponent,
    BadgeComponent,
    BookmarkComponent,
    CloseComponent,
    CollapsibleCardComponent,
    CollapsibleCardContentDirective,
    ConfettiComponent,
    CopyToClipboardComponent,
    DayToggleGroupComponent,
    DragAndDropDirective,
    FileUploadComponent,
    FullSizeDialogComponent,
    FullscreenUploadDropzoneComponent,
    HeroCardComponent,
    IconComponent,
    LoadingSpinnerComponent,
    MoreMenuTriggerComponent,
    ProfileImgComponent,
    ProfileImgPlaceholderComponent,
    ProgressStepperComponent,
    RadioCardComponent,
    ScopingIconsComponent,
    ShowMoreComponent,
    SplitCardComponent,
    StarsComponent,
    TagsComponent,
    TextareaComponent,
    ThrottleTooltipDirective,
    ToggleButtonsComponent,
    TogglePillsComponent,
  ],
  imports: [
    AddressAutocompleteComponentModule,
    CdkStepperModule,
    CommonModule,
    MaterialModule,
    OverlayModule,
    ReactiveFormsModule,
  ],
  exports: [
    CdkStepperModule,
    MaterialModule,
    AddressAutocompleteComponentModule,
    AddressAutocompleteComponent,
    AlertCardComponent,
    BadgeComponent,
    BookmarkComponent,
    CloseComponent,
    CollapsibleCardComponent,
    CollapsibleCardContentDirective,
    ConfettiComponent,
    CopyToClipboardComponent,
    DayToggleGroupComponent,
    DragAndDropDirective,
    FileUploadComponent,
    FullSizeDialogComponent,
    FullscreenUploadDropzoneComponent,
    HeroCardComponent,
    IconComponent,
    LoadingSpinnerComponent,
    MoreMenuTriggerComponent,
    ProfileImgComponent,
    ProfileImgPlaceholderComponent,
    ProgressStepperComponent,
    RadioCardComponent,
    ScopingIconsComponent,
    ShowMoreComponent,
    SplitCardComponent,
    StarsComponent,
    TagsComponent,
    TextareaComponent,
    ThrottleTooltipDirective,
    ToggleButtonsComponent,
    TogglePillsComponent,
  ],
  providers: [SvgCacheService],
})
export class UiModule {}
