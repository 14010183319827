import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromOptimizelyReducer from './optimizely.reducer';
import { OptimizelyEffects } from './optimizely.effects';
import { OptimizelyService } from './optimizely.service';
import { OptimizelyFacade } from './optimizely.facade';
import { OptimizelyCacheMetaReducer } from './optimizely-cache.meta-reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromOptimizelyReducer.OPTIMIZELY_FEATURE_KEY, fromOptimizelyReducer.optimizelyReducer, {
      metaReducers: [OptimizelyCacheMetaReducer],
    }),
    EffectsModule.forFeature([OptimizelyEffects]),
  ],
  providers: [OptimizelyFacade, OptimizelyService],
})
export class OptimizelyStateModule {}
