import { Injectable } from '@angular/core';
import { DeviceDetectionService } from '../device-detection/device-detection.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { config } from '@app/core/app-config';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OnesignalService {
  constructor(
    private deviceDetectionService: DeviceDetectionService,
    private scriptLoader: ScriptLoaderService,
    private readonly http: HttpClient
  ) {}

  public initialiseOneSignal(id_hash: string, email: string) {
    // OneSignal should be disabled on the Hospitable iOS app due to duplicates with device native notifications
    if (this.deviceDetectionService.isIosApp() || environment.production === false) {
      return;
    }

    this.scriptLoader
      .load({
        name: 'OneSignal CDN',
        src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
      })
      .subscribe((res) => {
        if ((window as any).OneSignal) {
          console.log(`Subscribing to OneSignal as ${id_hash} with email: ${email}`);

          (window as any).OneSignal.push([
            'init',
            {
              appId: config.ONESIGNAL_APP_ID,
              autoResubscribe: true,
              allowLocalhostAsSecureOrigin: true,
              notifyButton: {
                enable: false /* Set to false to hide */,
              },
            },
          ]);

          (window as any).OneSignal.push(function () {
            (window as any).OneSignal.setExternalUserId(id_hash);
          });

          (window as any).OneSignal.push(function () {
            (window as any).OneSignal.sendTags({
              id: id_hash,
              email: email,
            });
          });

          // Call our API when a user successfully subscribes
          (window as any).OneSignal.push(() => {
            // Occurs when the user's subscription changes to a new value.
            (window as any).OneSignal.on('subscriptionChange', (isSubscribed) => {
              console.log("The user's subscription state is now:", isSubscribed);

              if (isSubscribed) {
                this.setWebPushId();
              }
            });
          });
        }
      });
  }

  public async setWebPushId() {
    const id = await this.getPlayerId();
    this.http
      .post(`${config.API_URL}/user/push-id`, {
        platform: 'onesignal',
        id,
      })
      .subscribe((res) => {});
  }

  public async getPlayerId() {
    return await (window as any).OneSignal.getUserId();
  }

  public shutdownOneSignal() {
    console.log('Shutting down OneSignal');
    if ((window as any).OneSignal) {
      (window as any).OneSignal.deleteTags(['id', 'email']);

      (window as any).OneSignal.push(function () {
        (window as any).OneSignal.removeExternalUserId();
      });
    }
  }

  public showSlideNotification() {
    if ((window as any).OneSignal) {
      (window as any).OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        if (!isEnabled) {
          (window as any).OneSignal.showSlidedownPrompt();
        }
      });
    }
  }
}
