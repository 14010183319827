import { EventDefinition } from 'redux-beacon';
import { AnalyticsEvent } from '@app/shared/interfaces';

type Event = EventDefinition<AnalyticsEvent>;

export const trackEvent = function (eventDef: Event) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (action: any, prevState: any, nextState: any) {
    const event = eventDef(action, prevState, nextState);
    const analyticsEvent: AnalyticsEvent = {
      type: event.type,
      name: event.name,
      parameters: event.parameters,
      options: event.options,
    };
    return analyticsEvent;
  };
};
