import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/core/app-config';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private translateUrl = `${config.API_URL}/translate`;

  constructor(private http: HttpClient) {}

  translate(fromKey: string, toKey: string, textToTranslate: string): Observable<string> {
    const postBody = {
      from: fromKey,
      to: toKey,
      text: textToTranslate ? textToTranslate : 'Write your message...', // to prevent 422 errors on missing text
    };

    return this.http.post(this.translateUrl, postBody).pipe(
      map((res: { success: boolean; text: string; to: string; from: string }) => {
        return res.text;
      }),
      catchError((err) => 'Error')
    );
  }
}
