import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserManagementUser } from '@app/shared/models/user-management-user';
import { config } from '@app/core/app-config';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, of, BehaviorSubject, from, Observer } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Router } from '@angular/router';

interface DeletedUsersResponse {
  deleted: number;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private userUrl = `${config.API_URL}/users`;
  private inviteDetailsUrl = `${config.API_URL}/users/get-started/`;
  private acceptInviteUrl = `${config.API_URL}/users/accept-invite/`;
  private teamsUrl = `${config.API_URL}/teams/`;
  private switchTeamsUrl = `${config.API_URL}/teams/{teamid}/switch`;

  private _users: BehaviorSubject<any[]> = new BehaviorSubject(null);
  private _latestApiUsers: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public readonly users$: Observable<any[]> = from(this._users.asObservable());

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  getUsersFromApi(): Observable<UserManagementUser[]> {
    this._users.next(null);
    return this.http.get<UserManagementUser[]>(this.userUrl).pipe(
      map((res: any) => {
        this._latestApiUsers.next(res.data);
        this._users.next(res.data);
        return res.data;
      }),
      catchError((err) => {
        return of([]);
      })
    );
  }

  applySearchToUsers(search: string) {
    if (!search || search === '') {
      this._users.next(this._latestApiUsers.value);
      return;
    }

    const apiUsers = this._latestApiUsers.value;
    const result = apiUsers.filter(
      (au) =>
        au.name.toLowerCase().includes(search.toLowerCase()) || au.email.toLowerCase().includes(search.toLowerCase())
    );

    this._users.next(result);
  }

  getSingleUserFromApi(userId: string) {
    return this.http.get(`${this.userUrl}/${userId}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  createUser(user: UserManagementUser): Observable<boolean> {
    return this.http.post(this.userUrl, user).pipe(
      map((res: any) => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  updateUser(user: UserManagementUser): Observable<boolean> {
    return this.http.put(`${this.userUrl}/${user.id}`, user).pipe(
      map((res: any) => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  deleteUser(userId: string): Observable<boolean> {
    return this.http.delete(`${this.userUrl}/${userId}`).pipe(
      map((res) => {
        this._latestApiUsers.next(this._latestApiUsers.getValue().filter((user) => user.id !== userId));
        this._users.next(this._users.getValue().filter((user) => user.id !== userId));
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  deleteUsers(user_ids: string[]): Observable<DeletedUsersResponse | { error: boolean }> {
    return this.http.post<DeletedUsersResponse>(`${this.userUrl}/bulk/delete`, { user_ids }).pipe(
      tap((res) => {
        this._latestApiUsers.next(this._latestApiUsers.getValue()?.filter((user) => !user_ids.includes(user.id)));
        this._users.next(this._users.getValue()?.filter((user) => !user_ids.includes(user.id)));
      }),
      map((res) => res),
      catchError((err) => {
        return of({
          error: true,
        });
      })
    );
  }

  getInviteDetails(token: string) {
    return this.http.get(`${this.inviteDetailsUrl}${token}`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError((err) => {
        return of({
          error: true,
        });
      })
    );
  }

  acceptInviteAndSetPassword(token: string, email: string, password: string) {
    return this.http.post(`${this.acceptInviteUrl}${token}`, { password }).pipe(
      map((res: any) => {
        if (res.data && res.data.token) {
          localStorage.setItem('accessToken', res.data.token);

          // localStorage set is not instant and has no callback
          setTimeout(() => {
            this.authService.getUserDetailsFromApi().subscribe((res) => {
              localStorage.setItem('user', JSON.stringify(res));

              this.authService.loginActions(config.DEFAULT_PAGE);
            });
          }, 100);
        }
      }),
      catchError((err) => {
        return of({
          error: true,
        });
      })
    );
  }

  getTeamsForUser(): Observable<Team[]> {
    return this.http.get(this.teamsUrl).pipe(
      map((res: GetTeamsAPIResponse) => {
        return res.data;
      })
    );
  }

  switchActiveTeam(newTeamId: number) {
    const url = this.switchTeamsUrl.replace('{teamid}', newTeamId.toString());

    return this.http.put(url, {}).pipe(
      map((res: GetTeamsAPIResponse) => {
        if (res.data) {
          localStorage.setItem('user', JSON.stringify(res.data));

          // This should be a hard reload and it will re-identify on segment etc
          window.location.href = '/dashboard';
        }
      })
    );
  }
}

interface GetTeamsAPIResponse {
  data: Team[];
}

export interface Team {
  name: string;
  picture: string;
  team_id: number;
  owner: boolean;
  active: boolean;
}
