import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'sbnb-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private authService: AuthenticationService) {}

  isLoggedIn: boolean;

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
  }
}
