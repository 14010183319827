import { LoadingState } from '@app/shared/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as AIActions from './AI.actions';
import { GeneratedReply } from './AI.models';

import * as UsersActions from '../users/users.actions';

export const AI_FEATURE_KEY = 'AI';

export interface AIState {
  generateReply: {
    loadingState: LoadingState;
    data: GeneratedReply | null;
  };
  generateImprovement: {
    loadingState: LoadingState;
    data: GeneratedReply | null;
  };
}

export interface AIPartialState {
  readonly [AI_FEATURE_KEY]: AIState;
}

export const initialAIState: AIState = {
  generateReply: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  generateImprovement: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
};

const reducer = createReducer(
  initialAIState,

  // Reset the store when login actions happen
  on(UsersActions.resetStore, () => initialAIState),

  on(AIActions.resetGeneratedReply, (state) => ({
    ...state,
    generateReply: initialAIState.generateReply,
  })),

  on(AIActions.generateReply, (state) => ({
    ...state,
    generateReply: {
      ...state.generateReply,
      loadingState: LoadingState.Pending,
    },
  })),

  on(AIActions.generateReplySuccess, (state, { response }) => ({
    ...state,
    generateReply: {
      ...state.generateReply,
      loadingState: LoadingState.Success,
      data: response.data,
    },
  })),

  on(AIActions.generateReplyFailure, (state) => ({
    ...state,
    generateReply: {
      ...state.generateReply,
      loadingState: LoadingState.Error,
    },
  })),

  on(AIActions.generateImprovement, (state) => ({
    ...state,
    generateImprovement: {
      ...state.generateImprovement,
      loadingState: LoadingState.Pending,
    },
  })),

  on(AIActions.generateImprovementSuccess, (state, { response }) => ({
    ...state,
    generateImprovement: {
      ...state.generateImprovement,
      loadingState: LoadingState.Success,
      data: response.data,
    },
  })),

  on(AIActions.generateImprovementFailure, (state) => ({
    ...state,
    generateImprovement: {
      ...state.generateImprovement,
      loadingState: LoadingState.Error,
    },
  })),

  on(AIActions.resetGeneratedReply, (state) => ({
    ...state,
    generateImprovement: initialAIState.generateImprovement,
  }))
);

export function AIReducer(state: AIState | undefined, action: Action) {
  return reducer(state, action);
}
