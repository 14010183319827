import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiErrorResponse,
  ApiValidationError,
  NotificationType,
  SimpleApiValidationError,
} from '@app/shared/interfaces';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as BillingActions from './billing.actions';
import { PlanId, Subscription, SubscriptionErrorReasons } from './billing.models';
import { BillingService } from './billing.service';
import { UsersFacade } from '@app/shared/+state/users';
import { captureException } from 'src/sentry';
import { IntercomService } from '@app/shared/services/intercom.service';

@Injectable()
export class BillingEffects {
  loadSubscription$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.loadSubscription),
        map((action) => action),
        mergeMap((action) =>
          this.billingService.loadSubscription().pipe(
            mergeMap((response: Subscription) => {
              if (isErrorType(response)) {
                return of(BillingActions.loadSubscriptionFailure({ response: response as any as ApiValidationError }));
              }
              return of(
                BillingActions.loadSubscriptionSuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => of(BillingActions.loadSubscriptionFailure({ response: error })))
          )
        )
      )
  );

  loadSubscriptionFailure$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.loadSubscriptionFailure),
        map((action) => action),
        mergeMap((action) => {
          const message =
            action.response.message ??
            // @ts-expect-error the response from the server can take several shapes
            action.response?.error_reason ??
            'An error occurred when loading your subscription';
          this.notificationService.open(message, 'Dismiss', NotificationType.Error);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadPlans$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.loadPlans),
        mergeMap((action) =>
          this.billingService.loadPlans().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.loadPlansSuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => of(BillingActions.loadPlansFailure({ response: error })))
          )
        )
      )
  );

  initiateSubscriptionChange$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.initiateSubscriptionChange),
        map((action) => action),
        tap((action) => {
          this.router.navigateByUrl((action as any).redirectUrl);
        }),
        mergeMap((action) => {
          if (action.planId === PlanId.Host) {
            return of(BillingActions.loadDowngradeIssues());
          }
          return EMPTY;
        })
      ),
    { functional: true, dispatch: true }
  );

  checkDowngradeIssues$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.loadDowngradeIssues),
        map((action) => action),
        mergeMap((action) =>
          this.billingService.checkDowngradeIssues(PlanId.Host).pipe(
            mergeMap((response) => {
              return of(
                BillingActions.loadDowngradeIssuesSuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => of(BillingActions.loadDowngradeIssuesFailure({ response: error })))
          )
        )
      )
  );

  changeSubscription$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.changeSubscription),
        mergeMap((action) => {
          // We need to call subscribe if the user does not have an active subscription
          // And changeSubscription if the user has an active subscription
          // We cannot call changeSubscription for trialling users, as we have no card on file the backend can't action it
          if (action.isSubscribing) {
            return this.billingService
              .attemptSubscribe(action.activeListings, action.stashedPaymentMethod, action.planId)
              .pipe(
                mergeMap((response) => {
                  if ((response as any)?.success === false) {
                    return of(BillingActions.changeSubscriptionFailure({ response }));
                  }

                  return of(
                    BillingActions.changeSubscriptionSuccess({
                      response,
                    })
                  );
                })
              );
          } else {
            return this.billingService.changeSubscription(action.planId).pipe(
              mergeMap((response) => {
                if ((response as SimpleApiValidationError)?.success === false) {
                  return of(
                    BillingActions.changeSubscriptionFailure({ response: response as SimpleApiValidationError })
                  );
                }

                return of(
                  BillingActions.changeSubscriptionSuccess({
                    response: response as Record<string, unknown>,
                  })
                );
              }),

              catchError((error: any) => of(BillingActions.changeSubscriptionFailure({ response: error })))
            );
          }
        })
      )
  );

  changeSubscriptionFailure$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.changeSubscriptionFailure),
        map((action) => action),
        mergeMap((action) => {
          const base = action.response.error === true ? action.response : action.response.error;
          if (isErrorType(base)) {
            const reason = base?.error_reason;
            const message: string = base?.message ?? reason ?? 'An error occurred when changing your subscription';
            this.notificationService.open(message, 'Dismiss', NotificationType.Error);

            if (reason === SubscriptionErrorReasons.SubscriptionCannotBeCreated) {
              this.router.navigate(['/settings/your-plan']);
              this.intercomService.investigateSubscriptionIssue();
            }

            if (reason === SubscriptionErrorReasons.SubscriptionCannotBeResubscribedTo) {
              this.router.navigate(['/settings/your-plan']);
            }
          }
          captureException(action.response);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  changeSubscriptionSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.changeSubscriptionSuccess),
        tap(() => {
          this.usersFacade.loadUser();
        }),
        mergeMap(() => {
          return of(BillingActions.loadSubscription(), BillingActions.loadPlans());
        })
      )
  );

  loadDeviceAddon$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.loadDeviceAddon),
        mergeMap(() =>
          this.billingService.loadDeviceAddon().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.loadDeviceAddonSuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => of(BillingActions.loadDeviceAddonFailure({ response: error })))
          )
        )
      )
  );

  activateDeviceAddon$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.confirmDeviceAddon),
        mergeMap(() =>
          this.billingService.confirmDeviceAddon().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.confirmDeviceAddonSuccess(response),
                BillingActions.loadDeviceAddon(),
                BillingActions.loadSubscription()
              );
            }),
            catchError((error: ApiErrorResponse) => of(BillingActions.confirmDeviceAddonFailure({ response: error })))
          )
        )
      )
  );

  cancelSubscription$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(BillingActions.cancelSubscription),
        map((action) => action),
        mergeMap((action) =>
          this.billingService
            .cancelSubscriptionImmediately({ reason: action.reason, textReason: action.textReason })
            .pipe(
              mergeMap((response) => {
                return of(
                  BillingActions.cancelSubscriptionSuccess({
                    response,
                  })
                );
              }),
              catchError((error: ApiErrorResponse) => of(BillingActions.cancelSubscriptionFailure({ response: error })))
            )
        )
      )
  );

  constructor(
    private readonly actions$: Actions,
    private billingService: BillingService,
    private notificationService: ToastNotificationsService,
    private router: Router,
    private usersFacade: UsersFacade,
    private readonly intercomService: IntercomService
  ) {}
}

const isErrorType = (
  obj: any | { error: boolean; error_reason: string; message: string }
): obj is { error: boolean; error_reason: string; message: string } => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  return 'error' in obj;
};
