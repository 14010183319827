import { createAction, props } from '@ngrx/store';
import { ApiErrorResponse, ListingReview } from '@app/shared/interfaces';
import { UpdateListingReviewsFormAnalytics, UpdateListingReviewsFormEntity } from './listings.models';
import { RequestOverride } from '../shared/request-override.interface';

export const loadListingReviews = createAction(
  '[Listings] Load Listing Reviews',
  props<{
    platform: string;
    channelId: string;
    shouldLoadMergeCandidates: boolean;
    requestOverride: RequestOverride;
  }>()
);
export const loadListingReviewsSuccess = createAction(
  '[Listings] Load Listing Reviews Success',
  props<{ response: ListingReview; shouldLoadMergeCandidates: boolean }>()
);
export const loadListingReviewsFailure = createAction(
  '[Listings] Load Listing Reviews Failure',
  props<{ response: ApiErrorResponse }>()
);
export const listingReviewsPusherUpdate = createAction(
  '[Listings] Listing Reviews Pusher Update',
  props<{
    listings: ListingReview['listings'];
    shouldLoadMergeCandidates: boolean;
  }>()
);

export const trackStartedMergeMatch = createAction('[Listings] Track Started Merge Match');

export const trackStartedMergeMatchSuccess = createAction(
  '[Listings] Track Started Merge Match Success',
  props<{
    analytics: UpdateListingReviewsFormAnalytics;
  }>()
);

export const trackMergeMatchOutcome = createAction(
  '[Listings] Track Merge Match Outcome',
  props<{
    analytics: UpdateListingReviewsFormAnalytics;
  }>()
);

export const updateListingReviewsForm = createAction(
  '[Listings] Update Listing Reviews Form',
  props<{ form: UpdateListingReviewsFormEntity }>()
);

export const saveListingReviewsForm = createAction('[Listings] Save Listing Reviews Form');

export const bulkCreatePropertiesSuccess = createAction(
  '[Listings] Bulk Create Properties Success',
  props<{ form?: UpdateListingReviewsFormEntity }>()
);

export const bulkMergePropertiesSuccess = createAction(
  '[Listings] Bulk Merge Properties Success',
  props<{ form?: UpdateListingReviewsFormEntity }>()
);

export const bulkMutePropertiesSuccess = createAction(
  '[Listings] Bulk Mute Properties Success',
  props<{ form?: UpdateListingReviewsFormEntity }>()
);

export const bulkUnMutePropertiesSuccess = createAction(
  '[Listings] Bulk UnMute Properties Success',
  props<{ form?: UpdateListingReviewsFormEntity }>()
);
