import { createAction, props } from '@ngrx/store';
import { ApiErrorResponse, PropertyMergeCandidate } from '@app/shared/interfaces';
import { PropertiesResponse } from './properties.models';
import { RequestOverride } from '../shared/request-override.interface';

// Load Properties
export const loadProperties = createAction(
  '[Properties] Load Properties',
  props<{
    paginate?: boolean;
    filterCriteria?: any[];
    offset?: number;
    search?: string;
    limit?: number;
    transformer?: string; // simple
    idList?: any[];
  }>()
);

export const loadPropertiesSuccess = createAction(
  '[Properties] Load Properties Success',
  props<{ response: PropertiesResponse }>()
);
export const loadPropertiesFailure = createAction(
  '[Properties] Load Properties Failure',
  props<{ response: ApiErrorResponse }>()
);

// Load Property Merge Candidates
export const loadPropertiesMergeCandidates = createAction(
  '[Properties] Load Properties Merge Candidates',
  props<{
    propertyIds: string[];
    listingIds?: string[];
  }>()
);

export const loadPropertiesMergeCandidatesSuccess = createAction(
  '[Properties] Load Properties Merge Candidates Success',
  props<{ response: PropertyMergeCandidate[] }>()
);
export const loadPropertiesMergeCandidatesFailure = createAction(
  '[Properties] Load Properties Merge Candidates Failure',
  props<{ response: ApiErrorResponse }>()
);

// Bulk Mute / UnMute Properties
export const bulkMuteAndUnMuteProperties = createAction(
  '[Properties] Bulk Mute And Unmute Properties',
  props<{
    mute: string[];
    unmute?: string[];
    requestOverride?: RequestOverride;
  }>()
);

export const bulkMuteAndUnMutePropertiesSuccess = createAction('[Properties] Bulk Mute And Unmute Properties Success');
export const bulkMuteAndUnMutePropertiesFailure = createAction(
  '[Properties] Bulk Mute And Unmute Properties Failure',
  props<{ response: ApiErrorResponse }>()
);
