<div fxLayout="column" fxLayoutAlign="space-between">
  <div fxFlex>
    <h3>Remove Template</h3>
    <p>
      Are you sure you want to remove the
      <strong>{{ dialogData.rule.language.label }}</strong>
      version of this template?
    </p>
  </div>

  <div fxLayoutAlign="end center" fxLayoutGap="8px" style="margin-top: 40px">
    <button type="submit" mat-flat-button class="button__secondary" (click)="cancelClicked()">Cancel</button>
    <button type="submit" mat-flat-button (click)="okClicked()">Remove</button>
  </div>
</div>
