import { LoadingState, User } from '@app/shared/interfaces';
import { createReducer, on, Action } from '@ngrx/store';

import * as UsersActions from './users.actions';

export const USERS_FEATURE_KEY = 'users';

export interface UsersState {
  user: {
    loadingState: LoadingState;
    data: User | null;
  };
  signUpWithAirbnb: {
    loadingState: LoadingState;
    error?: string | null;
  };
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: UsersState;
}

export const initialUsersState: UsersState = {
  user: {
    loadingState: LoadingState.NotSent,
    data: null,
  },
  signUpWithAirbnb: {
    loadingState: LoadingState.NotSent,
    error: null,
  },
};

const reducer = createReducer(
  initialUsersState,

  // Reset the store when login actions happen
  on(UsersActions.resetStore, () => initialUsersState),

  on(UsersActions.loadUser, (state) => ({
    ...state,
    user: {
      ...state.user,
      loadingState: LoadingState.Pending,
    },
  })),

  on(UsersActions.loadUserSuccess, (state, { response }) => ({
    ...state,
    user: ((response) => {
      return {
        ...state.user,
        ...{
          loadingState: LoadingState.Success,
          data: response && response.data ? response.data : null,
        },
      };
    })(response),
  })),

  on(UsersActions.loadUserFailure, (state) => ({
    ...state,
    user: {
      ...state.user,
      loadingState: LoadingState.Error,
    },
  })),

  on(UsersActions.signUpWithAirbnb, (state) => ({
    ...state,
    signUpWithAirbnb: {
      ...state.signUpWithAirbnb,
      loadingState: LoadingState.Pending,
    },
  })),

  on(UsersActions.signUpWithAirbnbUrlFailure, (state) => ({
    ...state,
    signUpWithAirbnb: {
      ...state.signUpWithAirbnb,
      loadingState: LoadingState.Error,
    },
  })),

  on(UsersActions.signUpWithAirbnbCallback, (state) => ({
    ...state,
    signUpWithAirbnb: {
      ...state.signUpWithAirbnb,
      loadingState: LoadingState.Pending,
    },
  })),

  on(UsersActions.signUpWithAirbnbCallbackSuccess, (state) => ({
    ...state,
    signUpWithAirbnb: {
      ...state.signUpWithAirbnb,
      loadingState: LoadingState.Success,
    },
  })),

  // on(UsersActions.signUpWithAirbnbCallbackFailure, (state) => ({
  //   ...state,
  //   signUpWithAirbnb: {
  //     ...state.signUpWithAirbnb,
  //     loadingState: LoadingState.Error,
  //   },
  // }))
  on(UsersActions.signUpWithAirbnbCallbackFailure, (state, { response }) => ({
    ...state,
    signUpWithAirbnb: ((response) => {
      let error;
      if (response && response.error) {
        if (response.error.error_message) {
          error = response.error.error_message;
        }

        if (response.error.detail && typeof response.error.detail === 'string') {
          error = response.error.detail;
        }
      }

      return {
        ...state.signUpWithAirbnb,
        ...{
          loadingState: LoadingState.Error,
          error,
        },
      };
    })(response),
  }))
);

export function usersReducer(state: UsersState | undefined, action: Action) {
  return reducer(state, action);
}
