export enum NotificationType {
  Success = 'success-snackbar',
  Error = 'error-snackbar',
}

export enum NotificationVerticalPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export enum NotificationHorizontalPosition {
  Start = 'start',
  Center = 'center',
  End = 'end',
  Left = 'left',
  Right = 'right',
}
