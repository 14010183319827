<form [formGroup]="form" autocomplete="off">
  <mat-form-field class="fw deep-16px-padding input__mini">
    <img class="search__icon" src="/assets/iconography/search.svg" width="14" height="14" />
    <input
      autocomplete="false"
      aria-autocomplete="none"
      class="search__text"
      matInput
      formControlName="search"
      name="search"
      [placeholder]="placeholderText || 'Search...'"
      (keydown.enter)="stopClearingInput($event)" />
    <button class="button__clear" *ngIf="form.get('search').value" matSuffix aria-label="Clear" (click)="clearValue()">
      x
    </button>
  </mat-form-field>
</form>
