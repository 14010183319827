import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBillingReducer from './billing.reducer';
import { BillingEffects } from './billing.effects';
import { BillingService } from './billing.service';
import { BillingFacade } from './billing.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBillingReducer.BILLING_FEATURE_KEY, fromBillingReducer.billingReducer),
    EffectsModule.forFeature([BillingEffects]),
  ],
  providers: [BillingFacade, BillingService],
})
export class BillingStateModule {}
