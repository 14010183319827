<div
  *ngIf="customCode.all_conditions"
  fxLayout
  fxLayout.lt-md="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="16px">
  <span>No condition: include all the time</span>
  <button
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="conditionsMenu"
    (menuOpened)="setFocusToSearch()">
    + Add a condition
  </button>
</div>

<div
  *ngIf="!customCode.all_conditions && customCode.condition.key && activeMetaCode"
  fxLayout
  fxLayoutAlign="start center"
  fxLayoutGap="4px"
  fxLayout.lt-md="row wrap">
  <span *ngIf="activeMetaCode.label_before">{{ activeMetaCode.label_before }}</span>

  <button
    *ngIf="activeMetaCode.type === 'boolean' && activeMetaCode.bool_true && activeMetaCode.bool_false"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="booleanMenu">
    <span *ngIf="customCode.condition.value || customCode.condition.value === false">
      {{ customCode.condition.value_label }}
    </span>
    <span *ngIf="customCode.condition.value === null">[ select ]</span>
  </button>

  <button
    *ngIf="activeMetaCode.type === 'int'"
    mat-flat-button
    class="filter__option-btn button__small button__text"
    [matMenuTriggerFor]="intMenu"
    (menuOpened)="intMenu.resetActiveItem()">
    <span *ngIf="customCode.condition.value || customCode.condition.value === false">
      {{ customCode.condition.operator_label }} {{ customCode.condition.value }}
    </span>
    <span *ngIf="!customCode.condition.operator || !customCode.condition.value">[ select ]</span>
  </button>

  <span *ngIf="activeMetaCode.label_after && customCode.condition.value !== 1">{{ activeMetaCode.label_after }}</span>
  <span *ngIf="activeMetaCode.label_after_singular && customCode.condition.value === 1">
    {{ activeMetaCode.label_after_singular }}
  </span>

  <button mat-flat-button class="button__small button__text remove__button" (click)="removeCondition()">
    Remove condition
  </button>
</div>

<mat-menu #conditionsMenu="matMenu" class="filters__cdk-menu condition__menu filter__list-fixed-height">
  <input
    #searchInput
    class="filters__input-list-search"
    [(ngModel)]="searchCriteria"
    type="text"
    placeholder="Search"
    (click)="$event.stopPropagation()" />
  <ng-container *ngFor="let condition of meta.conditions">
    <button
      *ngIf="
        !searchCriteria ||
        searchCriteria === '' ||
        condition.label_before.indexOf(searchCriteria) > -1 ||
        condition.label_after.indexOf(searchCriteria) > -1
      "
      mat-menu-item
      (click)="setActiveCondition(condition)">
      <span [class.strong]="condition.label_importance === 'before'">{{ condition.label_before }}</span>
      <span *ngIf="condition.type === 'int'">...</span>
      <ng-container *ngIf="condition.type === 'boolean' && condition.bool_true && condition.bool_false">
        <span>&nbsp;{{ condition.bool_true }} / {{ condition.bool_false }}&nbsp;</span>
      </ng-container>
      <span [class.strong]="condition.label_importance === 'after'">{{ condition.label_after }}</span>
    </button>
  </ng-container>
</mat-menu>

<mat-menu #booleanMenu="matMenu" class="filters__cdk-menu">
  <button
    *ngIf="activeMetaCode?.bool_true"
    mat-menu-item
    (click)="setBoolConditionValue(true, activeMetaCode.bool_true)">
    {{ activeMetaCode.bool_true }}
  </button>
  <button
    *ngIf="activeMetaCode?.bool_false"
    mat-menu-item
    (click)="setBoolConditionValue(false, activeMetaCode.bool_false)">
    {{ activeMetaCode.bool_false }}
  </button>
  <button class="filter__btn-done">Done</button>
</mat-menu>

<mat-menu #intMenu="matMenu" class="filters__cdk-menu">
  <mat-radio-group
    #intRadioGroup
    [(ngModel)]="selectedIntValue"
    fxLayout="column"
    class="filters__input-number-rg"
    (change)="setIntOperator($event)">
    <mat-radio-button value="=" (click)="$event.stopPropagation()">exactly</mat-radio-button>
    <mat-form-field *ngIf="customCode.condition.operator === '='" (click)="$event.stopPropagation()">
      <input matInput type="number" [value]="customCode.condition.value" (change)="setIntValue($event)" />
    </mat-form-field>

    <mat-radio-button value=">" (click)="$event.stopPropagation()">more than</mat-radio-button>
    <mat-form-field *ngIf="customCode.condition.operator === '>'" (click)="$event.stopPropagation()">
      <input matInput type="number" [value]="customCode.condition.value" (change)="setIntValue($event)" />
    </mat-form-field>

    <mat-radio-button value="<" (click)="$event.stopPropagation()">less than</mat-radio-button>
    <mat-form-field *ngIf="customCode.condition.operator === '<'" (click)="$event.stopPropagation()">
      <input matInput type="number" [value]="customCode.condition.value" (change)="setIntValue($event)" />
    </mat-form-field>
  </mat-radio-group>
  <button class="filter__btn-done">Done</button>
</mat-menu>
