import { Injectable } from '@angular/core';
import { Filter } from '@app/shared/models/filter';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/core/app-config';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
import * as subDays from 'date-fns/sub_days';

const setSelectedValue = (savedFilterValue) => {
  if (savedFilterValue === null) {
    return {
      key: 'all',
      label: 'All',
    };
  }

  return savedFilterValue;
};

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  stashedFilters: any[] = [];

  private getListUrl = `${config.API_URL}/filters/`;

  constructor(private http: HttpClient) {}

  getFiltersForKey(key: string, customEndpoint?: string): Observable<Filter[]> {
    if (customEndpoint && !(customEndpoint.indexOf('http://') === 0 || customEndpoint.indexOf('https://') === 0)) {
      customEndpoint = `${config.API_URL}` + customEndpoint;
    }

    const url = customEndpoint ? customEndpoint : `${this.getListUrl}${key}`;
    return this.http.get<Filter[]>(url).pipe(map((response) => response['data']));
  }

  getFilterValuesForKey(url: string, activeSegment?: string): Observable<any[]> {
    if (activeSegment) {
      url = `${url}?segment=${activeSegment}`;
    }

    return this.http.get<any[]>(url).pipe(map((response) => response['data']));
  }

  stashValuesForKey(key: string, filters: Filter[]): void {
    const alreadyExistsIndex = this.stashedFilters.findIndex((x) => x.key === key);

    const newData = {
      key,
      filters,
    };

    if (alreadyExistsIndex !== -1) {
      this.stashedFilters[alreadyExistsIndex] = newData;
    } else {
      this.stashedFilters.push(newData);
    }
  }

  retrieveStashedValuesForKey(key: string): Filter[] {
    const resRef = this.stashedFilters.find((x) => x.key === key);
    const res = Object.assign({}, resRef);

    if (res && res.filters) {
      this.stashedFilters = [];
      return res.filters;
    }
  }

  transformFilters(filterCriteria: any[]): any[] {
    const result = [];

    filterCriteria.forEach((filter) => {
      let apiFilter;

      if (filter.selectedValue || filter.type === 'static' || filter.type === 'togglable') {
        switch (filter.type) {
          case 'list':
            apiFilter = {
              id: filter.id,
              value: filter.selectedValue.key,
              inverted: filter.inverted,
            };
            break;

          case 'static':
            apiFilter = {
              id: filter.id,
              value: filter.value,
            };
            break;

          case 'togglable':
            apiFilter = {
              id: filter.id,
              value: true,
            };
            break;

          case 'number':
            apiFilter = {
              id: filter.id,
              value: filter.selectedValue.number,
              comparison: filter.selectedValue.key,
            };
            break;

          case 'single-date':
          case 'date':
            if (Array.isArray(filter.selectedValue.number)) {
              filter.selectedValue.number.forEach((date, i) => {
                filter.selectedValue.number[i] = format(date, 'YYYY-MM-DD');
              });
            } else {
              switch (filter.selectedValue.key) {
                case 'today':
                  filter.selectedValue.date = format(new Date(), 'YYYY-MM-DD');
                  break;

                case 'tomorrow':
                  filter.selectedValue.date = format(addDays(new Date(), 1), 'YYYY-MM-DD');
                  break;

                case 'upcoming':
                  filter.selectedValue.date = [
                    format(new Date(), 'YYYY-MM-DD'),
                    format(addDays(new Date(), filter.selectedValue.number), 'YYYY-MM-DD'),
                  ];
                  apiFilter = {
                    id: filter.id,
                    comparison: 'bt',
                    value: filter.selectedValue.date,
                  };
                  break;

                case 'previous':
                  filter.selectedValue.date = [
                    format(subDays(new Date(), filter.selectedValue.number), 'YYYY-MM-DD'),
                    format(new Date(), 'YYYY-MM-DD'),
                  ];
                  apiFilter = {
                    id: filter.id,
                    comparison: 'bt',
                    value: filter.selectedValue.date,
                  };
                  break;

                default:
                  break;
              }
            }
            if (filter.selectedValue.key !== 'upcoming' && filter.selectedValue.key !== 'previous') {
              apiFilter = {
                id: filter.id,
                comparison: Array.isArray(filter.selectedValue.number) ? 'bt' : 'eq',
                value: filter.selectedValue.date ? filter.selectedValue.date : filter.selectedValue.number,
              };
            }
            break;

          default:
            break;
        }
        result.push(apiFilter);
      }
    });

    return result;
  }

  public mutedAndUnmutedFilter(savedFilterValue: Filter['selectedValue']): Filter {
    const selectedValue = setSelectedValue(savedFilterValue);
    if (!selectedValue) {
      return null;
    }

    return {
      id: 'properties-muted',
      label: 'Muted Properties',
      type: 'list',
      lockable: false,
      inverted: false,
      values: `${config.API_URL}/v1/filter-values/generics/yes_no?all=1`,
      selectedValue,
      valuesData: [
        {
          key: 'yes',
          label: 'Yes',
        },
        {
          key: 'no',
          label: 'No',
        },
        {
          key: 'all',
          label: 'All',
        },
      ],
    } as unknown as Filter;
  }
}
