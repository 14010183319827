import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiCacheService } from '@app/shared/services/api-cache.service';
import { config } from '@app/core/app-config';

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  constructor(private cache: ApiCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cacheConfig = this.getCacheConfig(req.url);

    if (req.method !== 'GET' || !cacheConfig) {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req.urlWithParams);

    if (cachedResponse) {
      console.log(`[API Cache] Returning cached response for ${req.urlWithParams}`);
      return of(cachedResponse);
    }

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.cache.put(req.urlWithParams, event, cacheConfig.ttl_seconds * 1000); // Convert ttl from seconds to milliseconds
        }
      })
    );
  }

  private getCacheConfig(url: string) {
    return config.URLS_TO_CACHE.find((cacheUrl) => url.includes(cacheUrl.url));
  }
}
