export function getDataWithExpiry<D>(key: string, isSessionStorage = false): { data: D; expiresAt: number } | null {
  const storageFn = isSessionStorage ? sessionStorage : localStorage;

  const itemInStorage = storageFn.getItem(key);

  // if the item doesn't exist, return null
  if (!itemInStorage) {
    return null;
  }

  const item = JSON.parse(itemInStorage);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiresAt) {
    // If the item is expired, delete the item from storage
    // and return null
    storageFn.removeItem(key);
    return null;
  }

  return { data: item.data, expiresAt: item.expiresAt };
}
