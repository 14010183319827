import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogEditKnowledgeComponent } from '@app/modules/knowledge/components/dialog-edit-knowledge/dialog-edit-knowledge.component';
import { KnowledgeHarnessComponent } from '@app/modules/knowledge/components/knowledge-harness.component';
import { KnowledgeComponent } from '@app/modules/knowledge/components/knowledge/knowledge.component';
import { SharedModule } from '@app/shared/shared.module';
import { UiModule } from '@app/ui';

@NgModule({
  imports: [CommonModule, UiModule, SharedModule],
  exports: [KnowledgeComponent, KnowledgeHarnessComponent],
  declarations: [KnowledgeComponent, KnowledgeHarnessComponent, DialogEditKnowledgeComponent],
  providers: [],
})
export class KnowledgeModule {}
