import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UserPermissionsService } from '@app/core/user-permissions/user-permissions.service';
import {
  OnboardingAction,
  OnboardingItem,
  OnboardingService,
  OnboardingStore,
} from '@app/modules/onboarding/onboarding.service';
import { SimpleApiValidationError, SubscriptionData } from '@app/shared/interfaces';
import { BillingService } from '@app/shared/services/billing/billing.service';
import { IntercomService } from '@app/shared/services/intercom.service';
import { OptimizelyService } from '@app/shared/services/optimizely/optimizely.service';
import { SegmentEvent, SegmentIoService } from '@app/shared/services/segmentIo/segment-io.service';
import { environment } from '@env/environment';
import { filter } from 'rxjs/operators';
import { NavbarService } from '../nav/navbar.service';

@Component({
  selector: 'sbnb-onboarding-banner',
  templateUrl: './onboarding-banner.component.html',
  styleUrls: ['./onboarding-banner.component.scss'],
})
export class OnboardingBannerComponent implements OnInit {
  navExpanded: boolean;
  navExists: boolean;
  hidden = true; // hide the entire component
  optimizelyAllowed = false;
  isDismissable: boolean;
  url: string;

  items: OnboardingStore;
  allCompleted = false;
  nextIncompleteItem: OnboardingItem;
  remainingStepCount: number;

  isMobile: boolean;

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly navbarService: NavbarService,
    private readonly router: Router,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly billingService: BillingService,
    private readonly authService: AuthenticationService,
    private readonly optimizelyService: OptimizelyService,
    private readonly userPermissionsService: UserPermissionsService,
    private readonly segmentIoService: SegmentIoService,
    private readonly intercomService: IntercomService
  ) {}

  ngOnInit(): void {
    this.userPermissionsService.permissions.subscribe((perms) => {
      // Block secondary users
      if (!perms?.is_team_owner) {
        return;
      } else {
        this.optimizelyFeatureFlagCheck();

        this.isUserOnTrial();

        this.checkAndHide();

        this.listenForMobile();

        // Listen for navbar size changes to adjust ourselves accordingly
        this.navbarService.expanded$.subscribe((res) => {
          this.navExpanded = res;
        });

        this.onboardingService.onboardingItems.subscribe((res) => {
          if (res?.data?.length > 0 && !this.hidden) {
            this.addClassToMain();
          }

          this.items = res;
          this.allCompleted = this.onboardingChecklistComplete();
          this.nextIncompleteItem = this.findNextIncompleteItem();
          this.remainingStepCount = this.calculateRemainingStepCount();
        });
      }
    });
  }

  openActionLink(link: string) {
    this.segmentIoService.track(SegmentEvent.OnboardingBannerInteraction, {});
    window.location.href = link;
  }

  openTour(action: OnboardingAction) {
    this.segmentIoService.track(SegmentEvent.OnboardingBannerInteraction, {});
    this.intercomService.startTour(this.isMobile ? action.mobile_tour_id : action.tour_id);
  }

  dismiss() {
    this.segmentIoService.track(SegmentEvent.OnboardingBannerDismissed, {});
    this.hidden = true;
    localStorage.setItem('onboardingHidden', '1');

    const mains = document.getElementsByTagName('body');
    if (mains.length > 0) {
      let i;
      for (i = 0; i < mains.length; i++) {
        mains[i].classList.remove('has__global-notifications');
      }
    }
  }

  listenForMobile() {
    // Mobile checks to determine correct tour to open
    this.breakpointObserver.observe(['(max-width: 960px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  optimizelyFeatureFlagCheck() {
    this.optimizelyService.onboardingBannerEnabled$.subscribe((flag) => {
      this.optimizelyAllowed = flag;
    });
  }

  checkAndHide() {
    // Hide the element if we're on certain pages
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((res) => {
      this.url = this.router.url;

      setTimeout(() => {
        this.navExists = document.getElementsByTagName('sbnb-nav').length ? true : false;

        if (
          this.url.indexOf('/onboarding') > -1 ||
          this.url.indexOf('/inbox/thread') > -1 ||
          !this.authService.isLoggedIn() ||
          localStorage.getItem('onboardingHidden') ||
          !this.optimizelyAllowed ||
          !this.navExists
        ) {
          this.removeClassFromMain();
          this.hidden = true;
        } else {
          this.hidden = false;
          this.addClassToMain();
        }
      }, 500);
    });
  }

  isSubscriptionData(obj: SubscriptionData | SimpleApiValidationError): obj is SubscriptionData {
    return 'status' in obj;
  }

  isUserOnTrial() {
    if (!environment.production) {
      return;
    }

    this.billingService.getSubscriptionDetails().subscribe((res) => {
      if (this.isSubscriptionData(res) && res.status === 'trialing') {
        this.isDismissable = false;
      } else {
        this.isDismissable = true;
      }
    });
  }

  onboardingChecklistComplete(): boolean {
    if (!this.items || !this.items.data.length) {
      return false;
    }

    return this.items.data.findIndex((item) => !item.completed) > -1 ? false : true;
  }

  findNextIncompleteItem() {
    const parent = this.items.data.find((item) => !item.completed);

    if (parent?.children?.length > 0) {
      return parent.children.find((child) => !child.completed) || parent;
    } else {
      return parent;
    }
  }

  calculateRemainingStepCount() {
    return this.items.data.filter((item) => !item.completed).length;
  }

  addClassToMain() {
    // we need to add a class to our <main> when we are displaying a global notification

    const mains = document.getElementsByTagName('body');
    if (mains.length > 0) {
      let i;
      for (i = 0; i < mains.length; i++) {
        mains[i].classList.add('has__global-notifications');
      }
    }
  }

  removeClassFromMain() {
    const mains = document.getElementsByTagName('body');
    if (mains.length > 0) {
      let i;
      for (i = 0; i < mains.length; i++) {
        mains[i].classList.remove('has__global-notifications');
      }
    }
  }
}
