import { Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ApiResponse,
  LocalStorageKey,
  NotificationType,
  QueryParams,
  SignUpWithAirbnbCallback,
  SignUpWithAirbnbUrl,
  User,
} from '@app/shared/interfaces';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of, Observable, EMPTY } from 'rxjs';
import * as UsersActions from './users.actions';
import { UsersService } from './users.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastNotificationsService } from '@app/shared/services/toast-notifications/toast-notifications.service';
import { SignUpErrorResponse, SignupStatusError } from './users.models';

@Injectable()
export class UsersEffects {
  loadUser$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UsersActions.loadUser),
        map((action) => action),
        mergeMap((action) =>
          this.usersService.loadUser().pipe(
            mergeMap((response: ApiResponse<User>) => {
              return of(
                UsersActions.loadUserSuccess({
                  response,
                })
              );
            }),
            catchError((error: ApiErrorResponse) => of(UsersActions.loadUserFailure({ response: error })))
          )
        )
      )
  );

  signUpWithAirbnb$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UsersActions.signUpWithAirbnb),
        mergeMap(() =>
          this.usersService.signUpWithAirbnb().pipe(
            mergeMap((response: ApiResponse<SignUpWithAirbnbUrl>) => {
              const { data } = response;
              localStorage.setItem(LocalStorageKey.signUpAirbnbState, data.state);

              if (data) {
                return of(
                  UsersActions.signUpWithAirbnbRedirectToUrl({
                    response,
                  })
                );
              }

              return EMPTY;
            }),
            catchError((error: ApiErrorResponse) => of(UsersActions.signUpWithAirbnbUrlFailure({ response: error })))
          )
        )
      )
  );

  signUpWithAirbnbRedirectToUrl$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UsersActions.signUpWithAirbnbRedirectToUrl),
        map((action) => action),
        mergeMap((action) => {
          if (action.response && action.response.data && action.response.data.url) {
            window.location.href = action.response.data.url;
          }

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  signUpWithAirbnbUrlFailure$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UsersActions.signUpWithAirbnbUrlFailure),
        map((action) => action),
        mergeMap(() => {
          this.notificationService.open(
            'We are unable to redirect you to Airbnb at this time',
            'Dismiss',
            NotificationType.Error
          );
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  signUpWithAirbnbCallback$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UsersActions.signUpWithAirbnbCallback),
        mergeMap((action) =>
          this.usersService.signUpWithAirbnbCallback(action.code, action.state).pipe(
            mergeMap((response: ApiResponse<SignUpWithAirbnbCallback>) => {
              const { data } = response;

              if (data) {
                return of(
                  UsersActions.signUpWithAirbnbCallbackSuccess({
                    response,
                  })
                );
              }

              return EMPTY;
            }),
            catchError((error: SignUpErrorResponse) =>
              of(UsersActions.signUpWithAirbnbCallbackFailure({ response: error }))
            )
          )
        )
      )
  );

  signUpWithAirbnbCallbackFailure$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UsersActions.signUpWithAirbnbCallbackFailure),
        map((action) => action.response),
        mergeMap((response) => {
          this.router.navigate(['/user/register/welcome'], {
            queryParams: { [QueryParams.AirbnbError]: SignupStatusError.ApiError },
          });

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  signUpWithAirbnbCallbackSuccess$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(UsersActions.signUpWithAirbnbCallbackSuccess),
        map((action) => action.response),
        mergeMap((response) => {
          const user: SignUpWithAirbnbCallback['user'] =
            response && response.data && response.data.user ? response.data.user : null;
          const channelUserId: SignUpWithAirbnbCallback['channel_user_id'] =
            response && response.data && response.data.channel_user_id ? response.data.channel_user_id : null;

          // save user to local storage
          localStorage.setItem(LocalStorageKey.signUpUser, JSON.stringify(user));
          localStorage.setItem(LocalStorageKey.isAirbnbSignUp, 'true');
          localStorage.setItem(LocalStorageKey.signUpAirbnbChannelUserId, channelUserId);

          this.router.navigate(['user/register/welcome'], {
            queryParams: { [QueryParams.AirbnbSignup]: true },
          });

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private usersService: UsersService,
    private router: Router,
    private notificationService: ToastNotificationsService
  ) {}
}
