import { Injectable } from '@angular/core';
import { config } from '@app/core/app-config';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';
import { Filter } from '@app/shared/models/filter';
import { FilterService } from '../filter/filter.service';
import { PaginationMeta } from '@app/shared/models/pagination-meta';
import { Condition, CustomCode } from '@app/shared/interfaces/lib/custom-code-conditions.interface';

interface CustomCodesResponse {
  data: CustomCode[];
  meta: {
    conditions: Condition[];
    pagination: PaginationMeta;
  };
  error?: any;
}
@Injectable({
  providedIn: 'root',
})
export class CustomCodeService {
  getAllUrl = `${config.API_URL}/custom-codes/`;
  resultsPerPage = 20;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private filterService: FilterService
  ) {}

  getCustomCodes(
    filterCriteria?: Filter[],
    offset?: number,
    search?: string,
    limit?: number,
    limitToRuleSetScope?: number,
    limitType?: string
  ) {
    const url = `${this.getAllUrl}`;
    const params = { with_conditions: 1, limit: limit ? limit : this.resultsPerPage };

    if (limitToRuleSetScope && limitType === 'ruleset') {
      params['relevant_to_ruleset_id'] = limitToRuleSetScope;
    }

    if (limitToRuleSetScope && limitType === 'customCode') {
      params['relevant_to_customcode_id'] = limitToRuleSetScope;
    }

    if (offset) {
      params['offset'] = offset;
    }

    if (search) {
      params['query'] = search;
    }

    let apiFilters: any[];

    if (filterCriteria) {
      apiFilters = this.filterService.transformFilters(filterCriteria);
    }

    return this.http.post<CustomCodesResponse>(url, { filters: apiFilters }, { params }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCustomCodeById(id) {
    const url = `${this.getAllUrl}${id}?with_conditions=1`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  createCustomCode(payload) {
    const url = `${this.getAllUrl}create`;

    return this.http.post(url, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateCustomCode(id, payload) {
    const url = `${this.getAllUrl}${id}`;

    return this.http.put(url, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteCustomCode(id) {
    const url = `${this.getAllUrl}${id}`;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getConditionsMeta() {
    const url = `${this.getAllUrl}conditions`;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
}
