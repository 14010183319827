import { Inject, Injectable } from '@angular/core';
import { OptimizelyFeatureFlag, LocalStorageKey } from '@app/shared/interfaces';
import { decodeToRealUserId, retrieveUserFromLocalStorage } from '@app/shared/utils';
import { createInstance, Client, setLogLevel } from '@optimizely/optimizely-sdk';
import { from, Observable, of } from 'rxjs';
import { InitResponse, EnabledFeatures } from './optimizely.models';
import { OPTIMIZELY_DATA_TTL } from './optimizely.reducer';

@Injectable({
  providedIn: 'root',
})
export class OptimizelyService {
  instance: Client;
  constructor(@Inject('optimizelySdkKey') private optimizelySdkKey: string) {}

  init(): Observable<InitResponse> {
    setLogLevel('error');

    this.instance = createInstance({
      sdkKey: this.optimizelySdkKey,
      datafileOptions: {
        autoUpdate: true,
        updateInterval: OPTIMIZELY_DATA_TTL,
      },
    });
    return from(this.instance.onReady());
  }

  loadEnabledFeatures(): Observable<EnabledFeatures> {
    const { id, email } = retrieveUserFromLocalStorage();

    const userId = decodeToRealUserId(id, email) ?? '';
    const enabledFeatures: OptimizelyFeatureFlag[] = this.instance.getEnabledFeatures(userId, {
      UID: userId,
    }) as OptimizelyFeatureFlag[];

    const enabledFeaturesWithMeta: EnabledFeatures['features'] = {};

    enabledFeatures.forEach((feature) => {
      const meta = this.instance.getAllFeatureVariables(feature, userId);
      enabledFeaturesWithMeta[feature] = {
        feature,
        meta,
      };
    });

    return from(
      Promise.resolve({
        features: enabledFeaturesWithMeta,
        user: userId,
      })
    );
  }
}
