import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchPlatformSearchCriteria',
})
export class MatchPlatformSearchCriteriaPipe implements PipeTransform {
  transform(platformName: any, searchCriteria: any): any {
    if (!searchCriteria || searchCriteria === '') {
      return true;
    } else {
      return platformName.toLowerCase().includes(searchCriteria.toLowerCase());
    }
  }
}
